@import './modals/LyricsModal/lyrics-modal.less';
@import './modals/LyricsIntroModal/lyrics-intro-modal.less';

@tips-width: 30%;
@tips-min-width: 324px;

.lyrics-success-message {
  .ant-message-notice-content {
    padding: @space-16;
    border: 1px solid @success-4;
    max-width: 500px;

    .ant-message-custom-content {
      display: inline-flex;

      span[role='img'].anticon {
        font-size: 21px;
        top: 2px;
        margin-right: @space-12;
      }

      .lyrics-success-message-text {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;

        span.ant-typography {
          text-align: start;
        }
      }
    }
  }
}

a.lyrics-link,
a.lyrics-link:hover {
  color: @secondary-5;
  text-decoration: underline;
  font-weight: 400;
  font-size: @size-12;

  @media screen and (max-width: @screen-md) {
    font-size: @size-10;
  }
}

a.platforms-link,
a.platforms-link:hover {
  color: @secondary-5;
  text-decoration: underline;
  font-weight: 400;
  font-size: @size-16;

  @media screen and (max-width: @screen-md) {
    font-size: @size-14;
  }
}

.lyrics-editor-wrapper {
  border: 1px solid @neutral-5;
  border-radius: @space-4;
  background-color: @white;
  padding: @space-8 @space-12;
  min-height: 376px;
  height: 100%;
  overflow-y: scroll;

  &:hover {
    border: 1px solid @neutral-7;
  }

  .DraftEditor-root {
    span.error-span {
      color: @danger-4;
    }

    span.error-span--invalid_characters {
      border-bottom: 2px solid @danger-4;
    }
  }

  .public-DraftEditorPlaceholder-root {
    color: @neutral-7;
  }

  .tip-info-box {
    .ant-space.ant-space-vertical {
      border: 1px solid @secondary-2;
    }
  }
}

.lyrics-editor-wrapper-focused {
  border: 1px solid @primary-4;
  box-shadow: 0px 0px @space-4 rgba(93, 179, 147, 0.5);

  &:hover {
    border: 1px solid @primary-4;
  }
}

.lyrics-jsyk-layout {
  .tip-layout-children {
    max-width: none;

    @media screen and (min-width: @screen-md) {
      max-width: calc(100% - @tips-min-width);
    }

    @media screen and (min-width: @screen-xxl) {
      max-width: calc(100% - @tips-width) !important;
    }
  }

  .tip-layout-tips {
    overflow-y: auto;
    overflow-x: hidden;
    min-width: @tips-min-width;

    @media screen and (min-width: @screen-xxl) {
      min-width: @tips-width;
      flex: 0 0 32%;
    }

    .lyrics-guideline-list {
      padding-inline-start: @space-16;
      margin: 0;

      li {
        margin-bottom: @space-4;
      }
    }

    .lyrics-example-incorrect,
    .lyrics-example-correct {
      display: inline-flex;
      flex-direction: column;
      padding-left: @space-12;
      white-space: break-spaces;
    }

    .lyrics-example-incorrect {
      border-left: 2px solid @danger-4;
    }

    .lyrics-example-correct {
      border-left: 2px solid @success-4;
    }

    min-width: 324px;
    padding: 0px;

    .ant-space.ant-space-vertical {
      overflow-y: auto;
      overflow-x: hidden;
      .ant-space-item {
        height: 100%;
      }
    }
  }

  .tip-info-box {
    height: 100%;
    .lyrics-errors-list {
      padding-bottom: @space-16;
      .lyrics-title-stick {
        top: 0;
        width: 100%;
        position: sticky;
        padding: @space-16;
        border-radius: @space-4 @space-4 0 0;
        background-color: @white;
        transition: border-bottom 0.2s;
        border-bottom: 1px solid transparent;

        &:not(.stuck) {
          transition: border-bottom 0.2s;
          border-bottom-color: @secondary-2;
          border-bottom-width: 1px;
        }

        h1.ant-typography {
          margin: 0;
        }
      }

      .lyrics-errors-body {
        padding-left: @space-16;
        padding-right: @space-16;
      }
    }

    .lyrics-error-card {
      border: 1px solid @secondary-2;
      border-radius: @space-4;
      padding: @space-16;
      width: 100%;
    }
  }
}

.lyrics-jsyk-layout.error {
  .tip-info-box {
    padding: 0px;
  }
}

.lyrics-errors-title {
  color: @secondary-4;
}
