@header-height: 44px;
@row-height: 56px;

.reuse-tracks-modal {
  .select-all-header {
    text-transform: none;
  }

  .ant-table-content {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .ant-table-body {
    table {
      @media screen and (max-width: @screen-md-min) {
        min-width: 640px;
      }
    }
  }

  .ant-table-container:after {
    display: none;
  }

  .reuse-tracks-cover-art {
    width: 32px;
    height: 32px;
  }

  .ant-tooltip-disabled-compatible-wrapper,
  .ant-checkbox-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    > span {
      display: flex;
      &:nth-child(2) {
        flex-grow: 1;
        align-items: center;
      }
    }
  }

  .reuse-tracks-title {
    width: 100%;
    max-width: 380px;
    .ant-typography {
      max-width: 95%;
    }
  }

  .track-reuse-tool {
    color: @neutral-6;
    align-items: center;
    display: flex;
    margin-top: @space-24;

    img {
      margin-left: @space-8;
    }
  }

  .track-reuse-tool:hover {
    cursor: pointer;
  }

  .table-loader {
    margin-top: 24px;
  }

  .reuse-track-table {
    margin-top: 24px;

    .ant-table {
      border: 1px solid @neutral-4;
      border-bottom: 0;
    }

    .ant-table-thead {
      white-space: nowrap;
    }

    .ant-table-thead,
    .ant-table-row {
      .ant-table-cell {
        height: @header-height;
        padding-right: 24px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: @neutral-3;
      }
    }

    .ant-table-row .ant-table-cell {
      height: @row-height;
      background-color: @white;
    }

    .ant-table-row {
      &:hover {
        .ant-table-cell {
          background-color: @neutral-2;
        }
      }
    }

    .ant-table-row,
    .ant-table-thead {
      border: 1px solid @neutral-4;
    }

    .ant-table-row[aria-disabled='true'] {
      opacity: 0.4;
    }

    .ant-table-row[aria-selected='true'] {
      &:after {
        content: '';
        height: @row-height;
        position: absolute;
        width: 2px;
        left: 0;
        background-color: @primary-5;
      }
      .ant-table-cell {
        background-color: rgba(158, 247, 209, 0.16);
      }
    }
  }
}
