// Spacing units for margin/padding - 0-22 * 4px (base)
.spacingUnits (@index) when (@index >= 0) {
  .pt-@{index} {
    padding-top: @index * @space-4!important;
  }
  .pb-@{index} {
    padding-bottom: @index * @space-4!important;
  }
  .pl-@{index} {
    padding-left: @index * @space-4!important;
  }
  .pr-@{index} {
    padding-right: @index * @space-4!important;
  }
  .p-@{index} {
    padding: @index * @space-4!important;
  }
  .mt-@{index} {
    margin-top: @index * @space-4!important;
  }
  .mb-@{index} {
    margin-bottom: @index * @space-4!important;
  }
  .mr-@{index} {
    margin-right: @index * @space-4!important;
  }
  .ml-@{index} {
    margin-left: @index * @space-4!important;
  }
  .m-@{index} {
    margin: @index * @space-4!important;
  }
  .flex-gap-@{index} {
    .flex();
    gap: @index * @space-4!important;
  }
  .spacingUnits(@index - 1);
}

.spacingUnits(22);

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.h-100 {
  height: 100%;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex !important;
}

.flex-column {
  .flex();
  flex-direction: column;
}

.flex-grow {
  .flex();
  flex-grow: 1;
}

.flex-end {
  .flex();
  justify-content: flex-end;
}

.flex-align-c {
  .flex();
  align-items: center;
}

.flex-justify-c {
  .flex();
  justify-content: center;
}

.flex-space-between {
  .flex();
  justify-content: space-between;
}

.text-align-c {
  text-align: center;
}

.text-align-l {
  text-align: left;
}

.text-align-r {
  text-align: right;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.text-strike-through {
  text-decoration: line-through;
}

.text-hide {
  display: none;
}

.text-pre-line {
  white-space: pre-line;
}

.font-size-10 {
  font-size: @size-10;
}

.font-bold {
  font-weight: @font-weight-medium;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.uppercase {
  text-transform: uppercase;
}
