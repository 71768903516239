.platforms-breakdown-card {
  container-type: inline-size;
  height: 100%;

  > .custom-card__header {
    padding: @space-32 @space-32 @space-18;

    h3 {
      margin-bottom: 0px;
    }
  }

  > .custom-card__content {
    padding: 0 @space-24 @space-12;
  }

  .platforms-breakdown-card-header {
    display: flex;
    align-items: center;
    gap: @space-8;
  }

  .platforms-breakdown-content {
    display: flex;
    width: 100%;
    gap: @space-40;

    .column {
      flex: 1;

      .platforms-breakdown-table-row {
        align-items: center;
        display: flex;
        height: 40px;

        .platforms-breakdown-table-row-number {
          width: 24px;
        }

        .platforms-breakdown-table-row-column {
          flex: 1;

          &.dsp-name {
            align-items: flex-end;
            display: flex;
            gap: @space-8;

            svg {
              height: 24px;
              width: 24px;
            }
          }
        }

        &.body {
          border-top: solid 1px @neutral-4;
        }
      }
    }
  }

  .no-data-container {
    .no-data-card {
      width: 320px;

      .custom-card__content {
        padding: @space-16;
      }
    }
  }

  @container (width < @screen-lg) {
    .platforms-breakdown-content {
      .column {
        .platforms-breakdown-table-row {
          .platforms-breakdown-table-row-column.dsp-name {
            flex: 2;
          }
        }
      }
    }
  }

  @container (width < @screen-md) {
    .platforms-breakdown-content {
      display: block;

      .column.second-column {
        .platforms-breakdown-table-row.header {
          display: none;
        }
      }
    }
  }

  @container (width < @screen-sm) {
    .platforms-breakdown-content {
      .column {
        .platforms-breakdown-table-row {
          .platforms-breakdown-table-row-column.dsp-name {
            flex: 3;
          }
        }
      }
    }
  }
}

.platforms-breakdown-spin {
  height: 100%;

  .ant-spin-container {
    height: 100%;
  }
}
