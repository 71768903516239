div.terms-conditions-frame-container {
  padding: 0 @space-16;
  background-color: @neutral-3;
  border-radius: @space-8;

  iframe.terms-and-conditions-frame {
    display: flex;
    border: none;

    &.terms-and-conditions-frame-s {
      min-height: 160px;
    }

    &.terms-and-conditions-frame-m {
      min-height: 300px;

      @media screen and (max-width: @screen-sm-min) {
        min-height: 200px;
      }
    }

    &.terms-and-conditions-frame-l {
      min-height: 400px;

      @media screen and (max-width: @screen-sm-min) {
        min-height: 200px;
      }
    }
  }
}

.terms-checkbox-item {
  .ant-form-item-control-input {
    min-height: auto;
  }
}
