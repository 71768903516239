.distribution-page-tip-layout {
  .distribution-page-form {
    display: flex;
    justify-content: flex-start;
  }

  .distribution-date-picker {
    margin-top: 30px;
  }
}
