footer.footer {
  z-index: 1;
  padding: @space-32 0 @space-80 0;
  background: @secondary-2;
  color: @secondary-2;

  @media only screen and (max-width: @screen-md-max) {
    padding: @space-24 0 @space-80 0;
  }

  @media only screen and (max-width: @screen-sm-min) {
    padding: @space-24 0 @space-80 0;
  }

  .footer-links-col {
    display: inline-flex;
    flex-direction: row;

    ul {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      list-style: none;
      padding-inline-start: 0;
      margin: 0;

      span.anticon-setting {
        svg {
          height: 18px;
          max-height: 18px;
          width: 18px;
          max-width: 18px;
          color: #45415c;
        }
      }
    }
  }

  div.footer-language-button {
    button.footer-change-language {
      .ant-space {
        div.ant-space-item {
          display: flex;

          span.ant-typography {
            line-height: normal;
          }
        }
      }
    }
  }
}

div.footer-releases-row {
  .ant-space-item {
    display: inline-flex;
  }
}
