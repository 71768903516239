.ant-layout.ant-parent-layout {
  aside.ant-layout-sider.desktop-nav-sider {
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: sticky;
    top: 0;
    background: @secondary-2;
    transition-duration: 0.35s;
    transition-timing-function: ease-out;
    transition-delay: 0s;
    transition-property: all;
    z-index: 10;

    .ant-menu-submenu-expand-icon {
      right: 0;
      margin-right: 0;
    }

    .ant-menu-item-only-child {
      padding-left: @space-12 !important;
      padding-inline-end: unset !important;
    }

    div.ant-layout-sider-children {
      padding: @space-32 @space-18;

      div.sider-nav-header {
        display: flex;
        justify-content: space-between;
        min-height: @space-40;
        max-height: @space-40;
        .sider-collapse-button {
          width: fit-content;
          transform: scaleX(-1);
        }
      }

      ul.ant-menu {
        background: @secondary-2;
        border: none;

        .ant-collapse {
          .ant-collapse-item {
            .ant-collapse-header {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              border-radius: 0;
              padding: @space-12 @space-12 @space-12 0;

              span.ant-collapse-arrow {
                right: 0;
                top: 20px;
              }
            }

            .ant-collapse-content {
              .ant-collapse-content-box {
                padding: 0;

                li.ant-menu-item.ant-menu-item-only-child {
                  padding-left: @space-12;

                  &:active {
                    background: @secondary-3;
                    border-radius: @space-8;
                  }
                }
              }
            }
          }
        }

        .ant-collapse:not(.custom-accordion):hover {
          .ant-collapse-header {
            background: none;
            box-shadow: none;
          }
        }

        li.ant-menu-item.nav-menu-item,
        li.ant-menu-submenu.nav-menu-item,
        li.nav-menu-item {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: @space-8 !important;

          .ant-menu-submenu-title,
          a {
            display: inherit;
            justify-content: inherit;
            align-items: inherit;
          }

          span.ant-typography {
            display: inherit;
            justify-content: inherit;
            align-items: inherit;

            div.ant-space-item {
              display: inline-flex;
            }

            span.anticon {
              margin: 0;
            }
          }

          .anticon {
            margin: 0;
          }

          &:active {
            background: @secondary-3;
            border-radius: @space-8;
          }
        }

        div.ant-divider.ant-divider-horizontal {
          margin: @space-8 0;
        }

        &:not(.ant-menu-horizontal) {
          ::after {
            display: none;
          }

          .ant-menu-item:focus,
          .ant-menu-item:active,
          .ant-menu-item-selected {
            background-color: @secondary-3;
            border-radius: @space-8;
          }
        }
      }
    }

    &:not(.ant-layout-sider-collapsed) {
      div.ant-layout-sider-children {
        button.ant-btn {
          padding: 0;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      div.ant-layout-sider-children {
        div.sider-nav-header {
          .logo {
            display: none;
          }
        }

        ul.ant-menu {
          li.ant-menu-item.nav-menu-item {
            width: calc(100% - 12px);
            padding: 12px !important;
          }
        }
      }
    }
  }

  .nav-menu-item-icon {
    width: @space-20;
    svg {
      width: 18px;
    }
    font-size: @space-20;
    * {
      fill: @secondary-6;
    }
  }

  .ant-menu-item-active,
  .ant-menu-item-selected {
    .nav-menu-item-icon * {
      color: @secondary-7 !important;
      fill: @secondary-7;
    }
    .nav-menu-item-icon--with-stroke * {
      stroke: @secondary-7;
    }
    .ant-typography {
      color: @secondary-7 !important;
    }
  }

  .ant-menu-item.nav-menu-item.nav-menu-item-promo-partners {
    letter-spacing: 0px;
  }

  .ant-layout-sider-collapsed {
    .ant-menu-submenu-title {
      padding-left: 6px !important;
    }
  }
}

span.nav-user-icon {
  background: @secondary-5;
  padding: @space-8;
  border-radius: 50%;
  color: @white;
}

li.nav-account-item {
  padding-left: 0 !important;
  &:active {
    background: none;
  }

  div.ant-menu-submenu-title {
    padding-left: 16px !important;
  }

  .ant-menu-submenu-title {
    padding-right: 0 !important;
  }
}

.nav-divider {
  background-color: @secondary-3;
}

.ant-layout-sider + .custom-layout {
  max-width: calc(100% - 270px);
}

.ant-layout-sider-collapsed + .custom-layout {
  max-width: calc(100% - 92px) !important;
}

.nav-account-item.nav-menu-item {
  .profile-image {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
  }
}
