.custom-carousel {
  .custom-carousel-dots {
    position: unset;
    margin-top: @space-12;

    li {
      width: @space-12;
      button {
        width: @space-8;
        height: @space-8;
        border-radius: 100%;
        background: @neutral-5;
      }

      &.slick-active {
        width: @space-12;
        button {
          background: @neutral-8;
        }
      }
    }
  }
  .slick-list .slick-slide {
    pointer-events: unset;
  }
}
