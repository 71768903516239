.promo-partners-content-child {
  display: flex;
  flex-wrap: wrap;
  gap: @space-16 @space-32;

  .promo-partners-section-title {
    flex-basis: 100%;
    margin-top: @space-8;

    &:first-child {
      margin-top: 0px;
    }
  }

  .promo-partners-card {
    flex-basis: calc(50% - @space-16);
    box-shadow: 0px -1px 4px 0px #a8a2c133;
    height: fit-content;

    .promo-partners-logo {
      border-radius: @space-8;
      min-width: 110px;
      width: fit-content;
      height: @space-40;
      margin-bottom: @space-8;

      svg {
        height: 100%;
      }
    }

    .promo-partner-description {
      display: -webkit-box;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 72px;
      line-height: @space-24;
      min-height: 72px;
      white-space: break-spaces;
    }

    .promo-partner-description.expanded {
      line-clamp: unset;
      -webkit-line-clamp: unset;
      max-height: none;
      text-overflow: unset;
    }
  }
}

@media (max-width: @screen-md) {
  .promo-partners-content-child {
    .promo-partners-card {
      flex-basis: 100%;
    }
  }
}
