.modal-steps {
  border-radius: @space-8;
  display: flex;
  flex-direction: row;
  gap: @space-40;
  justify-content: space-between;

  @media screen and (max-width: @screen-sm-max) {
    gap: @space-16;
    flex-direction: column;
  }
}

.modal-steps__step {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: @space-16;

  @media screen and (max-width: @screen-sm-max) {
    flex-direction: column;
    margin-bottom: @space-8;
  }
}

.modal-steps__icon {
  display: flex;
  margin-bottom: @space-8;
}
