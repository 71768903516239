.ant-row.stats-page-container {
  padding-top: @space-32;
}

.stats-layout {
  .subheader {
    padding-bottom: @space-8;
  }
  .subheader-container {
    @media (max-width: @screen-sm-min) {
      flex-wrap: wrap;
    }
  }
}

.stats-page-container {
  .ant-card {
    border-radius: 0.8rem;
  }

  .ant-card.top-resource-widget {
    min-height: 130px;
    .ant-card-body {
      padding: @space-16 @space-24;

      .top-resource-name {
        margin: 6px 0px;

        span[role='img'] {
          margin-left: @space-12;

          svg {
            width: @space-24;
            height: @space-24;
          }
        }
      }
    }
  }
}

div#chartjs-tooltip {
  -ms-filter: drop-shadow(0px 4px 20px rgba(22, 18, 44, 0.15));
  filter: drop-shadow(0px 4px 20px rgba(22, 18, 44, 0.15));
  background-color: @white;
  border-radius: 4px;
  padding: 0;

  .tip-container {
    .tip-title-container {
      display: flex;
      justify-content: space-between;
      align-content: center;
      border-bottom: 1px solid @neutral-4;
      padding: @space-8 @space-12;

      h4.age-chart-title:last-of-type {
        margin-left: @space-16;
      }
      h4.tip-title {
        color: @neutral-9;
        margin: 0;
        font-size: @size-12;
      }
    }
    .tip-body-container {
      padding: 0 @space-12;
      .tip-body-vals {
        list-style: none;
        padding-inline-start: 0;
        margin-bottom: 0;

        .tip-body-key {
          display: flex;
          justify-content: space-between;
          margin: @space-10 0;

          h4.tip-body {
            margin: 0;
            font-size: @size-12;
            color: @neutral-8;
            font-weight: 400;
          }
        }
      }
    }
  }
}

div#chartjs-tooltip::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0em;
  bottom: -1em;
  left: 40%;
  box-sizing: border-box;
  border: 0.6em solid @neutral-9;
  border-color: transparent transparent @white @white;

  transform-origin: 0 0;
  transform: rotate(-45deg);

  -ms-filter: drop-shadow(0px 4px 20px rgba(22, 18, 44, 0.15));
  filter: drop-shadow(0px 4px 20px rgba(22, 18, 44, 0.15));
}

.no-data-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    user-select: none;
  }

  .no-data-card {
    position: absolute;
    border: 1px solid @secondary-2;
    box-sizing: border-box;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    .ant-card-body {
      padding: @space-16;
    }
  }
}

.stats-content-wrapper .ant-spin-nested-loading,
.stats-content-wrapper .ant-spin-container {
  height: 100%;
}

.stats-new-tag {
  background-color: @accent-4;
  border-radius: @size-24;
  height: 20px;
  width: 40px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
