.message-card {
  background: white;
  border: solid;
  border-radius: @space-4;
  border-width: 2px 2px 2px 8px;
  padding: 14px 14px 14px 12px;

  @media screen and (min-width: @screen-sm-min) {
    padding: 22px 22px 22px 16px;
  }

  &.message-card-warning {
    border-color: @warning-4;
  }

  &.message-card-error {
    border-color: @danger-4;
  }

  &.message-card-info {
    border-color: @info-4;
  }

  ul {
    margin-bottom: 0px;
  }
}
