main[data-theme='dark'] {
  background-color: @secondary-6;
  color: @white;
}

.ant-layout.ant-parent-layout {
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: @screen-sm-max) {
    flex-direction: column;
  }
}

section.ant-layout.ant-content-layout {
  margin: 0;
}

.layout-header-sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
  min-height: 175px;
  background-color: @secondary-1;
  margin-bottom: @space-16;

  .subheader {
    background-color: @secondary-1;
  }

  @media (max-width: @screen-sm-min) {
    position: relative;
    min-height: auto;
  }

  &.stuck {
    .subheader {
      padding: @space-8 0;
    }
    .subheader-title {
      font-size: @heading-3-size;
    }
  }
}

.layout-header-sticky:has(.track-view-tabs) {
  min-height: 212px;

  .track-view-tabs {
    padding: @space-24 0 0 0 !important;
  }
}

.layout-header-sticky:not(:has(.create-release-steps-container)) {
  min-height: calc(175px - 64px);
}
