.user-segment-form-modal {
  .ant-modal-body {
    padding: @space-64;

    @media screen and (max-width: @screen-sm-min) {
      padding: @space-32;
    }
  }

  .user-segmentation-form {
    .ant-card-body {
      padding: 0;
    }
  }

  form#user-segmentation-form {
    .user-segment-fields {
      width: 100%;
    }
  }
}
