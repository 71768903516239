.form-field-tip {
  display: inline-flex;
  align-items: center;
  font-size: @size-12;
  color: @neutral-7;
  cursor: pointer;
}

.form-field-tip-box {
  .ant-tooltip-inner {
    font-size: @size-10;
  }
}

.form-field-tip-link {
  color: @secondary-8;
  font-size: @size-12;
  font-weight: 400;
  text-decoration: underline;
}
