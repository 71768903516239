@box-shadow: 0px -1px 4px rgba(168, 162, 193, 0.2);

.release-card {
  background: @neutral-1;
  box-shadow: @box-shadow;
  border-radius: 8px;

  &:has(.release-card--returned) {
    box-shadow: inset 0 0 0 2px #fa8c16, @box-shadow;
  }

  .ant-card-head {
    position: absolute;
    z-index: 10;
    width: auto;
    height: auto;
    border: 0;
    padding: 0;
    min-height: 0;
    margin: 0;
    top: @space-24;
    right: @space-24;

    .ant-card-extra {
      padding: 0;
    }
    .ant-tag.status-tag {
      margin: 0;
    }
  }

  .ant-card-cover,
  .ant-card-body {
    padding: @space-16 @space-16 0;
  }
  .ant-card-cover img {
    border-radius: @space-4;
  }
  .ant-typography.text-block {
    line-height: 1.2;
  }
  .ant-card-body {
    padding-bottom: @space-16;

    > .ant-space > .ant-space-item:last-child {
      display: flex;
      width: 100%;
    }
  }

  .release-card-artwork-link {
    width: 100%;
    background-color: @secondary-2;
    aspect-ratio: 1;
    border-radius: @space-4;

    @media screen and (max-width: @screen-md-min) {
      aspect-ratio: unset;
    }

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: @screen-md-min) {
    .release-card-artwork {
      width: 100%;
      height: 148px;
      object-fit: cover;
    }
  }

  .release-card--draft,
  .release-card--returned {
    .release-card__buttons {
      a,
      button {
        &:first-child {
          &:nth-last-child(2) {
            width: calc(100% - 40px); // 2 buttons case
          }
          &:nth-last-child(3) {
            width: calc(100% - 80px); // 3 buttons case
          }
        }
        &:not(:first-child) {
          width: auto;
        }
      }
    }
  }

  .release-card--in_review,
  .release-card--approved,
  .release-card--live {
    .release-card__buttons {
      a,
      .release-card-share-button {
        width: 50%;
      }
    }
    .release-card__buttons {
      a:only-child {
        width: 100%;
      }
    }
  }

  .release-card__buttons {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    gap: @space-8;

    a.full-width {
      width: calc(100% - 40px) !important;
    }

    .custom-btn .ant-typography {
      white-space: nowrap;
    }

    button {
      min-width: 32px !important;
    }
  }

  .release-card__version-row {
    display: flex;
    align-items: center;
    height: @space-16;
    max-width: 100%;

    .separator {
      width: 1px;
      height: 18px;
      background: @neutral-5;
      margin: 0 @space-8;
    }

    .release-version {
      flex-grow: 1;
      max-width: fit-content;
    }

    .release-date {
      white-space: nowrap;
      width: calc(100% - 110px);
    }
  }

  .release-card-smart-link {
    flex: 1;
    display: flex;
    align-items: center;
    color: @secondary-5 !important;
    justify-content: flex-start;
    width: calc(50% - 9px);
    max-width: fit-content;

    a {
      cursor: pointer;
      text-decoration: underline;
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
      font-family: inherit;
    }
  }

  .release-card-date-line {
    display: flex;
    align-items: center;
    min-height: 20px;
  }

  .release-card-calendar-icon {
    margin-left: 6px;
    height: 14px;
    svg {
      width: 14px;
      height: 14px;
    }
    svg,
    path {
      fill: @neutral-6;
    }
  }

  .release-card-copy-icon {
    margin-left: 6px;
    cursor: pointer;
    height: 14px;
    svg,
    path {
      fill: @neutral-6;
    }
  }
}
