.ant-tabs.custom-tabs {
  &.ant-tabs-top .ant-tabs-nav {
    margin-bottom: 0;
  }

  &.ant-tabs-top .ant-tabs-tab {
    padding-bottom: @space-8;
  }

  .ant-tabs-ink-bar {
    background: @secondary-3 !important;
  }

  .ant-tabs-tab-btn {
    line-height: 1 !important;
  }

  &.custom-tabs--text-only .ant-tabs-tab {
    margin-right: @space-16;
  }

  &.custom-tabs--group .ant-tabs-tab + .ant-tabs-tab {
    margin-left: @space-32;

    @media screen and (max-width: @screen-sm-min) {
      margin-left: @space-24;
    }
  }

  .ant-tabs-nav-more {
    padding: 0 @space-8 !important;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active),
  .ant-tabs-tab:not(.ant-tabs-tab-active) .ant-typography,
  .ant-tabs-tab:not(.ant-tabs-tab-active) .ant-typography a {
    color: @secondary-5 !important;
    font-weight: 400;
  }

  .ant-tabs-tab-active,
  .ant-tabs-tab-active .ant-typography,
  .ant-tabs-tab-active .ant-typography a {
    text-shadow: none;
    color: @secondary-6 !important;
    font-weight: 500;
  }
}
