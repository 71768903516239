.unauth-layout-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .login {
    flex: 1;
    padding: @space-42 120px 120px;
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;

    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      content: '';
      background-image: url(/public/static/images/sticker-future.svg),
        url(/public/static/images/sticker-good-stuff-accent.svg);
      background-size: auto, auto;
      background-position: left 70%, right 10%;
      background-repeat: no-repeat;

      @media screen and (max-width: @screen-lg-max) {
        background-position: left bottom, right -3%;
        background-size: auto, 0;
      }

      @media screen and (max-width: @screen-sm-max) {
        background-position: left bottom, right 10%;
      }

      @media screen and (max-width: @screen-xs-max) {
        background-position: left bottom, right 50%;
      }
    }

    .unauth-layout-container-bg {
      background-image: url(/public/static/images/login-bg.webp);
      background-position-x: center;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      transform: scale(1.1);
      filter: blur(5px);
    }

    @media screen and (max-width: @screen-lg-max) {
      padding: @space-42 @space-60;
    }

    @media screen and (max-width: @screen-sm-max) {
      padding: @space-16;
    }

    .music-hub-intro {
      padding-right: 10%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;

      @media screen and (max-width: @screen-lg-min) {
        display: block;
        padding: 0 @space-16;
        text-align: center;
        margin-bottom: @space-32;
        margin-top: 0;
      }

      @media screen and (max-width: @screen-sm-min) {
        margin: 0;
        padding: 0;
      }

      h1.ant-typography {
        margin-bottom: @space-40;
      }

      .music-hub-intro-highlight {
        display: inline-block;
        margin-top: @space-8;
        color: #161229;
        padding: 0px @space-12;
        background: @primary-3;
      }
    }
  }

  .login .login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: @space-32;

    @media screen and (min-width: @screen-lg-min) {
      margin-bottom: @space-64;
    }
  }

  .ant-row.login-header,
  .ant-row.login-inner {
    max-width: 1400px;
    z-index: 2;
  }

  .login .login-inner {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;

    .register-btn-col {
      .login-children {
        margin-bottom: 0;

        @media screen and (max-width: @screen-xs-max) {
          margin-bottom: @space-32;
        }
      }
    }
  }

  .login .logo {
    width: 100px;
    height: @space-48;

    @media screen and (max-width: @screen-sm-max) {
      width: @space-80;
    }

    svg,
    svg > path {
      fill: @primary-3;
    }
  }

  .login-extra-header {
    display: inline;
    padding: 20px 0;

    > .ant-typography {
      display: none;

      @media screen and (min-width: @screen-xs-max) {
        display: inline;
      }
    }

    a.login-redirect {
      margin-left: @space-20;
    }
  }

  .login-children {
    width: 100%;

    &.register-form-container {
      margin: 0 0 0 auto;

      @media screen and (max-width: @screen-lg-min) {
        margin: 0 auto @space-40;
      }
    }

    &.login-form-container {
      margin: auto;
    }
  }

  .login-form-container,
  .register-form-container,
  .email-confirm-container {
    max-width: 600px;
    padding: @space-42;
    border-radius: @space-16;
    background-color: @white;

    @media screen and (max-width: @screen-sm-max) {
      padding: @space-32 @space-24;
      margin-bottom: @space-32;
    }
  }
}
