@box-shadow: 0px -1px 4px rgba(168, 162, 193, 0.2);

.tracks-board-container {
  padding-bottom: @space-40;

  .track-board-lanes {
    display: flex;
    gap: @space-20;
    height: calc(100vh - 226px);

    .track-lane {
      flex: 1;
      overflow: hidden;
      margin-left: -@space-8;
      margin-right: -@space-8;

      .track-lane-header {
        position: sticky;
        top: 0;
        background-color: @secondary-1;
        z-index: 99;
        padding-left: @space-8;
        padding-right: @space-8;

        .track-lane-heading {
          &:after {
            content: '';
            margin-top: @space-8;
            border-top: 0.5px solid @secondary-5;
            display: block;
            width: 100%;
            height: @space-16;
            background-color: inherit;
          }
        }

        .track-lane-title-light {
          color: @secondary-4;
        }

        .new-idea-button {
          .ant-btn.custom-btn-icon {
            padding: @space-32 @space-16;
            background: @secondary-2;
          }
        }
      }

      .track-lane-content {
        display: flex;
        flex-direction: column;
        gap: @space-8;
        overflow-y: scroll;
        max-height: 100%;
        padding-bottom: 40px;
        padding-top: @space-4;
        padding-left: @space-8;
        padding-right: @space-8;
      }
    }
  }

  .track-filters-container {
    display: flex;
    gap: @space-8;
    flex-direction: column;
  }
}

.track-board-card {
  box-shadow: @box-shadow;

  .custom-card__header {
    padding: @space-16 @space-16 0 @space-16;
  }
  .custom-card__content {
    padding: @space-16;
  }

  .track-board-card-copy-icon {
    > path {
      color: @neutral-6 !important;
    }
  }

  .track-card-checklist {
    .ant-space-item {
      display: flex;
    }
  }
}

.track-board-card-releases {
  .ant-divider-vertical {
    margin: 0 2px;
  }
}
