.countries-map-container {
  img {
    width: 100%;
  }

  .countries-card-container {
    position: absolute;
    top: 32px;
    left: 32px;
    height: 100%;
  }

  .country-name {
    width: 240px;
  }
}

.countries-map-container-mobile {
  background-color: @white;
  border-radius: @space-8;
  padding: @space-20 @space-16;
}
