@primary-font-family: 'Poppins', 'Helvetica Neue', sans-serif;
@secondary-font-family: 'Robinson', 'Helvetica Neue', sans-serif;

@font-weight-light: 200;
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-bold: 600;

@heading-xl-desktop-font-size: @size-46;
@heading-l-desktop-font-size: @size-38;
@heading-m-desktop-font-size: @size-30;
@heading-s-desktop-font-size: @size-24;
@heading-xs-desktop-font-size: @size-18;
@heading-xxs-desktop-font-size: @size-16;

@heading-xl-mobile-font-size: @size-32;
@heading-l-mobile-font-size: @size-24;
@heading-m-mobile-font-size: @size-18;
@heading-s-mobile-font-size: @size-18;
@heading-xs-mobile-font-size: @size-16;
@heading-xxs-mobile-font-size: @size-14;

@heading-xl-desktop-line-height: @size-54;
@heading-l-desktop-line-height: @size-46;
@heading-m-desktop-line-height: @size-38;
@heading-s-desktop-line-height: @size-32;
@heading-xs-desktop-line-height: @size-22;
@heading-xxs-desktop-line-height: @size-22;

@heading-xl-mobile-line-height: @size-40;
@heading-l-mobile-line-height: @size-32;
@heading-m-mobile-line-height: @size-26;
@heading-s-mobile-line-height: @size-26;
@heading-xs-mobile-line-height: @size-24;
@heading-xxs-mobile-line-height: @size-22;

@body-l-desktop-font-size: @size-24;
@body-m-desktop-font-size: @size-16;
@body-s-desktop-font-size: @size-14;
@body-xs-desktop-font-size: @size-12;

@body-l-desktop-line-height: @size-36;
@body-m-desktop-line-height: @size-24;
@body-s-desktop-line-height: @size-20;
@body-xs-desktop-line-height: @size-16;

@body-l-mobile-font-size: @size-20;
@body-m-mobile-font-size: @size-14;
@body-s-mobile-font-size: @size-12;
@body-xs-mobile-font-size: @size-10;

@body-l-mobile-line-height: @size-28;
@body-m-mobile-line-height: @size-22;
@body-s-mobile-line-height: @size-20;
@body-xs-mobile-line-height: @size-14;

@label-desktop-font-size: @size-14;
@label-mobile-font-size: @size-12;

@label-desktop-line-height: @size-22;
@label-mobile-line-height: @size-20;

@button-l-desktop-font-size: @size-16;
@button-m-desktop-font-size: @size-14;
@button-s-desktop-font-size: @size-12;

.custom-typography {
  word-break: normal;

  &.text-headline-xl {
    font-size: @heading-xl-desktop-font-size;
    font-family: @secondary-font-family;
    font-weight: @font-weight-regular;
    line-height: @heading-xl-desktop-line-height;

    &.text-responsive {
      @media screen and (max-width: @screen-md-min) {
        font-size: @heading-xl-mobile-font-size;
        line-height: @heading-xl-mobile-line-height;
      }
    }
  }
  &.text-headline-l {
    font-size: @heading-l-desktop-font-size;
    font-family: @secondary-font-family;
    font-weight: @font-weight-regular;
    line-height: @heading-l-desktop-line-height;

    &.text-responsive {
      @media screen and (max-width: @screen-md-min) {
        font-size: @heading-l-mobile-font-size;
        line-height: @heading-l-mobile-line-height;
      }
    }
  }
  &.text-headline-m {
    font-size: @heading-m-desktop-font-size;
    font-family: @secondary-font-family;
    font-weight: @font-weight-regular;
    line-height: @heading-m-desktop-line-height;

    &.text-responsive {
      @media screen and (max-width: @screen-md-min) {
        font-size: @heading-m-mobile-font-size;
        line-height: @heading-m-mobile-line-height;
      }
    }
  }
  &.text-headline-s {
    font-size: @heading-s-desktop-font-size;
    font-family: @primary-font-family;
    font-weight: @font-weight-medium;
    letter-spacing: normal;
    line-height: @heading-s-desktop-line-height;

    &.text-responsive {
      @media screen and (max-width: @screen-md-min) {
        font-size: @heading-s-mobile-font-size;
        line-height: @heading-s-mobile-line-height;
      }
    }
  }
  &.text-headline-xs {
    font-size: @heading-xs-desktop-font-size;
    font-family: @primary-font-family;
    font-weight: @font-weight-medium;
    letter-spacing: normal;
    line-height: @heading-xs-desktop-line-height;

    &.text-responsive {
      @media screen and (max-width: @screen-md-min) {
        font-size: @heading-xs-mobile-font-size;
        line-height: @heading-xs-mobile-line-height;
      }
    }
  }
  &.text-headline-xxs {
    font-size: @heading-xxs-desktop-font-size;
    font-family: @primary-font-family;
    font-weight: @font-weight-medium;
    letter-spacing: normal;
    line-height: @heading-xxs-desktop-line-height;

    &.text-responsive {
      @media screen and (max-width: @screen-md-min) {
        font-size: @heading-xxs-mobile-font-size;
        line-height: @heading-xxs-mobile-line-height;
      }
    }
  }

  &.ant-typography-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    white-space: normal;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }

  &.text-body-l {
    font-size: @body-l-desktop-font-size;
    font-family: @primary-font-family;
    font-weight: @font-weight-medium;
    line-height: @body-l-desktop-line-height;

    &.text-responsive {
      @media screen and (max-width: @screen-md-min) {
        font-size: @body-l-mobile-font-size;
        line-height: @body-l-mobile-line-height;
      }
    }
  }

  &.text-body-m {
    font-size: @body-m-desktop-font-size;
    font-family: @primary-font-family;
    line-height: @body-m-desktop-line-height;

    &.text-responsive {
      @media screen and (max-width: @screen-md-min) {
        font-size: @body-m-mobile-font-size;
        line-height: @body-m-mobile-line-height;
      }
    }
  }

  &.text-body-s {
    font-size: @body-s-desktop-font-size;
    font-family: @primary-font-family;
    font-weight: @font-weight-regular;
    line-height: @body-s-desktop-line-height;

    &.text-responsive {
      @media screen and (max-width: @screen-md-min) {
        font-size: @body-s-mobile-font-size;
        line-height: @body-s-mobile-line-height;
      }
    }
  }

  &.text-body-xs {
    font-size: @body-xs-desktop-font-size;
    font-family: @primary-font-family;
    line-height: @body-xs-desktop-line-height;

    &.text-responsive {
      @media screen and (max-width: @screen-md-min) {
        font-size: @body-xs-mobile-font-size;
        line-height: @body-xs-mobile-line-height;
      }
    }
  }

  &.text-button-l {
    font-size: @button-m-desktop-font-size;
    font-family: @primary-font-family;
    line-height: 1;
    font-weight: 600;
  }
  &.text-button-m {
    font-size: @button-m-desktop-font-size;
    font-family: @primary-font-family;
    line-height: 1;
    font-weight: 600;
  }
  &.text-button-s {
    font-size: @button-s-desktop-font-size;
    font-family: @primary-font-family;
    line-height: 1;
    font-weight: 600;
  }

  &.text-label-default {
    font-size: @label-desktop-font-size;
    font-family: @primary-font-family;
    line-height: @label-desktop-line-height;
    font-weight: 500;
    letter-spacing: 0.4px;

    @media screen and (max-width: @screen-md-min) {
      font-size: @label-mobile-font-size;
      line-height: @label-mobile-line-height;
      letter-spacing: 0.2px;
    }
  }

  &.text-ellipsis-clamp-2,
  &.text-ellipsis-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    white-space: normal;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: unset;
  }

  .text-ellipsis-clamp-3 {
    -webkit-line-clamp: 3;
  }

  &.text-inline {
    display: inline-block;
  }

  &.text-block {
    margin: 0;
    line-height: 1;
  }

  &.bold-400 {
    font-weight: 400;
  }
  &.bold-500 {
    font-weight: 500;
  }

  &.bold-600 {
    font-weight: 600;
  }
  &.bold-700 {
    font-weight: 700;
  }
  &.bold-800 {
    font-weight: 800;
  }

  &.text-align-left {
    display: block;
    text-align: left;
  }

  &.text-align-center {
    display: block;
    text-align: center;
  }

  &.text-align-right {
    display: block;
    text-align: right;
  }
}
