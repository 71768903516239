.dsps-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-width: 200px;

  svg {
    margin-right: @space-4;
  }

  svg:last-of-type {
    margin: 0px;
  }

  .dsp-icon-extra {
    margin-left: @space-4;
  }

  .dsp-icon-hidden {
    display: none;
  }

  .dsp-icon-extra-number {
    display: flex;
  }

  .dsps-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: @space-8;

    .ant-space-item {
      margin-bottom: @space-8;
    }
  }
}
