div.works-status-limit-agree-confirm {
  a.gema-works-portal-link {
    font-weight: 400;
    text-decoration: underline;
  }

  ul.works-limitations-list {
    list-style: none;
    padding-inline-start: 0;
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: @space-12;

      span.anticon {
        margin-right: @space-8;
      }
    }
  }
}

.ant-modal.works-confirm-modal {
  width: 800px;
  padding-bottom: 0;

  .works-form-data {
    overflow-y: scroll;
    max-height: 300px;
  }

  .ant-modal-body {
    padding: @space-32 @space-40;
  }

  .ant-modal-footer {
    padding: @space-32 @space-40;

    button:last-of-type {
      margin-left: @space-16;
    }
  }
}

.ant-modal.works-success {
  .ant-modal-body {
    padding: @space-48 @space-48 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin: @space-32 auto 0;
    }
  }
}

div.works-split-radio {
  display: block;

  .radio-label-container {
    white-space: pre-line;
  }
}

.ant-form-item.author-errors {
  .ant-form-item-control-input {
    min-height: 0;
  }
  margin-bottom: 0;
}

.custom-modal__footer:has(.works-declare-success-peep) {
  .custom-modal__footer-extra {
    width: 100%;
  }

  padding-bottom: 0px !important;
}
