.custom-radio-container {
  display: inherit;
}

.ant-radio-wrapper {
  display: flex !important;
  line-height: 1;

  > span:not(.ant-radio) {
    width: 100%;
  }

  .ant-radio-inner {
    height: 20px;
    width: 20px;
  }

  .ant-radio-inner::after {
    top: 6px;
    left: 6px;
    height: 22px;
    width: 22px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: @primary-5 !important;
    background-color: @primary-5;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: @neutral-1;
  }

  .custom-radio-label-container {
    display: flex;
    flex-direction: column;
    white-space: normal;
  }
}

.custom-radio:hover {
  .ant-radio .ant-radio-inner {
    border-color: @neutral-6;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: @primary-6 !important;
    background-color: @primary-6;
  }
}

.custom-radio.ant-radio-wrapper-disabled {
  opacity: 0.4;
  pointer-events: none;
  .ant-radio-inner {
    background-color: @neutral-2;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: @primary-5 !important;
    background-color: @primary-5;
  }
  .ant-radio-inner::after {
    top: 9px;
    left: 9px;
    height: 16px;
    width: 16px;
  }
}

.custom-radio-bordered.ant-radio-wrapper {
  flex: 1;
  display: flex;
  align-items: flex-start;
  background: @white;
  border: 1px solid @neutral-5;
  border-radius: @space-4;
  min-height: @space-64;
  padding: @space-16;
  margin-right: 0;

  > span:not(.ant-radio) {
    flex-grow: 1;
  }

  .custom-radio-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    .custom-radio-label-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media screen and (max-width: @screen-md-max) {
        align-items: baseline;
      }
    }
  }

  .custom-radio-icon {
    align-items: flex-start;
    display: flex;
    margin-left: @space-16;
  }

  &.ant-radio-wrapper-checked {
    background: rgba(158, 247, 209, 0.16);
    border: 1px solid @primary-5;
  }
}

.custom-radio-bordered.ant-radio-wrapper.radio-centered {
  align-items: center;

  .radio-container {
    align-items: center;
  }

  .ant-radio {
    align-self: center;
  }

  .custom-radio-icon {
    align-items: center;
    display: flex;
    align-self: center;
    margin-left: @space-8;
  }
}

.custom-radio-bordered.ant-radio-wrapper.ant-radio-wrapper-disabled {
  background-color: @neutral-4;
}

.custom-radio-bordered.ant-radio-wrapper.ant-radio-wrapper-disabled.ant-radio-wrapper-checked {
  background-color: rgba(158, 247, 209, 0.16);
}

.custom-radio-bordered.ant-radio-wrapper:hover {
  border: 1px solid @neutral-6;
}

.custom-radio-bordered.ant-radio-wrapper-checked.ant-radio-wrapper:hover {
  border: 1px solid @primary-6;
}

.ant-radio-group:not(.stats-time-filter) {
  .ant-radio-button-wrapper-checked {
    border-right-color: @primary-color !important;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border-right-color: @primary-5 !important;
}

label.custom-radio &:first-child {
  align-self: flex-start;
}
