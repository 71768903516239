html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4em;
  letter-spacing: 0.05rem;
}

@font-face {
  font-family: 'Robinson';
  src: local('Robinson'),
    url('assets/fonts/Robinson-Medium-Web.woff') format('woff');
}

#root {
  height: 100%;
}
