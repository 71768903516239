.map-chart {
  position: relative;
  background-color: @white;
  border-radius: @space-8;
  cursor: pointer;

  &:active {
    cursor: grabbing;
  }

  .rsm-svg {
    border-radius: @space-8;
    vertical-align: top;
  }
}

.map-chart-buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.map-chart-tooltip {
  border: 1px solid @neutral-5 !important;
  border-radius: 4px !important;
  padding: @space-8!important;
  filter: drop-shadow(0px 4px 20px rgba(22, 18, 44, 0.15));
}

.chart-zoom-btn-override {
  background-color: @white !important;
}
