@checkbox-height: 20px;
@checkbox-mobile-height: 16px;

.custom-checkbox.ant-checkbox-wrapper {
  min-height: @checkbox-height;
  display: flex;
  align-items: center;

  @media (max-width: @screen-md-max) {
    min-height: @checkbox-mobile-height;
  }

  .ant-checkbox {
    top: 0;
  }

  .ant-checkbox-inner {
    width: @checkbox-height;
    height: @checkbox-height;

    @media (max-width: @screen-md-max) {
      width: @checkbox-mobile-height;
      height: @checkbox-mobile-height;
    }
  }

  &.custom-checkbox--description {
    height: auto;
    align-items: flex-start;
  }

  &:has(.ant-checkbox-disabled) {
    opacity: 0.4;
  }

  &:hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
  &:hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: @primary-5 !important;
    border-color: transparent !important;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: @primary-5;
      border-color: transparent !important;
      border-radius: @space-4;
      &:after {
        border-color: @white;
        transform: rotate(45deg) scale(1) translate(-45%, -55%);
      }
    }
    &.ant-checkbox:not(.ant-checkbox-disabled):active .ant-checkbox-inner,
    &.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
    &:after {
      background-color: @primary-5 !important;
      border-color: transparent !important;
    }
  }

  .ant-checkbox-inner,
  .ant-checkbox-inner:focus,
  .ant-checkbox-inner:active {
    border: 1px solid @neutral-5 !important;
  }

  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border: 1px solid @neutral-6 !important;
  }

  .ant-checkbox-disabled {
    &:not(.ant-checkbox-checked) {
      .ant-checkbox-inner {
        background-color: white;
      }
    }
  }

  .custom-checkbox__label,
  .custom-checkbox__description {
    line-height: 1;
  }

  .ant-checkbox + span {
    width: 100%;
  }
}

.custom-checkbox.ant-checkbox-wrapper.align-top {
  align-items: flex-start;
  .ant-checkbox {
    align-self: unset !important;
  }
}
