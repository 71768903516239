.custom-color-picker {
  position: relative;
  display: flex;
  align-items: center;
  gap: @space-16;

  .ant-color-picker-trigger {
    padding: 0px;
    border: none;

    .ant-color-picker-color-block {
      width: 40px;
      height: 40px;
    }
  }
}
