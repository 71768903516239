.ant-modal.subscription-modal {
  .ant-modal-body {
    .subscription-cancel-effects {
      padding: @space-16;
      background-color: @secondary-2;
      border-radius: @space-4;

      ul.cancellation-effects {
        list-style: none;
        padding-inline-start: 0;

        li {
          margin-bottom: @space-12;
          display: flex;

          span.anticon {
            margin-right: @space-12;
          }
        }
      }
    }

    .cancel-subscription-actions {
      display: inline-flex;
      justify-content: space-between;
      margin-top: @space-24;

      .cancel-text {
        width: 46%;
      }

      button:not(:last-of-type) {
        margin-right: @space-16;
      }
    }

    .subscription-processing,
    .subscription-cancel-confirm {
      span.ant-typography {
        display: flex;
        justify-content: center;
        text-align: center;
      }

      span.cancellation-downgrade-date {
        display: inline-block;

        span.ant-typography {
          display: inherit;
        }
      }
    }

    .subscription-processing {
      padding-bottom: @space-60;
    }

    svg.cancellation-confirm-peep {
      display: flex;
      margin: 0 auto;
      transform: rotateY(180deg);
    }
  }
}

.ant-modal.switch-billing-cycle-modal {
  div.ant-card.switch-modal-billing-summary {
    div.ant-card-body {
      padding: @space-24;

      @media screen and (max-width: @screen-md-min) {
        padding: @space-24 @space-16;
      }

      .ant-typography {
        margin-bottom: 0;
      }

      .plan-pricing-cycle {
        justify-content: space-between;
        display: inline-flex;
      }
    }
  }
}

.ant-modal.subscription-modal-switch-success {
  section.custom-modal__content {
    .switch-succcess-peep {
      display: flex;
      margin-bottom: -@space-40;
    }
  }
}

.subscription-payment-form-modal {
  width: 720px;
  margin-top: auto;

  .update-payment-method-subtitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: @space-8;
  }

  .ant-row.ant-form-item {
    margin-bottom: 0 !important;
  }
}
