@primary-font-family: 'Poppins', 'Helvetica Neue', sans-serif;
@secondary-font-family: 'Robinson', 'Helvetica Neue', sans-serif;

@font-weight-light: 200;
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-bold: 600;

.typography {
  .base() {
    font-family: @primary-font-family;
    font-size: @font-size-base;
  }

  .h1() {
    font-family: @secondary-font-family;
    letter-spacing: @space-2;
    font-weight: @font-weight-regular;
    font-size: @heading-1-size;
    line-height: @size-56;
  }

  .h2() {
    font-family: @secondary-font-family;
    letter-spacing: @space-2;
    font-size: @heading-2-size;
    line-height: @size-46;
  }

  .h3() {
    font-family: @secondary-font-family;
    letter-spacing: @space-2;
    font-size: @heading-3-size;
    line-height: @size-38;
  }

  .h4() {
    font-weight: @font-weight-bold;
    font-size: @heading-4-size;
    line-height: @size-28;
  }

  .h5() {
    font-weight: @font-weight-bold;
    font-size: @heading-5-size;
    line-height: @size-20;
    letter-spacing: 0.03em;
  }

  .label() {
    text-transform: uppercase;
    font-size: @font-size-sm;
    font-weight: @font-weight-medium;
  }

  .link() {
    font-size: @font-size-base;
    font-weight: @font-weight-bold;
  }

  .tooltip() {
    font-size: @font-size-sm;
  }
}
