.subscription-unsubscribed-info-banner {
  background: rgba(158, 247, 209, 0.16);
  border: 1px solid @primary-5;
  padding: @space-32;
  border-radius: @space-4;
  width: 100%;

  @media screen and (max-width: @screen-md-min) {
    padding: @space-16;
    .subscription-features-list {
      margin-bottom: @space-8;
    }
  }

  .check-icon {
    color: @accent-4;
    font-size: 16px;
  }

  .close-icon {
    color: @danger-4;
    font-size: 16px;
    margin-top: 2px;
  }
}

.gema-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;

  svg {
    height: 12px;
  }
}
