.incomplete-user-details-modal {
  position: relative;
}

.ant-col.incomplete-user-details-image {
  position: absolute;
  right: 0;
}

.review-submit {
  margin-top: @space-48;
}

.release-review-errors {
  margin-bottom: @space-24;
}
