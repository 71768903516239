.subscription-card-stripe-elements {
  height: auto;
  width: 100%;
  border: 1px;
}

.StripeElement {
  position: relative;
  z-index: 100;
  height: 40px;
  width: 100%;
  padding: 10px 0 0 @space-8 !important;
  box-shadow: 0 0 0 2px rgb(158, 248, 208 / 20%) !important;
  border-radius: @space-4;
  border: 1px solid transparent;
  background-color: @white;
}

.StripeElement--focus {
  border: 1px solid @primary-4 !important;
  box-shadow: unset !important;
}

.payment-method {
  display: flex;
  gap: @space-24;
  flex-wrap: wrap;

  .ant-space-item {
    flex: 1;
  }
}

.payment-method-stripe {
  height: 18px;
}

.payment-details-info {
  width: 100%;
  display: flex;
  height: 20px;
  justify-content: space-between;
  align-items: center;
}

.bank-account-card .ant-row.ant-form-item {
  margin-bottom: 0 !important;
}

.subscription-payment-view {
  .font-bold {
    font-weight: bold;
  }

  .paypal-payment-icon {
    height: 32px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @neutral-5;
    border-radius: 4px;
  }
}
