.custom-accordion.ant-collapse {
  border: 0;
  background: none;

  .ant-collapse-header {
    padding: @space-16 @space-32 @space-16 @space-16;
  }

  .ant-collapse-item {
    margin-bottom: @space-16;
    border: 0;
    background: @white;
    border: 1px solid @secondary-2;
    border-radius: @space-8 !important;
  }

  .ant-collapse-content {
    border: 0;
    border-radius: @space-8 !important;
  }

  .ant-collapse-content-box {
    padding-top: 0;
  }

  .ant-collapse-header {
    .ant-collapse-expand-icon {
      padding-inline-start: unset;
      height: 18px;
    }
  }
}
