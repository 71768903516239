.time-input-group.ant-input-group {
  border: 1px solid @neutral-5;

  .ant-input {
    font-size: @body-s-desktop-font-size;
  }

  &:hover {
    border: 1px solid @neutral-7;
  }

  &:focus-within {
    border: 1px solid @primary-4;
    box-shadow: 0 0 4px 0 rgba(93, 179, 147, 0.5);
  }

  &.time-input-group {
    display: flex;
    position: relative;
    width: 100px;
    align-items: center;
    justify-content: center;
  }
  &.time-input-group-disabled {
    background-color: @neutral-3;
    border: 1px solid @neutral-5;

    .time-input-icon path {
      fill: @neutral-6;
    }
  }

  .ant-input.time-input-minute,
  .ant-input.time-input-second {
    border: 0;
    text-align: left;
    height: 37px;
    margin-right: 0;
    width: 50px;

    &:focus,
    &:active {
      border: 0;
      outline: 0;
      box-shadow: none;
    }
  }

  .ant-input.time-input-minute {
    text-align: right;
  }

  .time-input-colon {
    width: 5px;
    z-index: 999;
  }

  .time-input-icon {
    display: flex;
    padding-right: @space-8;
    justify-content: flex-end;
  }
}
