.release-recent-widget {
  .release-recent-widget-header {
    display: flex;
    justify-content: space-between;
  }

  .release-recent-widget-card {
    background-color: white;
    padding: @space-24;
    padding-right: 0px;
    border-radius: @space-8;
    box-shadow: 0px -1px 4px 0px #a8a2c133;

    .custom-swiper {
      .release-recent-card {
        .release-recent-card-artwork {
          border-radius: @space-8;
        }

        .release-recent-card-artwork-image {
          width: 100%;
          height: 100%;
          border-radius: @space-8;
        }

        h5 {
          margin-bottom: 0px;
          margin-top: @space-8;
        }
      }
    }
  }
}
