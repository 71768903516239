.counter {
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: fit-content;
}

.counter__size-m {
  border-radius: @size-24 / 2;
  min-width: @size-24;
  height: @size-24;
  padding-left: 6px;
  padding-right: 6px;
}

.counter__size-s {
  border-radius: @size-20 / 2;
  min-width: @size-20;
  height: @size-20;
  padding-left: @space-4;
  padding-right: @space-4;
}

.counter__secondary {
  background-color: @secondary-4;
  span {
    color: white !important;
  }
}

.counter__neutral {
  background-color: @neutral-4;
  span {
    color: @neutral-8 !important;
  }
}

@media screen and (max-width: @screen-md) {
  .counter {
    border-radius: @size-18 / 2;
    min-width: @size-18;
    height: @size-18;
    padding-left: @space-4;
    padding-right: @space-4;
  }
}
