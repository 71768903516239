span.ant-input-suffix,
span.ant-input-prefix,
input.ant-input::placeholder,
span.custom-input::placeholder {
  color: @neutral-7;
}

span.ant-input-prefix {
  margin-right: @space-8 !important;
}

span.ant-input-suffix {
  margin-left: @space-4;
}

span.custom-input,
input.custom-input {
  padding: @space-10 @space-8;
  border: 1px solid @neutral-5;

  &.custom-input-large {
    height: @space-40;
  }

  &:hover {
    border: 1px solid @neutral-7;
  }

  &:focus,
  &.ant-input-affix-wrapper-focused {
    border: 1px solid @primary-4;
    box-shadow: 0 0 4px 0 rgba(93, 179, 147, 0.5);
  }

  &:disabled {
    background-color: @neutral-3;
    border: 1px solid @neutral-5;

    &:hover {
      border: 1px solid @neutral-5;
    }
  }
}
