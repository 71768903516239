.regular-placeholder {
  font-weight: 400;
}

.first-release-check {
  .ant-checkbox-wrapper {
    align-items: center;
    display: inline-flex;
    font-size: @size-12;
    font-weight: 500;
    font-style: normal;
    color: @neutral-8;
  }
}

.cover-version-link {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
  font-size: inherit;
}
.cover-version-link:hover {
  color: @primary-2;
}
