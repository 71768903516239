.ant-alert.custom-banner {
  @media screen and (min-width: @screen-lg-max) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media screen and (min-width: @screen-md-max) {
    padding-left: 50px;
    padding-right: 50px;
  }
  span.anticon.ant-alert-icon {
    align-self: auto !important;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.custom-banner-info {
    background-color: @info-2;
  }
  &.custom-banner-warning {
    background-color: @warning-2;
  }
  &.custom-banner-error {
    background-color: @danger-2;
  }
  &.custom-banner-success {
    background-color: @success-2;
  }
}
