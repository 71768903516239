.ant-tag {
  &.declined-transfer-tag,
  &.pending-transfer-tag {
    font-size: @size-16;
    font-weight: @font-weight-medium;
    text-align: right;

    .vat-amount {
      display: block;
      font-size: @font-size-base;
      color: @neutral-7;
    }
  }

  &.declined-transfer-tag {
    color: @warning-color;
  }

  &.pending-transfer-tag {
    color: @neutral-8;
    text-align: end;

    > p {
      margin: 0;
    }

    > p:last-child {
      color: @neutral-7;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
