.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

#audio-wave-loading {
  height: 140px;
  width: 140px;
  fill: @secondary-5;
}

#freq_1 {
  animation: pulse 1s infinite;
  animation-delay: 0.15s;
}

#freq_2 {
  animation: pulse 1s infinite;
  animation-delay: 0.3s;
}

#freq_3 {
  animation: pulse 1s infinite;
  animation-delay: 0.45s;
}

#freq_4 {
  animation: pulse 1s infinite;
  animation-delay: 0.6s;
}

#freq_5 {
  animation: pulse 1s infinite;
  animation-delay: 0.75s;
}

#freq_6 {
  animation: pulse 1s infinite;
  animation-delay: 0.9s;
}

#freq_7 {
  animation: pulse 1s infinite;
  animation-delay: 1.05s;
}

#freq_8 {
  animation: pulse 1s infinite;
  animation-delay: 1.2s;
}

#freq_9 {
  animation: pulse 1s infinite;
  animation-delay: 1.35s;
}

@keyframes pulse {
  0% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
  50% {
    transform: scaleY(0.7);
    transform-origin: 50% 50%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 50% 50%;
  }
}
