@large-modal-max-width: 1000px;
@medium-modal-max-width: 720px;
@small-modal-max-width: 500px;

@modal-horizontal-spacing: 32px;
@modal-vertical-spacing: 24px;

@modal-horizontal-spacing-s: 24px;
@modal-vertical-spacing-s: 18px;

@modal-marketing-horizontal-spacing: 40px;
@modal-marketing-horizontal-mobile-spacing: 16px;
@modal-marketing-vertical-spacing: 60px;
@modal-marketing-vertical-mobile-spacing: 40px;
@modal-marketing-bottom-mobile-spacing: 24px;
@modal-marketing-top-mobile-spacing: 40px;
@modal-marketing-header-spacing: @modal-marketing-vertical-spacing
  @modal-marketing-horizontal-spacing @modal-horizontal-spacing;
@modal-marketing-mobile-header-spacing: @modal-marketing-top-mobile-spacing
  @modal-marketing-horizontal-mobile-spacing
  @modal-marketing-bottom-mobile-spacing;

@modal-max-height: 90vh;

.custom-modal.ant-modal {
  width: 100% !important;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.custom-modal--size-l .ant-modal-content {
    width: 100%;
    max-width: @large-modal-max-width;
  }

  &.custom-modal--size-m .ant-modal-content {
    width: 100%;
    max-width: @medium-modal-max-width;
  }

  &.custom-modal--size-s .ant-modal-content {
    width: 100%;
    max-width: @small-modal-max-width;
  }

  .ant-modal-content {
    border-radius: @space-8;
    background: @secondary-1;
    padding: 0;
    overflow: hidden;
    margin: auto;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-body:has(.custom-modal__close) {
    .custom-modal__header {
      padding-right: 60px !important;
    }
  }

  .custom-modal__header {
    position: relative;

    h1 {
      margin-bottom: 0px;
    }
  }

  .custom-modal__close {
    position: absolute;
    top: @space-16;
    right: @space-16;
    z-index: 1;
    cursor: pointer;
  }

  .custom-modal__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: @modal-vertical-spacing @modal-horizontal-spacing;

    @media screen and (max-width: @screen-sm-max) {
      padding: @modal-vertical-spacing / 2 @modal-horizontal-spacing / 2;
    }

    .custom-modal__footer-extra {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .custom-modal__footer-buttons {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;

      .custom-btn {
        margin-left: @space-16;

        span {
          white-space: nowrap;
        }
      }
    }
  }

  &.custom-modal--footer-spread {
    .custom-modal__footer-buttons {
      display: flex;
      justify-content: space-between;
      .custom-btn {
        margin-left: 0;
      }
    }
  }

  &.custom-modal--no-bottom-padding {
    .custom-modal__content {
      padding-bottom: 0 !important;
    }
  }

  &.custom-modal--variant-functional {
    padding: @modal-horizontal-spacing;

    @media screen and (max-width: @screen-sm-max) {
      padding: @modal-marketing-horizontal-mobile-spacing / 2;
    }

    &:not(.custom-modal--has-footer) {
      .ant-modal-content {
        padding-bottom: @modal-horizontal-spacing - @modal-vertical-spacing;

        @media screen and (max-width: @screen-sm-max) {
          padding-bottom: (@modal-horizontal-spacing - @modal-vertical-spacing) /2;
        }
      }
    }

    &.custom-modal--scroll {
      .ant-modal-content,
      .custom-modal__wrapper {
        max-height: @modal-max-height;
        display: flex;
        flex-direction: column;
      }
      .custom-modal__content {
        overflow-y: scroll;
        overflow-x: auto;
        padding: @modal-vertical-spacing @modal-horizontal-spacing;
        @media screen and (max-width: @screen-sm-max) {
          padding: @modal-vertical-spacing / 2 @modal-horizontal-spacing / 2;
        }
      }
    }

    &.custom-modal--size-s {
      .custom-modal__header,
      .custom-modal__footer {
        padding: @modal-vertical-spacing-s @modal-horizontal-spacing-s;
      }
    }

    .custom-modal__header,
    .custom-modal__footer {
      background-color: @secondary-1;
      padding: @modal-vertical-spacing @modal-horizontal-spacing;
      @media screen and (max-width: @screen-sm-max) {
        display: flex;
        flex-direction: column;
        padding: @modal-vertical-spacing / 2 @modal-horizontal-spacing / 2;

        .custom-modal__footer-extra {
          align-self: flex-start;
        }
        .custom-modal__footer-buttons {
          align-self: flex-end;
          margin-top: @space-24;
          margin-bottom: @space-12;
        }
      }
    }

    .custom-modal__header {
      border-bottom: 1px solid @secondary-2;
    }
    .custom-modal__footer {
      box-shadow: 0px 40px 100px rgba(0, 0, 0, 0.1),
        0px 20.25px 43.5938px rgba(0, 0, 0, 0.0675),
        0px 8px 16.25px rgba(0, 0, 0, 0.05),
        0px 1.75px 5.78125px rgba(0, 0, 0, 0.0325);
    }

    &.custom-modal--size-s {
      .custom-modal__content {
        padding: @modal-vertical-spacing @modal-horizontal-spacing-s;
      }
    }
    .custom-modal__content {
      padding: @modal-vertical-spacing @modal-horizontal-spacing;
      @media screen and (max-width: @screen-sm-max) {
        padding: @modal-vertical-spacing / 2 @modal-horizontal-spacing / 2;
      }
    }
  }

  &.custom-modal--variant-marketing {
    padding: @modal-horizontal-spacing;

    @media screen and (max-width: @screen-sm-max) {
      padding: @modal-marketing-horizontal-mobile-spacing;
    }

    &.custom-modal--scroll {
      .ant-modal-content {
        max-height: @modal-max-height;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
    .custom-modal__header {
      padding: @modal-marketing-header-spacing;
      text-align: center;
      @media screen and (max-width: @screen-sm-max) {
        padding: @modal-marketing-mobile-header-spacing;
      }
    }
    .custom-modal__content {
      padding: 0 @modal-horizontal-spacing @modal-horizontal-spacing;
      @media screen and (max-width: @screen-sm-max) {
        padding: 0 @modal-marketing-horizontal-mobile-spacing
          @modal-marketing-horizontal-mobile-spacing;
      }
    }
  }

  &.custom-modal--variant-no-style {
    .ant-modal-content {
      box-shadow: unset;
      background: none;
    }
  }

  &.custom-modal--has-floater-button {
    .custom-modal__close {
      position: sticky;
      left: 100%;
      padding-right: @space-16;
      cursor: default;

      span {
        cursor: pointer;
      }
    }
  }

  .custom-modal__fixed-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: @modal-vertical-spacing;
  }
}
