.release-file-asset-list-wrapper {
  &.scrollable {
    overflow-y: auto;
    max-height: 400px;

    .release-file-asset-list {
      margin-bottom: -40px;
    }
    .release-file-asset-list-sentinel {
      display: block;
    }
  }
}

.release-file-asset-list {
  display: grid;
  gap: @space-16;
  grid-auto-flow: row;

  /* Largest screens */
  @media (min-width: @screen-lg-min) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  /* Medium screens */
  @media (min-width: @screen-md-min) and (max-width: calc(@screen-lg-min - 1px)) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  /* Small screens */
  @media (max-width: calc(@screen-md-min - 1px)) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.release-file-asset-list-sentinel {
  position: relative;
  width: 100%;
  display: none;
  height: 2px;
  background-color: transparent;
  bottom: -40px;
  text-align: center;
  padding-top: 4px;
}

.release-file-asset-list-item {
  aspect-ratio: 1 / 1;
  width: 100%;
  padding-top: 100%;
  position: relative;
  background-color: @neutral-3;
  border-radius: @space-4;
  margin-bottom: 16px;

  &.clickable {
    cursor: pointer;
  }

  &:has(.release-file-asset-empty-asset) {
    background-color: @secondary-1;
  }
}

.release-file-asset-list-item-filename {
  position: absolute;
  display: flex;
  bottom: -20px;
  left: 0;
  right: 0;
  justify-content: center;
  z-index: 99;
}

.release-file-asset-list-item-content {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-width: 70%;
    max-height: 70%;
  }

  .release-file-asset-icon {
    height: 40px;
    width: 40px;
    color: @secondary-5;
  }

  .release-file-asset-image {
    max-width: 100%;
    max-height: 100%;
    transform: scale(2);
  }

  .release-file-asset-empty-asset {
    height: auto;
    fill: @secondary-5;
    svg {
      width: 22px;
      height: 28px;
      color: @secondary-3;
    }
  }

  .release-file-asset-video-icon {
    svg {
      color: @secondary-5;
      height: 40px;
      width: 40px;
    }
  }
}

.release-file-asset-list-item-modal-content {
  img,
  svg {
    width: 100%;
  }
}

.release-file-asset-preview-icon {
  display: inline-block;
  max-width: 25px;
  margin-right: @space-16;
}
