@dsp-select-height: 20px;
@dsp-select-mobile-height: 16px;
@dsp-select-background-color: rgba(158, 247, 209, 0.16);

.dsp-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid @neutral-4;
  padding: @space-12;
  border-radius: @space-8;
  width: 100%;
  margin-bottom: @space-12;
  overflow: hidden;

  &:hover {
    border-color: @neutral-5;
  }

  @media (min-width: @screen-md-min) {
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: @screen-lg-max) {
      .custom-checkbox__label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 90%;
      }
    }
  }

  &.dsp-selection--beatport.dsp-selection--no-access {
    position: relative;
    border: solid 1px transparent;
    border-radius: @space-8;
    z-index: 1;

    .dsp-selection-header,
    .dsp-selection-expanded {
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: -1px;
      z-index: -1;
      border-radius: @space-8;
      background: linear-gradient(
        222.91deg,
        #a547fb 0%,
        #9747ff 49.88%,
        #6affbf 99.76%
      );
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 1px;
      background-color: white;
      border-radius: 7px;
      z-index: 0;
    }
  }

  .custom-checkbox {
    min-width: calc(100% - 18px);
  }

  .custom-checkbox .ant-checkbox + span {
    height: @dsp-select-height;
    @media (max-width: @screen-md-max) {
      height: @dsp-select-mobile-height;
    }
  }
}

.dsp-selection-header {
  display: flex;
  width: 100%;
}

.dsp-selection-chevron {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  z-index: 11;

  svg {
    fill: @neutral-6;
    font-size: @dsp-select-height;

    @media (max-width: @screen-md-max) {
      font-size: @dsp-select-mobile-height;
    }
  }
}

.dsp-selection-expanded {
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  display: none;
}

.dsp-selection--expanded {
  .dsp-selection-expanded {
    display: block;
    padding-top: @space-12;
  }
}

.dsp-selection:has(.ant-checkbox-checked) {
  background-color: @dsp-select-background-color;
  border: 1px solid @primary-5;

  &:hover {
    border-color: @primary-6;
  }
}

.dsp-selection:has(.ant-checkbox-disabled) {
  background-color: @neutral-4;
  border: 1px solid @neutral-5;
  opacity: 0.4;
  cursor: not-allowed;
}

.dsp-selection-header .dsp-name {
  display: flex;
  line-height: 1;
  @media (max-width: @screen-md-max) {
    font-size: @body-s-mobile-font-size;
  }
}

.dsp-selection-not-allowed-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}
