.ant-card-grid.info-item {
  border: 0;
  padding: @space-20 0;
  box-shadow: none;

  &.full-width {
    width: 100%;
  }

  &.half-width {
    width: 50%;
  }
}

.info-item {
  .label {
    .typography.label();
    color: @secondary-3;
  }

  .text {
    color: @secondary-6;
  }
}
