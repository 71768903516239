.ant-tag {
  &.ant-tag-size-small {
    font-size: 9px;
  }

  &.ant-tag-size-large {
    font-family: Robinson;
    font-size: 14px;
    padding: 4px 8px;
    font-weight: 700;
  }

  &.ant-tag-size-rounded {
    border-radius: 24px;
  }
}
