.audio-preview {
  .ant-space {
    .ant-space-item {
      button.ant-btn {
        .ant-typography {
          display: inline-flex;

          .ant-space {
            .ant-space-item {
              display: inline-flex;

              svg {
                margin-left: 3px;
              }
            }
          }
        }
      }

      button.ant-btn.custom-btn.custom-btn-secondary.custom-btn-secondary-filled,
      button.ant-btn.custom-btn.custom-btn-secondary.custom-btn-secondary-filled:hover {
        background-color: @secondary-5;
      }
    }
  }
}

.share-audio-link-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 @space-24 @space-24 @space-24;
  flex-wrap: nowrap;

  .share-audio-link-tag {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: @space-12;
    padding: @space-12 @space-16 @space-12 @space-16;
    cursor: pointer;

    @media screen and (max-width: @screen-md-max) {
      padding: @space-8;
    }

    svg {
      height: 20px;
      width: 20px;
      color: @secondary-5;
    }

    span {
      max-width: 396px;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: @screen-md-max) {
        max-width: 92px;
      }
    }
  }
}

.share-audio-stats-container {
  .icon {
    height: 22px;
    width: @space-16;
    font-size: @space-16;
    color: @secondary-5;
  }
}
