.upload-certificate-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}

.track-audio-upload-wrapper {
  padding: @space-24;
  background-color: white;
  border-radius: @space-8;
}

.audio-player-menu {
  padding: @space-24;
  background-color: @neutral-1;
  container-type: inline-size;

  .audio-player-menu-actions {
    display: flex;
    text-align: right;
    flex-wrap: wrap;
    gap: @space-4;
    flex-shrink: 0;
    justify-content: space-between;

    .audio-player-menu-actions-play {
      align-items: center;
      display: flex;
      gap: @space-16;
    }

    .audio-player-menu-actions-buttons {
      flex: 1;
      align-items: center;
      display: flex;
      gap: @space-4;
      min-width: fit-content;
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    button {
      min-width: fit-content !important;
    }
  }

  @container (width < @screen-lg) {
    padding: @space-16;

    .audio-player-menu-actions {
      row-gap: @space-16;

      .audio-player-menu-actions-buttons {
        row-gap: @space-12;
      }
    }
  }
}
