.share-audio-modal {
  .audio-preview {
    padding: @space-12;
  }

  .share-audio-modal-url {
    cursor: pointer;
    input {
      cursor: pointer;
    }

    .share-audio-modal-url-copy {
      > path {
        color: @neutral-6 !important;
      }
    }
  }
}
