.audio-player {
  background-color: @secondary-5;
  width: 100%;
  position: relative;
  overflow: auto hidden;
  cursor: pointer;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.audio-player--hide {
  display: none;
}

.waveform {
  width: 100%;
}

.waveform--loading {
  opacity: 0.1;
}

.audio-player-loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: @space-20;
  width: @space-20;
  margin: auto;

  .anticon {
    color: @white;
    font-size: @space-20;
  }
}

.audio-player-container {
  display: flex;

  .audio-player-wave {
    flex: 1;
  }

  .audio-player-time {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-width: 72px;

    .ant-tag {
      margin: 0;
    }
  }
}
