@mob-header-height: 80px;

div.mobile-menu-drawer {
  z-index: 1052; // ABOVE STICKY HEADER
  div.ant-drawer-header,
  div.ant-drawer-body {
    padding: @space-16;
  }

  div.ant-drawer-header {
    border: none;
    height: @mob-header-height;
    background-color: @secondary-2;

    .ant-drawer-header-title {
      display: inline-flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      .ant-drawer-title {
        flex: 0 0;
      }
    }
  }

  div.ant-drawer-body {
    padding-top: 0;
    overflow-y: scroll;
    height: calc(100vh - @mob-header-height);
    background-color: @secondary-2;

    ul.ant-menu {
      background-color: transparent;
      border: none;

      li.ant-menu-item.nav-submenu-item {
        border-radius: @space-8;
        padding: 0 @space-12;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-typography {
          margin: 0;
        }
      }

      li.ant-menu-item {
        padding-left: 12px !important;
        padding-right: 12px;
        display: flex;
        align-items: center;

        &::after {
          border: none;
        }

        &:active {
          background-color: initial;
        }

        h1 {
          margin-bottom: 0px;
        }

        .nav-item-new-feature {
          .ant-space-item {
            display: flex;

            &:has(.anticon) {
              margin-right: 2px !important;
            }

            &:last-child:has(svg) {
              display: flex;
              align-items: center;
            }
          }
        }

        .nav-menu-external-container {
          .ant-space-item:first-child {
            min-width: 28px;
          }
        }

        .nav-menu-item-icon {
          width: 18px;
          margin-right: @space-8;
        }

        a {
          margin: 0;
          display: flex;
          align-items: center;

          svg.external-link-icon {
            margin: 0;
            position: absolute;
            right: 20px;
          }
        }
      }

      li.ant-menu-item.ant-menu-item-selected {
        background-color: @secondary-3;
        border-radius: 8px;

        svg.check-circle-fill {
          display: flex;
        }
      }

      div.drawer-menu-dropdown-divider {
        margin: @space-12 0;
        min-width: 0;
        width: 100%;
        border-top: 1px solid @neutral-4;
      }
    }

    ul.ant-menu.ant-menu-sub {
      background-color: transparent !important;
    }
  }
}

.nav-release-count {
  white-space: nowrap;
  display: flex;
  align-items: center;

  svg {
    margin-top: @space-4;
  }
}
