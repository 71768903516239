.tracklist-page-upload {
  width: 100%;

  .ant-upload-list.ant-upload-list-text {
    margin-top: @space-8;
  }

  .custom-card.custom-card-md {
    container-type: inline-size;

    .tracklist-page-upload-card {
      display: flex;
      align-items: stretch;
      gap: @space-24;

      .tracklist-page-upload-from-board {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-color: @secondary-1;
        border-radius: @space-8;
        padding: @space-16;
        min-width: 275px;
      }
    }

    @container (width < @screen-md) {
      .tracklist-page-upload-card {
        flex-direction: column;
        gap: @space-20;
      }
    }
  }
}
