.tracks-list-container {
  text-align: center;
  .tracklist-card-container {
    .ant-card-body {
      display: flex;
      flex-direction: row;
      padding: @space-16 @space-24;

      &:hover {
        cursor: auto;
      }

      @media screen and (max-width: @screen-sm-min) {
        flex-direction: column;
        padding: @space-16;
      }

      .ant-row {
        flex-direction: row;

        .ant-col {
          flex-direction: column;
          display: flex;
          align-items: flex-start;
        }

        .ant-col:last-of-type {
          align-items: center;

          @media screen and (max-width: @screen-sm-min) {
            justify-content: center;
            width: 100%;
          }
        }

        .ant-col.track-title-col,
        .ant-col.track-title-col-mob {
          align-items: flex-start;

          .track-title-container {
            display: inline-flex;
            align-items: center;

            h1.ant-typography {
              max-width: 90%;
            }
          }
        }

        .ant-col.track-title-col {
          padding: 0 @space-24;
        }

        .ant-col.track-title-col-mob {
          padding: 0 0 0 @space-8;

          .ant-space-item {
            text-align: left;
            width: 100%;

            .status-icon {
              margin-left: @space-8;
            }
          }
        }

        .ant-col.track-title-col-mob {
          .track-options-mob {
            position: absolute;
            top: 0px;
            right: 0px;
          }
        }

        .ant-col.track-status-col {
          display: inline-flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
        }

        .ant-col.track-action-btns {
          flex-direction: row;

          a {
            .ant-btn {
              .ant-typography {
                display: inline-flex;
                .ant-space {
                  .ant-space-item {
                    display: inline-flex;
                  }
                }
              }
            }
          }
        }

        .ant-col.track-action-btns-mob {
          button {
            margin: 0 auto;
            width: 100%;

            span {
              display: flex;
              align-items: center;

              .ant-space-item {
                display: flex;
                align-items: center;
              }
            }
          }
        }

        .ant-btn.custom-btn.custom-btn-s.ant-btn-circle svg {
          font-size: @space-16;
          max-width: @space-16;
          max-height: @space-16;
        }

        .ant-btn.custom-btn.custom-btn-m.ant-btn-circle svg {
          font-size: @space-16;
          max-width: @space-16;
          max-height: @space-16;
        }
      }
    }
  }
}

div.ant-dropdown {
  ul.ant-dropdown-menu {
    li.ant-dropdown-menu-item {
      div[class*='ellipsis-dropdown-menu-'] {
        display: inline-flex;
        align-items: center;

        div.ant-space-item {
          display: inherit;
        }

        svg {
          font-size: @size-20;
        }
      }
    }
  }
}

.lyrics-btn-tooltip {
  max-width: 200px;
}
