.stats-layout {
  @media screen and (max-width: @screen-lg-max) {
    .subheader-container {
      display: block;
    }
  }
  @media screen and (max-width: @screen-sm-min) {
    .subheader-container {
      display: flex;
    }
  }

  @media screen and (max-width: @screen-md-min) {
    .stats-filters-row .ant-space {
      flex-direction: column;
      margin-right: 0;
      width: 100%;
    }

    .ant-row.stats-filters-row {
      width: 100%;

      .ant-select,
      .ant-space-item:has(.ant-select, .ant-row) {
        min-width: 100% !important;
        margin-right: 0 !important;
      }
    }

    .ant-row.stats-filters-row .ant-space-item:has(.ant-select) {
      margin-bottom: @space-16;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.ant-row.stats-filters-row {
  display: inline-flex;

  .ant-col {
    .ant-select {
      min-width: 150px;
    }
  }

  .ant-select {
    .ant-select-arrow {
      color: @secondary-5;
      font-size: @size-14;
    }

    .ant-select-selector {
      span.ant-select-selection-placeholder,
      span.ant-select-selection-item {
        cursor: pointer;
        color: @secondary-7;
        font-weight: 500;
      }
    }
  }
}

.ant-row.stats-filters-row-mob {
  .ant-space-item {
    .ant-space,
    .ant-select {
      width: 100%;
    }
  }

  .mob-filter-actions {
    display: flex;
    justify-content: space-between;
    margin-top: @space-24;
  }

  .ant-select {
    .ant-select-selector {
      span.ant-select-selection-placeholder,
      span.ant-select-selection-item {
        cursor: pointer;
        color: @secondary-7;
        font-weight: 500;
      }
    }
  }
}
