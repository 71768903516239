.available-balance {
  display: flex;
  justify-content: space-between;
  margin-top: @space-24;
  flex-direction: column;

  @media screen and (min-width: @screen-sm-min) {
    flex-direction: row;
  }

  @media screen and (min-width: @screen-md-min) {
    align-items: center;
  }

  span.ant-statistic-content-suffix {
    color: @secondary-5;
  }

  .transfer-amount-btn {
    margin-top: @space-8;

    @media screen and (min-width: @screen-sm-min) {
      margin-top: 0;
    }
  }
}

.balance-overview-card,
.bank-overview-card {
  > .ant-card-body {
    padding: @space-24;
    height: 100%;
  }
}

.ant-tooltip-inner {
  .hidden-decimal-int {
    color: @secondary-8;
  }

  .currency-suffix {
    color: @secondary-9;
  }
}

.bank-overview-card {
  .overview-card-body {
    .bank-account-information {
      display: flex;
      flex-direction: column;
      height: 100%;

      .ant-card-grid.info-item {
        p.label {
          color: @neutral-7;
        }
      }

      .no-bank-account {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: @space-24;
        padding-top: @space-24;
      }

      .add-bank-details {
        width: fit-content;
      }
    }
  }

  .add-bank-details-button {
    padding-top: @space-24;
  }
}

div.disabled-tip-wrapper {
  cursor: not-allowed;
}
