@avatar-spacing: 56px;

.share-release-collaborators {
  max-height: 300px;
  overflow-y: auto;
  border-top: 1px solid @secondary-3;

  .share-release-collaborators-row {
    display: flex;
    justify-content: space-between;
  }

  .share-release-right {
    min-width: fit-content;
  }
}

.share-release-permission-select-popup {
  z-index: 999999 !important;
}

.share-release-permission-select .custom-select-item-inner .custom-typography {
  color: @neutral-6!important;
}

.share-release-permission-select--update .custom-typography,
.share-release-permission-select--update .ant-select-arrow {
  color: @neutral-9!important;
}
