.verify-email-cta {
  color: @primary-6;
  font-size: inherit;
  text-decoration: none;
  font-weight: 500;
  outline: 0;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: @secondary-4;
  }
}

.verify-message-email {
  white-space: nowrap;
  font-weight: 500;
}

.envelope-icon-wrapper {
  background-color: @secondary-2;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
