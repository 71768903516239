.select-free-text-footer {
  padding: @space-12;
  box-shadow: 0px 2px 8px rgba(23, 18, 43, 0.5);
  text-align: center;
  margin-left: -@space-8;
  margin-right: -@space-8;
  clip-path: inset(-10px 0px -10px 0px);
}

.select-free-text-close.anticon {
  color: @neutral-7;
}
