div.streams-chart-container {
  background-color: @white;
  border-radius: 8px;
  padding: 20px 16px;
  min-height: 100%;

  @media screen and (min-width: @screen-sm-min) {
    padding: 32px;
  }

  .empty-stats-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    max-width: 470px;
    margin: 2rem auto 0;

    h1.ant-typography {
      white-space: pre-wrap;
      margin-bottom: @space-16;
    }

    button.custom-btn {
      width: 190px;
      margin: 0 auto @space-10;
    }
  }

  .empty-stats-dates {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding-inline-start: 0;
    border-top: 1px solid @neutral-5;
    padding-top: @space-10;
    margin-bottom: 0;
  }

  .total-streams-title {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    .ant-statistic-content-suffix {
      margin-left: 0;
    }

    .ant-statistic.percentage-change-stats {
      margin-right: 0.6rem;

      .ant-statistic-content {
        font-weight: 500;
        border-radius: 6px;
        padding: 2px 4px;
      }
    }

    .ant-statistic,
    .ant-statistic-content,
    span.ant-statistic-content-value,
    span.ant-statistic-content-value-decimal,
    span.ant-statistic-content-suffix {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }

    span.ant-statistic-content-value {
      margin-right: 0.6rem;
    }

    .social-stats-tip {
      margin-left: @space-8;
      height: @space-16;
      width: @space-16;
      color: @neutral-6;
    }
  }

  .percentage-change-container {
    span.ant-statistic-content-value {
      margin-right: 0rem;
    }
    span.ant-typography.text-label-default {
      font-weight: 400;
    }
  }

  .stream-chart-container {
    // height: 30vh;
    min-height: 270px; // Needed on both to support change in window height

    canvas {
      margin-top: @space-16;
      min-height: 270px; // Needed on both to support change in window height
    }

    @media screen and (min-width: @screen-sm-min) {
      margin-top: @space-36;
    }
  }
}
