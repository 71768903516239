@import './info-banners/unsubscribed-info-banner.less';

.ant-modal.subscription-modal {
  padding-bottom: 0;
}

.subscription-modal .ant-modal-body {
  background-color: @secondary-1;
  padding: @space-60;

  @media screen and (max-width: @screen-md-min) {
    padding: @space-60 @space-16;
  }
}

.subscription-banner {
  position: relative;
  width: 100%;
  background-color: @accent-6;
  border-radius: @space-8;
  background-image: url('/public/static/images/subscriptions-star-1.svg');
  background-size: 500px auto;
  background-position: right;
  background-repeat: no-repeat;
  padding: @space-32 @space-60;

  @media screen and (max-width: @screen-md-min) {
    padding: @space-16;
    background-position: -130px -240px;
  }

  .subscription-banner-price {
    span {
      text-transform: lowercase;
      font-size: @heading-xs-desktop-font-size;
    }
  }

  .subscription-referral-section {
    background: @accent-1;
    border-radius: @space-4;
    border: 2px solid @accent-4;
  }
}

.subscription-banner-left {
  width: 50%;
  max-width: 600px;
  @media screen and (max-width: @screen-md-min) {
    width: 100%;
    max-width: none;
  }
}

.subscription-banner-right {
  position: absolute;
  right: 0px;
  width: 500px;
  top: 0;
  bottom: 0;
  max-height: 138px;
  text-align: center;
  margin: auto;
  padding: 0 140px;

  .subscription-banner-right-inner {
    max-width: 280px;
  }

  @media screen and (max-width: @screen-md-min) {
    position: relative;
    padding: unset;
    margin: unset;
    bottom: unset;
    height: unset;
    right: unset;
    top: unset;
    width: 100%;
    text-align: left;
    margin-top: @space-16;
  }
}

.subscription-banner.subscription-banner--basic,
.subscription-banner.subscription-banner--points {
  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    li.subscription-point-item {
      display: inline-flex;

      span.anticon {
        margin-right: @space-8;
      }
    }
  }
}

.subscription-banner.subscription-banner--basic {
  padding: @space-32;
  border-radius: 4px;
  border: 1px solid @secondary-3;

  @media screen and (max-width: @screen-xs-min) {
    padding: @space-16;
  }

  ul {
    li.subscription-point-item {
      margin-bottom: @space-16;
    }
  }

  h1.ant-typography.subscription-banner-basic-price span {
    text-transform: lowercase;
    font-size: @heading-xs-desktop-font-size;
  }
}

.subscription-banner.subscribed-info-banner {
  background: white;
}

.subscription-banner.subscription-banner--points {
  display: flex;
  background-size: 900px;
  background-position: right -200px center;

  @media screen and (max-width: @screen-md-min) {
    display: block;
    background-size: 600px;
    background-position: bottom -300px left -130px;
  }

  h1.ant-typography.subscription-banner-price {
    margin-bottom: 0;

    span {
      text-transform: lowercase;
      font-size: @heading-s-desktop-font-size;
    }
  }

  .subscription-banner-left {
    width: 40%;
    padding-right: @space-8;
    @media screen and (max-width: @screen-md-min) {
      width: 100%;
    }
  }

  .subscription-banner-benefits {
    flex-grow: 1;
    text-align: left;

    .subscription-banner-benefits-inner {
      max-width: 380px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      padding: 0;
      height: 100%;

      div.ant-space-item {
        height: inherit;
      }
    }

    @media screen and (max-width: @screen-md-min) {
      .subscription-banner-benefits-inner {
        max-width: 100%;
        position: relative;
        right: unset;
        top: unset;
        width: auto;
        text-align: left;
        margin-top: @space-16;
        margin-left: 0;
        padding: 0;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin-bottom: @space-8;
  }
}

.cancel-pending-banner {
  margin: @space-16 0;
}

.payment-error-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: @size-56;
  height: @size-56;
  background-color: @danger-4;
  svg {
    display: flex;
    fill: @white;
    font-size: 30px;
  }
}

.subscriptions-pricing-boxes {
  display: flex;
  gap: @space-32;

  .ant-space {
    height: 100%;
  }
  .ant-space-item:last-of-type {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
  }

  @media screen and (max-width: @screen-md-min) {
    flex-direction: column;
    gap: @space-32;
  }
}

.subscriptions-pricing-box {
  position: relative;
  background-color: @white;
  border: 1px solid @secondary-4;
  padding: @space-32;
  border-radius: @space-4;
  width: 100%;

  .subscriptions-pricing-annual-tag {
    position: absolute;
    left: 28px;
    top: -10px;
    border-radius: 24px;
    text-transform: uppercase;
    font-weight: 500;

    @media screen and (max-width: @screen-md-min) {
      left: @space-12;
    }
  }

  @media screen and (max-width: @screen-md-min) {
    padding: @space-24 @space-16;

    a,
    a > button {
      width: 100%;
    }
  }
}

.subscriptions-pricing-faqs-container {
  margin-top: @space-60;

  @media screen and (max-width: @screen-md-min) {
    margin-top: @space-32;
  }
}

.subscriptions-pricing-subheading,
.subscriptions-steps-subheading {
  width: 100%;
  margin-bottom: @space-32;
  text-align: center;

  @media screen and (max-width: @screen-md-min) {
    margin-bottom: @space-16;
  }
}

.subscriptions-steps-subheading {
  margin-top: @space-32;
  @media screen and (max-width: @screen-md-min) {
    margin-top: @space-24;
  }
}

.subscriptions-pricing-faqs {
  display: flex;
  justify-content: center;
  width: 100%;
  .custom-accordion {
    max-width: 660px;
    width: 100%;
  }
}

.subscription-gradient-banner.subscription-gradient-banner--landing {
  .ant-col {
    text-align: center;
  }

  .subscription-gradient-banner-info {
    width: 100% !important;
    text-align: center;
    align-items: center;
  }
}

.subscription-gradient-banner.subscription-gradient-banner--landing-narrow {
  padding: @space-24 !important;
}

.subscription-gradient-banner {
  background: linear-gradient(
    222.91deg,
    #a547fb 0%,
    #9747ff 49.88%,
    #6affbf 99.76%
  );
  border-radius: 8px;
  padding: @space-32 @space-40;

  @media screen and (max-width: @screen-md-min) {
    padding: @space-24;
  }

  .subscription-gradient-banner-title {
    white-space: pre-line;
  }

  .subscription-gradient-span-block {
    display: block;

    .subscription-gradient-price-highlight {
      font-size: @heading-2-size;

      @media screen and (max-width: @screen-md-min) {
        font-size: @heading-3-size;
        font-weight: 600;
      }
    }
  }

  .subscription-gradient-list-item {
    display: flex;

    .subscription-gradient-checkmark {
      font-size: 16px;
      color: @primary-3;
      margin-right: @space-8;
      margin-top: @space-2;
    }
  }

  .subscription-gradient-banner-dsps {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: @space-20;

    .dsp-container {
      margin-top: @space-12;
    }

    @media screen and (max-width: @screen-lg-max) {
      justify-content: flex-start;
      align-items: flex-start;

      .dsp-container {
        display: flex;
        align-items: center;
        height: @size-24;
        margin-top: @space-12;

        .facebook-instagram-horizontal-logo {
          margin-top: @space-8;
        }
      }

      h1 {
        align-self: flex-end;
        margin-bottom: 0px;
      }
    }
  }
}

.subscription-pricing-modal {
  .modal-steps__icon {
    height: 90px;
  }
}

// sub pricing table
.subscription-pricing-table-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.subscription-pricing-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  border-radius: 10px;
  min-width: 1000px;
  width: 100%;

  &.billing {
    background: rgba(158, 247, 209, 0.16);
    border: 1px solid @primary-5;
    min-width: unset;
    width: 100%;
    grid-template-columns: 2fr 1fr 1fr;
  }
}

.subscription-pricing-feature-column,
.subscription-pricing-plan-column {
  padding: 20px;
}

.subscription-pricing-plan-features {
  ul,
  li {
    list-style: none;
    margin: 0;
    padding-inline-start: 0;
  }
  li {
    padding: @space-8 0;
    .subscription-pricing-table.home & {
      border-bottom: 1px solid @secondary-2;
    }
  }
  .subscription-pricing-body {
    .link-in-text {
      color: @accent-4 !important;
    }
  }
}

.subscription-pricing-plan-header {
  display: inline-block;
  width: 100%;
  text-align: left;
  color: #fff;
  border-radius: 10px 10px 0 0;
  min-height: 79px;

  .subscription-pricing-table.billing & {
    display: none;
  }
}

.subscription-pricing-plan-subheader {
  display: none;

  .subscription-pricing-table.billing & {
    display: block;
  }
}

.subscription-pricing-plan-column.essential,
.subscription-pricing-plan-column.lite {
  text-align: left;

  .releases-tooltip {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .subscription-pricing-table.home & {
    background-color: white;
    border: 1px solid @secondary-3;
    border-radius: 12px;
    text-align: center;

    .releases-tooltip {
      justify-content: center;
    }
  }
}

.subscription-pricing-plan-buttons {
  margin-top: @space-16;
  display: none;

  .subscription-pricing-table.home & {
    display: block;
  }
}

.subscription-pricing-tag {
  margin-bottom: @space-16;
}

.subscription-pricing-plan-header .year {
  font-size: 18px;
}

.subscription-pricing-table-wrapper-mobile {
  background: rgba(158, 247, 209, 0.16);
  border: 1px solid @primary-5;
  border-radius: 4px;
  padding: @space-16;

  .subscription-pricing-table-mobile-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    :first-child {
      flex: 2;
    }

    :nth-child(n + 2) {
      flex: 1;
    }
  }
}
