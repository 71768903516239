.breakdown-line {
  display: flex;
  padding: @space-8;
  justify-content: space-between;
  border-bottom: 1px solid @secondary-2;

  .breakdown-line-left {
    display: flex;
    align-items: center;
    gap: @space-16;

    svg {
      height: 20px;
      width: 20px;
    }

    .breakdown-line-color {
      width: @space-8;
      height: @space-32;
      border-radius: @space-4;
    }
  }

  .breakdown-line-right {
    display: flex;
    align-items: center;
  }

  &.breakdown-line-list {
    gap: @space-16;
    padding: @space-16 @space-24;

    .breakdown-line-left {
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.ant-collapse.breakdown-list-line {
  .ant-collapse-item {
    border-bottom: 1px solid @secondary-2;

    .ant-collapse-header {
      background-color: white;
      padding: 0px;
      .ant-collapse-expand-icon {
        height: 72px;
        font-size: 14px;
        color: @secondary-5;
        padding-inline-start: @space-24;
        padding-inline-end: @space-24;
      }
    }

    .ant-collapse-content-box {
      background-color: white;
      padding: 0px;

      .breakdown-line.breakdown-line-list {
        padding: 8px 84px 8px 24px;
      }
    }

    .image-wrapper {
      height: 40px;
      width: 40px;
      display: flex;
      border-radius: @space-20;
      align-items: center;
      justify-content: center;
      background-color: @neutral-3;

      svg {
        height: 20px !important;
        width: 20px !important;
      }
    }

    .breakdown-line {
      border-bottom: none !important;
    }
  }

  &:hover {
    .ant-collapse-header {
      background-color: white;
      box-shadow: none;
    }
  }
}

@media screen and (max-width: @screen-md-min) {
  .breakdown-line {
    .breakdown-line-left {
      svg {
        height: @space-16;
        width: @space-16;
      }
      .breakdown-line-color {
        width: 6px;
        height: @space-24;
      }
    }

    &.breakdown-line-list {
      .breakdown-line-left {
        svg {
          height: @space-24;
          width: @space-24;
        }
      }
    }
  }
}

.breakdown-list
  .breakdown-line-list.breakdown-line-total:not(.breakdown-line-dsp) {
  padding-right: calc(@space-24 + @space-60);
}

.breakdown-line-total {
  background-color: 'red';

  .breakdown-line-color {
    display: none;
  }

  h1 {
    margin-bottom: 0px;
  }
}
