.homepage-banner-close {
  color: @white;
  font-size: @size-16;
  position: absolute;
  top: @space-16;
  right: @space-16;
  cursor: pointer;
}

.homepage-banner {
  padding: @space-40 0;
  border-radius: @space-8;

  &.variant-1 {
    background-color: @accent-6;
    background-image: url(/public/static/images/sticker-future.svg),
      url(/public/static/images/sticker-hand.svg),
      url(/public/static/images/sticker-good-stuff-accent.svg);
    background-repeat: no-repeat;
    background-position: 0% 100%, 80% 80%, 92% 22%;
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      width: 404px;
      height: 404px;
      top: 36px;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 404px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(158, 247, 209, 0.6) 0%,
        rgba(158, 247, 209, 0.21) 66.26%,
        rgba(164, 251, 213, 0) 89.69%
      );
    }
  }
  &.variant-2 {
    background-color: @accent-5;
    background-image: url(/public/static/images/sticker-good-stuff.svg),
      url(/public/static/images/sticker-headphones.svg),
      url(/public/static/images/sticker-hand.svg),
      url(/public/static/images/sticker-wow.svg);
    background-repeat: no-repeat;
    background-position: 0% 100%, 12% 29%, 80% 80%, 92% 22%;
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      width: 404px;
      height: 404px;
      top: 36px;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 404px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(158, 247, 209, 0.6) 0%,
        rgba(158, 247, 209, 0.21) 66.26%,
        rgba(164, 251, 213, 0) 89.69%
      );
    }
  }
  &.variant-3 {
    background-color: @secondary-7;
    background-image: url(/public/static/images/sticker-star.svg),
      url(/public/static/images/sticker-headphones.svg),
      url(/public/static/images/sticker-globe-glasses.svg),
      url(/public/static/images/sticker-good-stuff-accent.svg);
    background-repeat: no-repeat;
    background-position: 0% 100%, 12% 29%, 100% 100%, 97% 70%;
    position: relative;
    overflow: hidden;
    &:after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 704px;
      height: 704px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 404px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(154, 82, 241, 0.9) 0%,
        rgba(154, 82, 241, 0.27) 63.54%,
        rgba(154, 82, 241, 0) 89.69%
      );
      opacity: 0.8;
    }
  }
  &.variant-4 {
    background-color: @secondary-7;
    background-image: url(/public/static/images/sticker-perfect.svg),
      url(/public/static/images/sticker-hand.svg),
      url(/public/static/images/sticker-wow.svg);
    background-repeat: no-repeat;
    background-position: 7% 50%, 80% 80%, 92% 22%;
    position: relative;
    overflow: hidden;
    &:after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 704px;
      height: 704px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 404px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(154, 82, 241, 0.9) 0%,
        rgba(154, 82, 241, 0.27) 63.54%,
        rgba(154, 82, 241, 0) 89.69%
      );
      opacity: 0.8;
    }
  }
  &.variant-5 {
    position: relative;
    background: url(/public/static/images/sticker-sharing-caring.svg) no-repeat
        0% 100%,
      url(/public/static/images/sticker-hand.svg) no-repeat 80% 80%,
      linear-gradient(90deg, #9ef7d1 -23.22%, #a547fb 40.96%, #431287 90.76%);
  }
  &.variant-6 {
    position: relative;
    background: url(/public/static/images/sticker-hand.svg) no-repeat 90% 80%,
      linear-gradient(90deg, #9ef7d1 -23.22%, #a547fb 40.96%, #431287 90.76%);
    overflow: hidden;

    .stripe-container {
      font-family: 'Robinson', 'Helvetica Neue', sans-serif;
      font-size: @size-16;
      font-weight: 700;
      font-style: italic;
      overflow: hidden;
      position: absolute;
      overflow: visible;
      width: 2000px;

      .stripe-primary {
        position: absolute;
        background-color: @primary-3;
        color: @accent-6;
        transform: rotate(40deg);
        bottom: 24px;
        left: -200px;
      }

      .stripe-accent {
        position: absolute;
        background-color: @accent-4;
        color: @neutral-1;
        bottom: 24px;
        transform: rotate(24deg);
        left: -232px;
      }
    }
  }

  .homepage-banner__content {
    position: relative;
    z-index: 1;
    max-width: 680px;
    margin: 0 auto;
    justify-content: center;
    display: flex;

    @media only screen and (max-width: @screen-md-min) {
      padding: 0 @space-24;
    }

    .custom-btn {
      margin-top: @space-8;
    }

    .link-in-text {
      color: white !important;
    }
  }
}

@media screen and (max-width: @screen-xl) {
  .homepage-banner {
    &.variant-6 {
      .stripe-container {
        .stripe-primary {
          left: -272px;
        }

        .stripe-accent {
          left: -316px;
        }
      }
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .homepage-banner {
    &.variant-6 {
      .stripe-container {
        .stripe-primary {
          left: -300px;
        }

        .stripe-accent {
          left: -348px;
        }
      }
    }
  }
}

@media screen and (max-width: @screen-md) {
  .homepage-banner {
    &.variant-6 {
      .stripe-container {
        .stripe-primary {
          left: -270px;
        }

        .stripe-accent {
          left: -316px;
        }
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .homepage-banner {
    &.variant-6 {
      .stripe-container {
        .stripe-primary {
          left: -320px;
        }

        .stripe-accent {
          left: -372px;
        }
      }
    }
  }
}
