@button-size: 32px;
@space-between: 16px;

.track-mini-player {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;

  .track-mini-player-audio {
    align-items: center;
    display: flex;
    gap: 8px;

    &.track-mini-player-audio--grow {
      flex: 1;
    }
  }

  .play-pause-button {
    flex-shrink: 0;
  }

  .track-mini-player-audio-tag {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 8px;
    border-radius: 8px;
    margin-right: 0px;
    flex-grow: 1;
    justify-content: center;

    svg {
      flex-shrink: 0;
    }
  }

  @media screen and (max-width: @screen-sm) {
    flex-wrap: wrap;
    row-gap: @space-24;

    .track-mini-player-audio {
      flex-grow: 1;
    }

    .button-file-upload {
      width: 100%;

      .ant-upload.ant-upload-drag {
        width: 100%;

        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}
