@small-image-size: 56px;
@large-image-size: 128px;
@small-icon-size: 50px;
@large-icon-size: 60px;
@border-radius: 8px;

.shortcut {
  border-radius: @border-radius;
  padding: @space-24;
  height: 176px;
  display: flex;
  box-shadow: 0px -1px 4px rgba(168, 162, 193, 0.2);

  .shortcut__main_content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
  }

  .shortcut__image-container {
    img {
      width: @small-image-size;
      height: @small-image-size;
    }
  }

  .shortcut__text-content {
    span,
    h1 {
      max-width: 100%;
    }
  }

  .shortcut__action-content {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    svg {
      width: @large-icon-size;
      height: @large-icon-size;
    }
  }
}

.shortcut-primary {
  background-color: @secondary-7;
}

.shortcut-secondary {
  background-color: @neutral-1;
}

.shortcut-warning {
  background-color: @neutral-1;
  border: 2px solid @warning-4;
}

.shortcut-gradient {
  background: linear-gradient(
    270deg,
    #431287 7.99%,
    #a547fb 53.75%,
    #9ef7d1 108.76%
  );
}

.shortcut__title {
  margin-bottom: @space-4 !important;
}

.shortcut__icon-container {
  display: flex;
}

.shortcut-small {
  padding: @space-16;
  height: 196px;

  .shortcut__main_content {
    > .shortcut__image-container {
      svg {
        width: @small-icon-size;
        height: @small-icon-size;
      }

      img {
        border-radius: @border-radius;
        width: @small-image-size;
        height: @small-image-size;
        margin-bottom: @space-8;
      }
    }

    .shortcut__text-content {
      .shortcut__description {
        display: none;
      }
    }

    .shortcut__action-content {
      margin-top: @space-8;

      .shortcut__icon-container {
        display: none;
      }
    }
  }

  &.shortcut-with-image {
    .shortcut__large-image {
      display: none;
    }
  }
}

.shortcut-regular {
  .shortcut__main_content {
    > .shortcut__image-container {
      display: none;
    }
  }

  &.shortcut-with-image {
    display: flex;
    justify-content: space-between;

    .shortcut__main_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: calc(100% - @large-image-size - @space-16);

      .shortcut__action-content {
        .shortcut__icon-container {
          display: none !important;
        }
      }
    }

    .shortcut__large-image {
      margin-left: @space-16;
      img {
        border-radius: @border-radius;
        width: @large-image-size;
        height: @large-image-size;
      }
    }
  }
}
