.ant-card.notify-card {
  width: 100%;
  border-left: 8px solid;
  border-radius: 0;
  margin-bottom: 3rem;
}

.ant-card.notify-card.notify-card--warning {
  border-color: @warning-color;
  border-radius: 4px;

  svg {
    fill: @warning-color;
  }
}

.ant-card.notify-card.notify-card--message {
  border-color: @secondary-9;
  border-radius: 4px;

  .notify-card-title {
    font-size: 1.6rem;
  }

  svg {
    fill: @primary-5;
  }
}

.ant-card.notify-card.notify-card--error {
  border-color: @danger-color;

  svg {
    fill: @danger-color;
  }
}

.notify-card-icon {
  margin-right: @space-12;
  display: inline-block;
}

.notify-card-content {
  margin-top: @space-24;
  width: 100%;

  ul {
    padding-inline-start: 20px;
  }
}
