@media screen and (max-width: @screen-xs-max) {
  .ant-picker-range-wrapper {
    .range-picker-container {
      .ant-picker-panel {
        &:last-child {
          width: 0;
          display: none;

          .ant-picker-header {
            position: absolute;
            right: 0;

            .ant-picker-header-prev-btn,
            .ant-picker-header-view {
              visibility: hidden;
            }
          }

          .ant-picker-body {
            display: none;
          }
        }
      }
    }
  }
}

.stats-filter-drop,
.stats-release-filter-drop {
  .ant-select-item.ant-select-item-option {
    color: @neutral-8;
  }

  .stats-filter-info-link {
    font-size: @size-12;
    text-decoration: underline;
  }
}

.stats-filter-drop {
  @media screen and (min-width: @screen-lg-min) {
    width: 219px;
  }
}

.stats-release-filter-drop {
  .ant-select-item-group {
    color: @neutral-8;
    border-top: 1px solid @neutral-4;
    font-weight: bold;
    padding-left: @space-16;
  }

  .ant-select-item-option-grouped {
    padding-left: @space-16;
  }
}

.stats-filter-release {
  max-width: 248px;
  min-width: 248px !important;
  width: 248px;

  @media screen and (max-width: @screen-sm-max) {
    max-width: 100%;
  }

  .custom-select-label {
    color: @secondary-7 !important;
    font-weight: 500;
  }
}

.stats-filter-dsp,
.stats-filter-timeframe {
  max-width: 200px;
  min-width: 200px !important;
  width: 200px;

  @media screen and (max-width: @screen-sm-max) {
    max-width: 100%;
  }

  .custom-select-label {
    color: @secondary-7 !important;
    font-weight: 500;
  }
}

.mob-filters-container {
  .ant-drawer-content {
    background-color: @secondary-1;
  }

  .mob-filters-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: @space-32;
  }
}
