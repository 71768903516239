.subheader {
  padding: @space-16 0 @space-24;

  .link {
    display: flex;
    align-items: center;
    margin-right: @space-16;
  }

  .icon {
    color: @secondary-4;

    > svg {
      width: @space-24;
      height: @space-24;
    }
  }

  & h1.ant-typography {
    margin-bottom: 0;
  }

  .subheader-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-link-container {
      flex-grow: 1;
      display: flex;
      overflow: hidden;

      .subheader-status {
        display: inline-flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-left: @space-16;
      }

      .subheader-title.ant-typography {
        color: @secondary-7;
        margin-bottom: 0;
        transition: font-size linear 0.1s;
      }
    }

    .status-actions-container {
      min-width: 350px;
      display: flex;
      justify-content: flex-end;

      @media screen and (max-width: @screen-md-min) {
        min-width: unset;
        padding-left: @space-16;
      }
    }
  }

  @media screen and (max-width: @screen-md-min) {
    .subheader-container {
      .title-link-container {
        max-width: max-content;
        overflow: auto;
      }
    }
  }
}
