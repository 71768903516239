.lyrics-intro-modal {
  .custom-modal__content {
    .lyrics-intro-modal-subheading {
      margin-bottom: @space-32;
      padding-left: 10%;
      padding-right: 10%;

      @media screen and (max-width: @screen-md) {
        margin-bottom: @space-8;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  .lyrics-intro-modal-footer {
    margin-top: @space-32;

    @media screen and (max-width: @screen-md) {
      margin-top: @space-8;
    }

    > div:last-child {
      max-width: 382px;
      text-align: center;
    }
  }
}
