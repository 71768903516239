.section-header {
  margin-bottom: @space-32;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: @screen-lg-min) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    &.tip-layout-constraint-header {
      padding-right: calc(25% + @space-12);

      .section-header-actions {
        align-items: center;
      }
    }
  }

  > div {
    > p {
      margin: 0;
      color: @secondary-9;
      font-weight: 500;
    }
  }

  .section-header-title--inline {
    h4 {
      width: -moz-fit-content;
      width: fit-content;
      display: inline-flex;
      margin-right: @space-12;
    }
    p {
      display: inline-flex;
    }
  }

  .section-header-actions {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    > * {
      margin-top: @space-24;
      margin-right: @space-24;

      &:last-child {
        margin-right: 0;
      }

      &.ant-select {
        max-width: 200px;
        min-width: 160px;
      }
    }

    @media (min-width: @screen-lg-min) {
      align-items: flex-end;
      justify-content: flex-end;
      flex-wrap: nowrap;

      > * {
        margin-top: 0;
      }
    }
  }
}

div[data-theme='dark'] {
  .section-header {
    h4.ant-typography {
      color: @white;
    }
  }
}

.section-header:empty {
  display: none;
}
