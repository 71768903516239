.create-release-steps-container {
  background-color: @secondary-2;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
  padding: @space-16 0;

  @media only screen and (max-width: @screen-xs-max) {
    padding: @space-8 0;
  }
}

.ant-steps.create-release-steps {
  .ant-steps-item-container {
    display: inline-flex;
    align-items: center;

    .ant-steps-item-content {
      display: inherit;
      align-items: inherit;
    }
  }

  &.ant-steps-vertical {
    .ant-steps-item-container {
      .ant-steps-item-tail {
        top: @space-12;
        left: @space-12;
      }
    }
  }
}

.ant-steps.create-release-steps {
  .ant-steps-item-wait {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        background: @secondary-1;
        border-color: @secondary-3;

        .ant-steps-icon {
          color: @secondary-5;
        }
      }

      .ant-steps-item-title {
        color: @secondary-5;
      }
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        background: @secondary-6;
        border-color: @secondary-6;

        .ant-steps-icon {
          color: @white;
        }
      }

      .ant-steps-item-title {
        color: @secondary-7;
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        background: @secondary-4;
        border-color: @secondary-4;

        .ant-steps-icon {
          color: @white;
        }
      }

      .ant-steps-item-title {
        color: @secondary-5;
      }
    }
  }
}

.ant-steps.create-release-steps {
  .ant-steps-item-wait.create-release-step,
  .ant-steps-item-active.create-release-step,
  .ant-steps-item-finish.create-release-step {
    .ant-steps-item-title {
      line-height: @size-24;

      &::after {
        top: @space-12;
        background: @secondary-4;
      }
    }
  }
}

.ant-steps.create-release-steps
  .ant-steps-item.create-release-step:not(.ant-steps-item-active):not(.ant-steps-item-process)
  .ant-steps-item-container:hover[role='button'] {
  .ant-steps-item-icon {
    border-color: @secondary-4;
    background: @secondary-3;
  }
  .ant-steps-icon {
    color: @secondary-6;
  }
  .ant-steps-item-title {
    color: @secondary-6;
  }
}
