.table-loader-row {
  width: 100%;
  border-bottom: 1px solid @neutral-3;

  &:first-child {
    margin-top: -@space-8;
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: -@space-8;
  }
}

.table-loader {
  .ant-card-body {
    padding: @space-16;
  }
}
