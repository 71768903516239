.search-and-filter-container {
  display: flex;
  justify-content: space-between;

  .search-container {
    flex-grow: 0.35;
  }

  .filter-extra-container {
    flex-grow: 0.35;
    gap: @space-32;
  }

  .filter-container {
    gap: @space-16;
  }

  @media screen and (max-width: @screen-xl-min) {
    .filter-extra-container {
      gap: @space-16;
    }
  }

  @media screen and (max-width: @screen-md-min) {
    gap: @space-16;
    flex-direction: column;

    .filter-extra-container {
      gap: 0px;
    }
  }
}
