// @import '~antd/dist/antd.less';
// @import '~antd/lib/style/themes/default.less';

// Media queries breakpoints
// @screen-xs and @screen-xs-min is not used in Grid
// smallest break point is @screen-md
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
// 👆 Extra small screen / phone

// 👇 Small screen / tablet
@screen-sm: 576px;
@screen-sm-min: @screen-sm;

// Medium screen / desktop
@screen-md: 768px;
@screen-md-min: @screen-md;

// Large screen / wide desktop
@screen-lg: 992px;
@screen-lg-min: @screen-lg;

// Extra large screen / full hd
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;

// Extra extra large screen / large desktop
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;

// provide a maximum
@screen-xs-max: (@screen-sm-min - 1px);
@screen-sm-max: (@screen-md-min - 1px);
@screen-md-max: (@screen-lg-min - 1px);
@screen-lg-max: (@screen-xl-min - 1px);
@screen-xl-max: (@screen-xxl-min - 1px);

@import '~draft-js/dist/Draft.css';

@import 'component-library/src/styles/reset.less';
@import 'component-library/src/styles/variables.less';
@import 'component-library/src/styles/exports.less';
@import 'component-library/src/styles/util.less';

@import 'styles/typography.less';
@import 'styles/global.less';

// design system
@import 'component-library/src/button/button.less';
@import 'component-library/src/input-text/input-text.less';
@import 'component-library/src/input-text-area/input-text-area.less';
@import 'component-library/src/tag/tag.less';
@import 'component-library/src/time-input/time-input.less';
@import 'component-library/src/typography/typography.less';
@import 'component-library/src/modal/modal.less';
@import 'component-library/src/modal/modal-content/steps/steps.less';
@import 'component-library/src/list/list.less';

// legacy
@import 'components/footer/footer.less';
@import 'components/card/card.less';
@import 'features/stats/components/audience-data-chart.less';
@import 'features/stats/components/countries-map-chart.less';
@import 'features/stats/components/platform-breakdown.less';
@import 'features/stats/components/streams-line-chart.less';
@import 'features/stats/components/top-countries-card.less';
@import 'features/stats/components/top-playlists-card.less';
@import 'features/stats/components/top-resource-card.less';
@import 'components/charts/percentage-change/percentage-change.less';
@import 'components/collapse-section/collapse-section.less';
@import 'components/color-picker/color-picker.less';
@import 'components/container/container.less';
@import 'components/date-picker/date-picker.less';
@import 'components/ellipsis-dropdown/ellipsis-dropdown.less';
@import 'components/empty-section/empty-section.less';
@import 'components/error/error.less';
@import 'components/info-item/info-item.less';
@import 'components/row-info-item/row-info-item.less';
@import 'components/search-bar/search-bar.less';
@import 'components/layout/layout.less';
@import 'components/layout/unauth-layout.less';
@import 'components/list-loader/list-loader.less';
@import 'components/message-card/message-card.less';
@import 'components/nav/components/mobile-menu-drawer.less';
@import 'components/nav/nav.less';
@import 'components/overview-card/overview-card.less';
@import 'components/page-loader/page-loader.less';
@import 'components/peep/peep.less';
@import 'components/radio/radio.less';
@import 'features/release/components/release-card/release-card.less';
@import 'features/release/release-edit/metadata/base-data/release-form/release-form.less';
@import 'features/release/release-edit/components/release-error/release-error.less';
@import 'features/release/components/release-data-view/release-data-view.less';
@import 'features/release/release-edit/components/release-progress/release-progress.less';
@import 'features/release/release-details/release-success-modal/release-success-modal.less';
@import 'features/track/track-tabs/track-metadata/components/track-released-in.less';
@import 'components/section/section.less';
@import 'components/section-alert/section-alert.less';
@import 'components/shortcut/shortcut.less';
@import 'components/subheader/subheader.less';
@import 'components/switch/switch.less';
@import 'components/table/table.less';
@import 'components/tabs/tabs.less';
@import 'components/data-table/data-table.less';
@import 'components/table-loader/table-loader.less';
@import 'components/upload/upload.less';
@import 'components/tip-layout/tip-layout.less';
@import 'components/tooltip-form-field/form-field-tip.less';
@import 'components/tracklist-form/reuse-modal.less';
@import 'components/tracklist-form/track-form.less';
@import 'features/track/track-list/components/tracklist-track-card.less';
@import 'features/track/track-tabs/track-metadata/components/track-edit-form.less';
@import 'components/track-performer-list/performer-list.less';
@import 'components/status-tag/status-tag.less';
@import 'components/or-separator/or-separator.less';
@import 'components/notify-card/notify-card.less';
@import 'components/select-freetext/select-freetext.less';
@import 'components/audio-player/audio-player.less';
@import 'components/banner/Banner.less';
@import 'components/box/box.less';
@import 'components/checkbox/checkbox.less';
@import 'components/charts/map-chart/map-chart.less';
@import 'components/counter/counter.less';
@import 'components/select/select.less';
@import 'components/user-segmentation-form/user-segmentation.less';
@import 'components/accordion/accordion.less';
@import 'components/subscriptions-payment/subscriptions-payment.less';
@import 'components/hidden-currency-value/hidden-currency-value.less';
@import 'components/search-and-filter/search-and-filter.less';
@import 'components/carousel/carousel.less';
@import 'components/homepage-banner/homepage-banner.less';
@import 'features/bio-page/bio-page.less';
@import 'features/track/track-tabs/track-broadcast-monitoring/modals/soundfile-tab-modals.less';
@import 'features/release/release-edit/metadata/distribution/distribution-form/distribution-form.less';
@import 'features/dsps/components/dsp-icons/dsp-icons.less';
@import 'features/dsps/components/dsp-selection/dsp-selection.less';
@import 'features/lyrics/lyrics.less';
@import 'features/subscriptions/modals/subscriptions-modals.less';
@import 'features/subscriptions/subscriptions.less';
@import 'components/subscriptions-payment/beatport-modal.less';
@import 'components/subscriptions-payment/beatport-sales-banner.less';
@import 'features/statement-transaction/statement-transaction.less';
@import 'features/terms-conditions/terms-conditions.less';
@import 'features/track/track-board/track-board.less';
@import 'features/track/track-tabs/track-works-declaration/track-works-declaration.less';
@import 'pages/account/account.less';
@import 'pages/account/payment-preview.less';
@import 'pages/login/login.less';
@import 'features/release/release-edit/release-edit-form/release-edit-form.less';
@import 'features/release/release-edit/metadata/base-data/base-data.less';
@import 'features/release/release-edit/metadata/artwork/artwork-page.less';
@import 'features/release/release-edit/metadata/tracklist/tracklist-page.less';
@import 'features/release/release-edit/metadata/distribution/distribution-page.less';
@import 'features/release/release-edit/components/release-preview/release-preview.less';
@import 'features/release/release-edit/metadata/review/review-page.less';
@import 'features/release/release-list/release-list.less';
@import 'features/release/release-list/components/incomplete-user-card.less';
@import 'features/stats/stats-filters/components/filter-components.less';
@import 'features/stats/stats-filters/stats-filters.less';
@import 'features/stats/stats.less';
@import 'features/track/track.less';
@import 'features/track/track-tabs/track-tabs.less';
@import 'features/track/track-tabs/track-audio/components/track-audio.less';
@import 'pages/register/register.less';
@import 'pages/email-verification/email-verification.less';
@import 'features/landing-page/components/styles.less';
@import 'features/landing-page/components/release-recent.less';
@import 'features/upload-certificate/upload-certificate.less';
@import 'components/resource-form-item/resource-form-item.less';
@import 'components/sider-nav/sider-nav.less';
@import 'features/share-audio/share-audio.less';
@import 'features/track/track-tabs/track-notes/track-notes.less';
@import 'features/track/track-tabs/track-metadata/track-metadata.less';
@import 'components/track-mini-player/track-mini-player.less';
@import 'features/track/track-tabs/track-audio/track-audio-tab.less';
@import 'features/promo-partners/promo-partners.less';
@import 'features/royalties/royalties.less';
@import 'components/avatar/avatar.less';
@import 'features/share-release/share-release.less';
@import 'features/rewind/rewind.less';
@import 'features/release/components/file-list/release-file-list.less';
@import 'features/news/news.less';
