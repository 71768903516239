.user-register-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1.ant-typography {
    margin-bottom: @space-32;
  }

  span.ant-typography.text-label-default {
    margin-bottom: @space-8;
  }

  form.register-form {
    width: 100%;

    div.register-title-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      @media screen and (max-width: @screen-xs-min) {
        margin-bottom: @space-16;
      }

      .ant-typography:first-of-type {
        margin-bottom: @space-8;
      }
    }

    .mh-terms-link {
      color: @primary-6;
      font-weight: 600;
      font-size: inherit;
      margin-left: @space-4;

      &:hover {
        text-decoration: underline;
      }
    }

    h1.ant-typography {
      text-align: center;
    }

    div.ant-row.ant-form-item {
      margin-top: 0;
    }

    div.user-segment-fields {
      width: 100%;
    }

    div.signup-actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      button.user-signup-next {
        margin-bottom: @space-24;
      }
    }

    .register-form-actions {
      display: flex;
      flex-direction: column;

      div.ant-col:first-of-type {
        margin-bottom: @space-24;
      }

      .register-go-back {
        div.ant-space-item {
          display: flex;
          margin-right: @space-8;
        }
      }
    }
  }

  .user-segmentation-form {
    .ant-card-body {
      h1.ant-typography.text-headline-l {
        margin-bottom: @space-32;
      }
    }
  }
}

.register .terms-checkbox {
  font-size: @font-size-sm;
}

.gema-box,
.non-gema-box {
  position: relative;
  overflow-y: hidden;
  overflow-x: visible;

  button {
    div.ant-space-item {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      span.ant-typography {
        white-space: inherit;
      }
    }
  }

  .box-image {
    display: inline-flex;
    position: absolute;
    text-align: right;
    max-width: 40%;

    svg {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: @screen-xs-min) {
      display: none;
    }
  }

  &.non-gema-box {
    .box-image {
      bottom: 0;
      right: 10px;
    }
  }

  &.gema-box {
    .box-image {
      bottom: 14px;
      right: 28px;
      max-width: 50%;

      svg {
        max-width: 110px;
      }

      @media screen and (max-width: @screen-sm-max) {
        top: 20px;
        max-width: 20%;
      }
    }
  }
}
