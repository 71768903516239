.ant-tag.status-tag {
  border-radius: @space-4;
  padding: @space-4 @space-8;
  display: inline-flex;
  align-items: center;
}

.status-icon {
  border-radius: 50%;
  display: inline-block;
  width: @space-10;
  height: @space-10;
  margin-right: @space-8;
}
