.custom-avatar {
  &.custom-avatar__large {
    height: 40px;
    width: 40px;
    border: none;

    svg {
      height: 18px;
      width: 18px;
    }
  }

  &.custom-avatar__small {
    height: 20px;
    width: 20px;
    border: none;

    svg {
      height: 9px;
      width: 9px;
    }
  }

  &:has(svg) {
    background-color: @neutral-5;
  }

  &.custom-avatar-pointer {
    cursor: pointer;
  }
}
