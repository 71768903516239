.release-edit-footer-steps-action {
  align-items: center;
  position: sticky;
  bottom: 0;
  z-index: 999;
  left: 0;
  background-color: @secondary-1;
  box-shadow: 0px -1px @space-4 rgba(168, 162, 193, 0.2);
  height: @space-80;

  @media only screen and (max-width: @screen-sm-min) {
    height: @space-64;
  }

  div.ant-space {
    justify-content: inherit;
  }
}

.add-performer-btn.form-back-link {
  font-size: @size-14;
}

.subscription-release-alert {
  margin-bottom: @space-32;

  .ant-alert-message {
    .ant-typography {
      margin: 0;
    }
  }
}
