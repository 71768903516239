.transaction-list-item {
  .transaction-item-row {
    width: 100%;
    align-items: center;

    .ant-col {
      display: inline-flex;
      justify-content: flex-end;
      margin: @space-16 0;
    }

    .ant-col:first-of-type {
      justify-content: flex-start;
      margin: 0;
    }

    .ant-col:last-of-type {
      margin: 0;
    }

    @media screen and (min-width: @screen-md-min) {
      .ant-col {
        margin: 0;
      }
    }
  }

  &.ant-list-item {
    padding: @space-16;
    background-color: @white;

    @media screen and (min-width: @screen-sm-min) {
      padding: @space-16 @space-32;
    }

    &:first-child {
      border-radius: @border-radius-base @border-radius-base 0 0;
    }

    &:last-child {
      border-radius: 0 0 @border-radius-base @border-radius-base;
    }

    .ant-list-item-meta {
      .ant-list-item-meta-avatar {
        display: flex;
        background: @neutral-3;
        border-radius: 50%;
        padding: 14px;
      }

      .ant-list-item-meta-description {
        color: @neutral-7;
      }
    }

    .ant-list-item-avatar {
      margin-right: @space-40;
    }
  }
}
