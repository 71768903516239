.yt-content-id-modal {
  .yt-mail-preview {
    width: 100%;
    background: @white;
    padding: @space-24;
    border-radius: @space-8;

    .yt-place-holder {
      width: 270px;
      height: @space-16;
      background: @neutral-4;
      border-radius: 2px;
    }

    .yt-mail-preview-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: fit-content;
      background: #1990e3;
      border-radius: @space-4;
      padding: @space-4 @space-16;
    }
  }

  .yt-footer {
    justify-content: space-between;
  }

  .ant-modal-body {
    .ant-space-vertical {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: @screen-xs-max) {
  .yt-content-id-modal {
    .yt-warning-icon {
      overflow: visible;
    }

    .yt-mail-preview {
      padding: @space-16;
      .yt-place-holder {
        width: 152px;
      }
      .yt-mail-preview-button {
        height: 22px;
      }
    }

    .yt-footer {
      .ant-space-item:first-child {
        align-self: flex-start;
      }
    }
  }
}
