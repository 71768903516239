.data-table-simple {
  .ant-table-thead {
    .ant-table-cell {
      background: none;
      border-bottom: 1px solid @neutral-4;
      padding: @space-8;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding-left: @space-8;
      padding-right: @space-8;
    }
  }

  .ant-table-row:last-child > td {
    border: 0;
  }
}
