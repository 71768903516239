.top-playlists-card-desktop,
.top-playlists-card-mob {
  .ant-card-head {
    border-bottom: none;

    .ant-card-head-title {
      padding: 0;

      .ant-typography.text-body-s {
        margin-left: @space-8;
      }
    }
  }

  .ant-card-body {
    padding: 0 @space-32 @space-10;

    .ant-table-empty {
      .ant-table-tbody {
        .ant-table-placeholder {
          tr.ant-table-placeholder,
          td {
            border: none;
          }
        }
      }
    }
  }
}

.top-playlists-card-desktop {
  overflow-y: hidden;
  overflow-x: auto;

  .ant-card-head {
    padding: @space-32 @space-32 @space-18;
  }

  .top-playlist-title .ant-typography {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}

.top-playlists-card-mob {
  .ant-card-head {
    padding: @space-24 @space-16;

    .ant-card-head-title {
      display: flex;
      width: 100%;

      .ant-typography.text-headline-s {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .ant-card-body {
    padding: 0 @space-16 @space-24;

    .ant-list-item {
      .title-container {
        display: flex;
        align-items: center;
        max-width: 66%;

        .top-playlist-text,
        .top-playlist-link {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .dsp-mobile {
          display: inline-flex;

          svg {
            margin-left: 0.4rem;
            overflow: visible;
            transform: scale3d(0.8, 0.8, 0.8);
          }
        }

        .top-playlist-link {
          text-decoration: underline;
        }
      }
    }
  }
}

.top-playlists-table {
  .ant-table-thead {
    .ant-table-cell {
      background: none;
      border-bottom: 1px solid @neutral-4;
      padding: 0px @space-8;
      padding-bottom: 0.8rem;
    }
  }

  .ant-table-tbody {
    .top-playlist-row {
      .ant-table-cell {
        border-bottom: 1px solid @neutral-4;
        padding: @space-16 @space-8;

        .dsp-cell {
          display: flex;
          align-items: center;

          svg {
            margin-right: 0.4rem;
            overflow: visible;
          }
        }

        .ant-typography {
          margin: 0;
        }

        span.text-body-s {
          white-space: nowrap;
          width: 90%;
          display: list-item;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a.top-playlist-link:hover {
          text-decoration: underline;
          color: inherit;
        }
      }
    }
    .top-playlist-row:last-of-type {
      .ant-table-cell {
        border-bottom: none;
      }
    }
  }
}
