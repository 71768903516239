.list-loader-container {
  border-radius: @border-radius-base;
}

.list-loader-item {
  background-color: @white;

  > svg {
    padding-left: @space-32;
  }
}
