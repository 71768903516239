.artwork-spacer {
  height: 40px;
}

div.ant-col.artwork-upload {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: @screen-md-max) {
    margin-bottom: @space-24;
  }

  .ant-upload-picture-card-wrapper {
    position: relative;
    display: flex;
    width: 100%;

    .ant-upload.ant-upload-select {
      display: flex;
      padding-top: 100%;
      width: 100%;
      background-color: rgba(158, 247, 209, 0.16);
      border: 1px dashed @primary-5;

      &:hover {
        background-color: rgba(158, 247, 209, 0.16);
        border: 1px dashed @primary-5;
      }

      &:has(img) {
        border: none;

        &:hover {
          border: none;
        }
      }
    }

    @media screen and (max-width: @screen-md-max) {
      max-width: 30rem;
    }
  }

  .remove-artwork-btn {
    margin-left: -@space-16;
  }
}

.artwork-upload span.ant-upload {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.artwork-upload .artwork-upload-img {
  width: 100%;
  height: 100%;
  border-radius: @border-radius-base / 2;
}

.ant-upload-drag-icon {
  padding: @space-24;

  svg {
    height: @space-48;
    width: @space-48;
  }

  .ant-upload-text {
    padding-bottom: @space-8;
  }
}

.artwork-requirements {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: @space-16;
  height: 100%;
  justify-content: center;
  white-space: pre-wrap;

  a {
    text-decoration: underline;
  }

  .full-req-button {
    padding: 0px !important;
    height: fit-content !important;
  }
}

.artwork-row {
  align-items: flex-start;
}
