.ant-card.top-locations-card {
  border: none;
  overflow: scroll;

  @media screen and (min-width: @screen-sm-min) {
    border: 1px solid @neutral-5;
    width: 390px;
    max-height: 84%;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0;

    .top-country-title-stick {
      padding: @space-24 @space-24 @space-16;
      position: sticky;
      top: 0;
      background-color: @white;
      border-bottom: 1px solid transparent;
      transition: border-bottom 0.2s;

      &:not(.stuck) {
        transition: border-bottom 0.2s;
        border-bottom-color: @neutral-5;
        border-bottom-width: 1px;
      }

      h1.ant-typography {
        margin: 0;
      }
    }

    .country-rows {
      margin-top: @space-8;
      padding: 0 @space-24 @space-24;
    }

    .ant-row.w-100 {
      justify-content: space-between;
      margin-bottom: @space-16;
    }

    .ant-row.w-100:last-of-type {
      margin-bottom: 0;
    }

    .no-data-card {
      padding: 0 @space-24 @space-24;
    }
  }

  &.disabled {
    height: 376px;
    background: @secondary-1;
    border: 1px solid @neutral-5;
    .top-country-title-stick {
      background: @secondary-1;
    }
  }
}
