.box {
  width: 100%;

  &.box-padding-large {
    padding: @space-unit * 7.5 @space-unit * 5;

    &.box-rounded {
      border-radius: @space-unit;
    }
  }

  &.box-padding-medium {
    padding: @space-unit * 4;

    &.box-rounded {
      border-radius: @space-unit / 2;
    }
  }
}
