.ant-card.incomplete-user-details-card {
  border: 1px solid @primary-5;
  margin-bottom: @space-40;

  .ant-card-body {
    .ant-row {
      @media screen and (max-width: @screen-sm-min) {
        text-align: center;
      }

      .ant-col {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: @screen-sm-min) {
          justify-content: center;
          align-items: center;
        }
      }

      .incomplete-account-text {
        margin-top: @space-24;
        margin-bottom: @space-24;
        white-space: pre-line;

        span:last-of-type {
          margin-top: @space-24;
        }

        @media screen and (min-width: @screen-sm-min) {
          margin-top: 0;
          margin-bottom: @space-20;

          span:last-of-type {
            margin: 0;
          }
        }

        @media screen and (min-width: @screen-md-min) {
          margin: 0;
        }
      }

      .incomplete-account-cta {
        display: inline-flex;
        align-items: center;

        @media screen and (min-width: @screen-md-min) {
          align-items: flex-end;
        }

        button {
          max-width: 200px;
        }
      }
    }
  }
}
