.login-title {
  text-align: center;
}

.login #firebaseui_container {
  padding: 0;
  margin: 0;
  background-color: transparent;

  .firebaseui-id-page-password-recovery,
  .firebaseui-id-page-password-reset-success {
    .firebaseui-card-content {
      .firebaseui-text {
        color: @neutral-9;
      }
    }
  }

  .firebaseui-card-content {
    padding: 0;
  }
}

.login .mdl-textfield--floating-label .mdl-textfield__label,
.password-forget-label {
  color: @neutral-8 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  top: 0px !important;
  visibility: visible !important;
  vertical-align: super;

  .typography.base();
  font-weight: @font-weight-medium !important;
}

.firebaseui-id-page-email-verification-success {
  .firebaseui-id-submit {
    display: none;
  }
}

.login {
  .mdl-card {
    padding: 0;
    min-height: 0;
    max-width: none;
  }

  .unauth-intro-text {
    list-style: none;
    padding-inline-start: 0;
    max-width: 88%;
    margin-bottom: 0;

    @media screen and (max-width: @screen-lg-min) {
      max-width: 100%;
    }

    @media screen and (max-width: @screen-xs-max) {
      margin-bottom: @space-96;
    }

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: @space-24;
      text-align: initial;

      @media screen and (max-width: @screen-sm-min) {
        margin-bottom: @space-16;
      }

      svg {
        overflow: visible;
      }

      span.ant-typography {
        margin-left: @space-8;
      }
    }
  }

  .firebaseui-title.firebaseui-title {
    display: none;
  }

  .firebaseui-card-actions {
    padding: @space-8 0 0 !important;
  }

  .firebaseui-card-header {
    display: none;
  }

  .firebaseui-form-actions {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .firebaseui-error-wrapper {
    min-height: 0;
  }

  .firebaseui-id-submit {
    width: 100% !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    height: 48px !important;
  }

  .mdl-button--primary.mdl-button--primary {
    background-color: transparent !important;
  }

  .firebaseui-card-actions {
    display: flex;
    flex-direction: column;
  }

  .login-or {
    display: flex;
    align-items: center;

    .login-or-separator {
      background-color: @neutral-5;
      width: 100%;
      height: 1px;
    }
  }

  .firebaseui-form-links {
    display: flex;
    width: 100%;
    order: 2;
    margin: @space-24 0 0 0;
    .firebaseui-link {
      text-align: center;
      width: 100%;
      color: @secondary-4;
    }
    .firebaseui-id-secondary-link {
      display: none;
    }
  }

  .firebaseui-form-actions {
    display: flex;
    width: 100%;
    order: 1;
    .firebaseui-id-secondary-link {
      order: 1;
      width: 100%;
      color: @secondary-5;
      border: 1px solid @secondary-3;
      height: 48px;
      margin-top: @space-16;
      &:hover {
        color: @secondary-6;
        border-color: @secondary-4;
      }
    }
  }

  .firebaseui-textfield.mdl-textfield .firebaseui-label:after {
    display: none;
  }
  .firebaseui-input-floating-button {
    display: none;
  }
}

.login .firebaseui-container {
  box-shadow: none;
  border-radius: 0;
}

.login .firebaseui-input,
.login .firebaseui-input-invalid {
  border: 1px solid @secondary-11 !important;
  padding: @space-8 @space-12 !important;
  border-radius: 0.4rem !important;
  font-size: @font-size-base;
}

.login .firebaseui-container * {
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.login .firebaseui-button {
  text-transform: none;
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
  margin: 0;
  font-weight: 600;
  line-height: normal;
}

.login .mdl-button--raised.mdl-button--colored {
  background-color: @primary-color;
  border-color: @primary-color;
  color: @secondary-6;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: none;

  &:active,
  &:hover,
  &:focus {
    background-color: @primary-2;
    border-color: @primary-2;
    box-shadow: none;
  }
  &:focus:not(:active) {
    background-color: @primary-color;
    border-color: @primary-color;
    box-shadow: none;
  }
}

.title-text-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.gema-terms-checkbox.ant-form-item {
  margin-bottom: @space-12;

  .ant-checkbox-wrapper {
    display: flex;
  }

  .ant-checkbox {
    height: @space-16;
  }

  .ant-checkbox-wrapper,
  .ant-checkbox-wrapper a {
    font-size: @size-12;
  }

  .ant-form-item-explain {
    font-size: @size-12;
  }
}

.firebaseui-id-page-email-verification-success,
.firebaseui-id-page-email-verification-failure {
  .firebaseui-text {
    text-align: center;
  }
}

.firebaseui-card-content {
  .firebaseui-text {
    text-align: center;
  }
}

.firebaseui-id-page-password-reset {
  .firebaseui-text {
    display: none;
  }
}
