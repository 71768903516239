@import './components/BalanceOverview/balance-overview.less';
@import './components//PendingTransaction/pending-transaction.less';
@import './components/TransactionListItem/transaction-list-item.less';
@import './components/BreakdownLine/breakdown-line.less';
@import './components/BreakdownByOptions/breakdown-by-options.less';

.royalty-income-filters {
  @media screen and (max-width: @screen-md-min) {
    flex-direction: column;
    margin-bottom: @space-16 !important;
  }
}

.royalty-income-sort-wrapper {
  @media screen and (max-width: @screen-md-min) {
    flex-grow: 1;
    margin-top: @space-16;
  }
}

.pie-chart-icon {
  font-size: 64px;
  color: @secondary-4;
}

.breakdown-chart-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;

  .breakdown-chart {
    display: flex;
    justify-content: center;

    canvas {
      width: 260px !important;
      height: 260px !important;
    }
  }

  .breakdown-chart-details {
    width: 470px;

    .breakdown-chart-details-table {
      .breakdown-chart-details-head {
        padding: @space-8;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid @secondary-2;
      }
    }
  }
}

.breakdown-list-container {
  .breakdown-list-header {
    align-items: center;
    border-radius: @space-8 @space-8 0px 0px;
    display: flex;
    justify-content: space-between;
    background-color: @secondary-6;
    padding: @space-24;

    h3 {
      margin-bottom: 0px;
    }
  }

  .breakdown-list {
    background-color: @white;
    .royalties-breakdown-artwork {
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
  }
}

@media screen and (max-width: @screen-md-min) {
  .breakdown-chart-container {
    flex-direction: column;
    .breakdown-chart {
      canvas {
        height: 130px !important;
        width: 260px !important;
      }
    }

    .breakdown-chart-details {
      width: unset;
    }
  }
}
