.track-form-wrapper-child {
  .ant-card {
    border-radius: 0px !important;

    .ant-card-head {
      border-bottom: none;
      min-height: auto;
      .ant-card-head-title {
        padding-bottom: 0px;

        .question-icon {
          font-size: @space-16;
        }
      }
    }
  }
}

div.track-title-card-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  button.collapse-track-card-btn,
  button.collapse-track-card-btn__mob {
    .collapse-btn-icon svg {
      transition: 0.2s ease-in;
      font-size: @size-18;
      max-width: @size-18;
    }

    span.ant-typography,
    .ant-space-item {
      display: inline-flex;
      align-items: center;
    }
  }

  button.ant-btn.custom-btn.custom-btn-secondary.custom-btn-secondary-outline.collapse-track-card-btn__mob {
    border: none;
    padding: 0;
  }

  button.ant-btn.custom-btn.tracklist-remove-track-btn {
    padding: 0;
    padding-left: @space-24 !important;

    &:hover,
    &:active,
    &focus,
    &.custom-btn-secondary-filled:focus:not(:focus-visible):not(a) {
      background-color: transparent;
    }
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    svg {
      font-size: 24px;
      max-width: 24px;
      max-height: 24px;
    }
  }
}

.placeholder-background {
  background: linear-gradient(
    to bottom,
    @secondary-2,
    @secondary-2 82%,
    #f5f3fd 82%,
    #f5f3fd
  );
  // 77px height + 16px margin between rows
  background-size: 100% 93px;
  background-position: top;
}

.draggable-space {
  margin-bottom: @space-16;
}

.track-preview-snippet {
  .ant-space-item-split {
    text-indent: -999999px;
    height: 0;
    display: block;
    width: 16px;
    border: 1px solid @neutral-6;
  }
}

.track-copy-meta-data .ant-card-body {
  display: flex;
  justify-items: flex-end;
  flex-direction: row-reverse;

  .copy-meta-data-tooltip-disabled {
    cursor: not-allowed;
  }
}

.track-copy-meta-data__menu {
  max-height: 230px;
  max-width: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: @space-4;

  > .ant-dropdown-menu-item-only-child {
    margin-bottom: @space-4;
  }

  .ant-dropdown-menu-item-group-title {
    border-top: 1px solid @neutral-4;

    .ant-typography {
      margin-top: @space-4;
    }
  }

  .ant-dropdown-menu-item-group-title,
  .ant-dropdown-menu-item {
    padding-top: @space-8 !important;
    padding-bottom: @space-8 !important;
  }

  .ant-dropdown-menu-item:hover {
    border-radius: @space-4;
  }

  .ant-typography {
    display: flex;
    align-items: center;
    gap: @space-12;
  }

  .ant-dropdown-menu-item-group-list {
    margin: 0 !important;
  }
}

.track-audio-review {
  .ant-tag {
    border-radius: 8px;
    margin-right: 0px;

    .tag-content {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      height: 32px;
      padding-left: @space-4;
      padding-right: @space-4;

      span {
        max-width: 148px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ant-upload-drag-container .ant-btn {
    min-width: 110px !important;
  }

  .ant-card {
    border-top: 0 !important;

    .ant-form-item-control-input {
      padding: 0 0 0 12px;
    }
  }
}

.track-audio-review.rounded,
.track-form-audio-replace-rounded {
  .ant-card {
    border-radius: 8px !important;

    &:not(:has(.track-mini-player)) {
      .button-file-upload {
        width: 100%;

        .ant-upload.ant-upload-drag {
          width: 100%;

          .ant-upload.ant-upload-btn {
            width: 100%;

            .ant-btn {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: @screen-sm) {
    .track-mini-player {
      padding-top: @space-24;
    }
  }
}

.track-copy-meta-data-buttons {
  justify-content: center;
}

.lyrics-content-wrapper {
  padding: 0 @space-12;

  @media screen and (max-width: @screen-sm) {
    padding: 0;
  }
}

.lyrics-content {
  white-space: pre-line;

  @media screen and (max-width: @screen-sm) {
    .lyrics-content {
      margin-top: @space-16;
    }
  }
}

.lyrics-button-row {
  margin-bottom: @space-32;

  @media screen and (max-width: @screen-sm) {
    margin-bottom: @space-16;
  }
}
