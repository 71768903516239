.custom-card {
  background-color: white;
  border-radius: @space-8;
  width: 100%;
}

.custom-card__header {
  padding: @space-16 @space-16 0 @space-16;

  @media screen and (min-width: @screen-md-min) {
    padding: @space-24 @space-32 0 @space-32;
  }

  .custom-card__heading {
    display: flex;
    justify-content: space-between;
  }
}

.custom-card__content {
  padding: @space-16;

  @media screen and (min-width: @screen-md-min) {
    padding: @space-32;
  }
}

&.custom-card-sm {
  .custom-card__content {
    padding: @space-16;

    @media screen and (min-width: @screen-md-min) {
      padding: @space-24;
    }
  }
}

.custom-card .row-info-item:last-child {
  .field-value,
  .field-label {
    margin-bottom: 0;
  }
}

.custom-card-group {
  .custom-card {
    border-radius: unset;
    border-bottom: 1px solid @secondary-2;
    &:first-child {
      border-top-right-radius: @space-8;
      border-top-left-radius: @space-8;
    }
    &:last-child {
      border-bottom-right-radius: @space-8;
      border-bottom-left-radius: @space-8;
      border-bottom: unset;
    }
  }
}
