.account-details-form,
.subscription-payment-form {
  display: flex;
  margin-top: 0;

  .ant-card-body {
    .ant-row:last-of-type {
      .ant-col {
        .ant-form-item {
          margin-bottom: 0;
        }
      }

      @media screen and (max-width: @screen-sm-min) {
        .ant-col {
          .ant-form-item {
            margin-bottom: @space-32;
          }
        }

        .ant-col:last-of-type {
          .ant-form-item:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .change-password-container input {
    width: 397px;
  }
  .profile-image-section {
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: @neutral-5;
    margin-right: @space-16;
    color: @white;

    .profile-image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      object-position: center;
    }
  }
}

h1.ant-typography.subscriptions-title {
  margin-bottom: @space-32;

  @media screen and (max-width: @screen-md-max) {
    margin-top: 0;
    margin-bottom: @space-16;
  }
}

.faq-section {
  margin-top: @space-60;

  @media screen and (max-width: @screen-md-max) {
    margin-top: @space-40;
  }
}

.subscription-loading {
  display: flex;
  justify-content: center;
}

div.subscription-voucher {
  height: auto;

  .voucher-column {
    margin-top: @space-8;
  }

  @media screen and (max-width: @screen-md-min) {
    .voucher-column,
    .voucher-column .ant-row {
      margin: 0 !important;
    }
  }

  @media screen and (max-width: @screen-xs-max) {
    max-height: @space-84;
    flex-direction: column;

    .subscription-voucher-input {
      margin-top: @space-16;
    }
  }
}

div.account-details-alert-ctr {
  width: 100%;
}

div.link-wrapper {
  a.link-text {
    font-weight: 400;
    text-decoration: underline;
    color: @neutral-8;
  }
  a.small-text {
    font-size: @size-12;
  }
}

.ant-card.example-stripe-statement {
  background-color: @neutral-2;
  border: 1px solid @neutral-4;
  padding: @space-8 @space-12;

  .ant-card-body,
  .ant-card-head {
    padding: 0;
  }

  .ant-card-head {
    border-bottom: none;
    min-height: auto;

    .ant-card-head-title,
    .ant-card-extra {
      padding: 0;
    }
  }
}

.add-ons-card {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  background-clip: padding-box;
  border: solid 1px transparent;
  border-radius: 4px;

  &.add-ons-card--disabled {
    border: solid 1px @neutral-5;
    background-color: @neutral-2 !important;

    .beatport-disabled-heading {
      margin-left: @space-8;
    }

    * {
      color: @neutral-6 !important;
      fill: @neutral-6 !important;
    }

    .beatport-main-heading {
      color: @neutral-9!important;
    }
  }

  &:not(.add-ons-card--disabled) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: -1px;
      border-radius: inherit;
      background: linear-gradient(
        222.91deg,
        #a547fb 0%,
        #9747ff 49.88%,
        #6affbf 99.76%
      );
    }
  }

  .custom-card__header,
  .custom-card__content {
    border-radius: 4px 4px 0 0;
    position: relative;
    background-color: inherit;
    z-index: 1;
  }

  .custom-card__content {
    border-radius: 0 0 4px 4px;
  }
}
