textarea.custom-input-text-area::placeholder {
  color: @neutral-7;
}

.custom-input-text-area-container {
  display: flex;
  flex-direction: column;

  textarea.custom-input-text-area {
    padding: @space-8 @space-12;
    border: 1px solid @neutral-5;
    font-family: @font-family;

    &.custom-input-text-area-large {
      min-height: 76px;
    }

    &:hover {
      border: 1px solid @neutral-7;
    }

    &:focus {
      border: 1px solid @primary-4;
      box-shadow: 0 0 4px 0 rgba(93, 179, 147, 0.5);
    }

    &:disabled {
      background-color: @neutral-3;
      border: 1px solid @neutral-5;
    }
  }

  .custom-typography.max-length-text {
    align-self: flex-end;
    margin-top: @space-4;
  }

  .custom-input-text-area-large {
    font-size: @body-s-desktop-font-size;
  }

  .custom-input-text-area {
    border-radius: @space-4;
  }
}
