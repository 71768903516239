.ant-input-search.ant-input-group-wrapper {
  .ant-input-group-addon {
    display: none;
  }

  .ant-input-group {
    .ant-input-affix-wrapper {
      border-radius: 4px;
    }
    .ant-input-suffix {
      svg {
        color: @neutral-7;
      }
    }
  }
}
