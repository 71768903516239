.card-with-audio {
  .ant-tag {
    @media screen and (max-width: @screen-md) {
      .track-mini-player {
        margin-top: @space-8;
      }
    }
  }
  .card-audio-player {
    @media screen and (max-width: @screen-md) {
      margin-top: @space-16;
    }
  }
}
