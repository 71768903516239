:root {
  --primary-color: @primary-color;
  --secondary-color: @secondary-color;
  --neutral-color: @neutral-color;
  --danger-color: @danger-color;
  --warning-color: @warning-color;
  --success-color: @success-color;
  --info-color: @info-color;
  --accent-color: @accent-color;
  --white: @white;

  --primary-1: @primary-1;
  --primary-2: @primary-2;
  --primary-3: @primary-3;
  --primary-4: @primary-4;
  --primary-5: @primary-5;
  --primary-6: @primary-6;
  --primary-7: @primary-7;

  --secondary-1: @secondary-1;
  --secondary-2: @secondary-2;
  --secondary-3: @secondary-3;
  --secondary-4: @secondary-4;
  --secondary-5: @secondary-5;
  --secondary-6: @secondary-6;
  --secondary-7: @secondary-7;

  --neutral-1: @neutral-1;
  --neutral-2: @neutral-2;
  --neutral-3: @neutral-3;
  --neutral-4: @neutral-4;
  --neutral-5: @neutral-5;
  --neutral-6: @neutral-6;
  --neutral-7: @neutral-7;
  --neutral-8: @neutral-8;
  --neutral-9: @neutral-9;

  --success-1: @success-1;
  --success-2: @success-2;
  --success-3: @success-3;
  --success-4: @success-4;
  --success-5: @success-5;
  --success-6: @success-6;

  --warning-1: @warning-1;
  --warning-2: @warning-2;
  --warning-3: @warning-3;
  --warning-4: @warning-4;
  --warning-5: @warning-5;
  --warning-6: @warning-6;

  --danger-1: @danger-1;
  --danger-2: @danger-2;
  --danger-3: @danger-3;
  --danger-4: @danger-4;
  --danger-5: @danger-5;
  --danger-6: @danger-6;

  --info-1: @info-1;
  --info-2: @info-2;
  --info-3: @info-3;
  --info-4: @info-4;
  --info-5: @info-5;
  --info-6: @info-6;

  --accent-1: @accent-1;
  --accent-2: @accent-2;
  --accent-3: @accent-3;
  --accent-4: @accent-4;
  --accent-5: @accent-5;
  --accent-6: @accent-6;

  --primary-font-family: @font-family;
}
