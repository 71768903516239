.ant-btn.custom-btn {
  line-height: 1;
  border: 0;
  transition: none !important;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:not(.ant-btn-loading) .ant-btn-icon {
    margin-inline-end: unset !important;
  }

  &:disabled {
    pointer-events: none;
  }

  .custom-btn__icon {
    display: flex;
    align-items: center;
  }

  .custom-btn__typography {
    max-width: 100%;
  }

  .custom-btn__typography {
    vertical-align: middle;
    line-height: 1.3;
  }

  &.ant-btn-loading {
    &:before {
      display: none;
    }
    .ant-btn-loading-icon {
      margin-right: @space-8;
    }
  }

  &:after {
    display: none;
  }

  &.custom-btn-primary.custom-btn-primary-filled {
    .btnFill(@primary-3, @primary-4, @primary-5, @primary-3, @neutral-4);
    .btnColor(
      @secondary-7,
      @secondary-7,
      @secondary-7,
      @secondary-7,
      @neutral-6
    );
    .btnBorder(
      transparent,
      transparent,
      transparent,
      @primary-3,
      transparent,
      @white
    );
  }

  &.custom-btn-primary.custom-btn-primary-outline {
    .btnFill(transparent, transparent, @primary-2, @primary-2, transparent);
    .btnColor(@primary-3, @primary-4, @primary-6, @primary-6, @primary-3);
    .btnBorder(
      @primary-3,
      @primary-4,
      @primary-3,
      @primary-3,
      @primary-3,
      @primary-3
    );
  }

  &.custom-btn-primary.custom-btn-primary-link {
    .btnFill(transparent, transparent, transparent, @primary-1, transparent);
    .btnColor(@primary-6, @primary-7, @primary-7, @primary-6, @neutral-6);
    .removeShadow();
  }

  &.custom-btn-secondary.custom-btn-secondary-filled {
    .btnFill(
      @secondary-6,
      @secondary-5,
      @secondary-5,
      @secondary-6,
      @neutral-4
    );
    .btnColor(@white, @white, @white, @white, @neutral-6);
    .btnBorder(
      transparent,
      transparent,
      transparent,
      @secondary-3,
      transparent,
      @white
    );
  }

  &.custom-btn-secondary.custom-btn-secondary-outline {
    .btnFill(transparent, transparent, @secondary-2, @secondary-2, @neutral-2);
    .btnColor(
      @secondary-5,
      @secondary-6,
      @secondary-6,
      @secondary-6,
      @neutral-6
    );
    .btnBorder(
      @secondary-3,
      @secondary-4,
      @secondary-4,
      @secondary-4,
      @neutral-4,
      @white
    );
  }

  &.custom-btn-secondary.custom-btn-secondary-outline-light {
    .btnFill(transparent, transparent, transparent, @secondary-2, @neutral-2);
    .btnColor(
      @secondary-2,
      @secondary-4,
      @secondary-2,
      @secondary-4,
      @neutral-6
    );
    .btnBorder(
      @secondary-3,
      @secondary-4,
      @secondary-4,
      @secondary-4,
      @neutral-4,
      @white
    );
  }

  &.custom-btn-secondary.custom-btn-secondary-link {
    .btnFill(transparent, transparent, transparent, @secondary-2, transparent);
    .btnColor(
      @secondary-5,
      @secondary-6,
      @secondary-6,
      @secondary-6,
      @neutral-6
    );
    .removeShadow();
  }

  &.custom-btn-secondary.custom-btn-secondary-text {
    box-shadow: none;
    padding: 0;
    height: fit-content;

    .btnFill(transparent, transparent, transparent, @secondary-2, transparent);
    .btnColor(
      @secondary-5,
      @secondary-6,
      @secondary-6,
      @secondary-6,
      @neutral-6
    );
  }

  &.custom-btn-secondary.custom-btn-secondary-carousel {
    .btnFill(transparent, transparent, @secondary-2, @secondary-2, transparent);
    .btnColor(
      @secondary-5,
      @secondary-6,
      @secondary-6,
      @secondary-6,
      @neutral-6
    );
    .btnBorder(
      @secondary-3,
      @secondary-4,
      @secondary-4,
      @secondary-4,
      @neutral-4,
      @white
    );
  }

  &.custom-btn-danger.custom-btn-danger-filled {
    .btnFill(@danger-4, @danger-5, @danger-6, @danger-4, @neutral-5);
    .btnColor(@white, @white, @white, @white, @neutral-6);
    .btnBorder(
      transparent,
      transparent,
      transparent,
      @danger-6,
      transparent,
      @white
    );
  }

  &.custom-btn-danger.custom-btn-danger-outline {
    .btnFill(transparent, transparent, @danger-2, @danger-2, transparent);
    .btnColor(@danger-4, @danger-6, @danger-6, @danger-4, @neutral-6);
    .btnBorder(@danger-4, @danger-6, @danger-6, @danger-4, @neutral-6, @white);
  }

  &.custom-btn-danger.custom-btn-danger-link {
    .btnFill(transparent, transparent, transparent, @danger-2, transparent);
    .btnColor(@danger-4, @danger-6, @danger-6, @danger-4, @neutral-6);
    .removeShadow();
  }

  &.custom-btn-success.custom-btn-success-filled {
    .btnFill(@success-4, @success-6, @success-6, @success-4, @neutral-5);
    .btnColor(@white, @white, @white, @white, @neutral-6);
    .btnBorder(
      transparent,
      transparent,
      transparent,
      @success-6,
      transparent,
      @white
    );
  }

  &.custom-btn-success.custom-btn-success-outline {
    .btnFill(transparent, transparent, @success-2, @success-2, transparent);
    .btnColor(@success-4, @success-6, @success-6, @success-4, @neutral-6);
    .btnBorder(
      @success-4,
      @success-6,
      @success-6,
      @success-4,
      @neutral-6,
      @white
    );
  }

  &.custom-btn-warning.custom-btn-warning-filled {
    .btnFill(@warning-4, @warning-5, @warning-4, @warning-4, @neutral-4);
    .btnColor(@white, @white, @white, @white, @white);
    .btnBorder(
      transparent,
      transparent,
      @warning-6,
      @warning-6,
      transparent,
      transparent
    );
  }

  &.custom-btn-warning.custom-btn-warning-outline {
    .btnFill(transparent, transparent, @warning-2, @warning-2, transparent);
    .btnColor(@warning-4, @warning-6, @warning-6, @warning-4, @neutral-6);
    .btnBorder(
      @warning-4,
      @warning-6,
      @warning-6,
      @warning-4,
      @neutral-6,
      @white
    );
  }

  &.custom-btn-info.custom-btn-info-outline {
    .btnFill(transparent, transparent, @info-2, @info-2, transparent);
    .btnColor(@info-4, @info-6, @info-6, @info-4, @neutral-6);
    .btnBorder(@info-4, @info-6, @info-6, @info-4, @neutral-6, @white);
  }

  &.custom-btn-success.custom-btn-success-link {
    .btnFill(transparent, transparent, transparent, @success-2, transparent);
    .btnColor(@success-4, @success-6, @success-6, @success-4, @neutral-6);
    .removeShadow();
  }

  &.custom-btn-accent.custom-btn-accent-filled {
    .btnFill(@accent-4, @accent-5, @accent-4, @accent-4, @neutral-4);
    .btnColor(@white, @white, @white, @white, @white);
    .btnBorder(
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent
    );
  }

  &.custom-btn-tab.custom-btn-s,
  &.custom-btn-tab.custom-btn-l,
  &.custom-btn-tab.custom-btn-m {
    padding: 10px @space-16;
    border-radius: @space-8;
  }

  &.custom-btn-tab.custom-btn-tab-filled {
    .btnFill(
      @secondary-4,
      @secondary-4,
      @secondary-4,
      @secondary-4,
      @secondary-4
    );
    .btnColor(@white, @white, @white, @white, @white);
    .btnBorder(
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent
    );
  }

  &.custom-btn-tab.custom-btn-tab-link {
    .btnFill(transparent, transparent, transparent, transparent, transparent);
    .btnColor(
      @secondary-5,
      @secondary-5,
      @secondary-5,
      @secondary-5,
      @secondary-5
    );
    .btnBorder(
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent
    );
  }

  &.custom-btn-filter.custom-btn-filter-outline {
    .btnFill(transparent, transparent, @white, @white, transparent);
    .btnColor(
      @secondary-5,
      @secondary-5,
      @secondary-5,
      @secondary-5,
      @secondary-5
    );
    .btnBorder(
      transparent,
      transparent,
      @secondary-3,
      @secondary-3,
      transparent,
      transparent
    );
    border-radius: 4px;
    .removeShadow();
  }

  &.custom-btn-l {
    height: @space-48;
    padding: 14px 24px 14px 24px;

    svg {
      font-size: @button-l-desktop-font-size;
      max-width: @button-l-desktop-font-size;
      max-height: @button-l-desktop-font-size;
    }

    &.custom-btn-circle {
      padding: 0;
      width: @space-48;

      &.ant-btn-loading {
        .custom-btn__typography {
          display: none;
        }

        .ant-btn-loading-icon {
          margin-right: 0;
          width: 100%;
          .anticon {
            padding-right: 0;
          }
        }
      }
    }
  }

  &.ant-btn-loading {
    &.custom-btn-circle {
      .ant-btn-loading-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        width: 100%;
        .anticon {
          padding-right: 0;
        }
      }
    }
  }

  &.custom-btn-m {
    height: 40px;
    padding: 10px 16px;

    svg {
      font-size: @button-m-desktop-font-size;
      max-width: @button-m-desktop-font-size;
      max-height: @button-m-desktop-font-size;
    }

    &.custom-btn-circle {
      padding: 0;
      width: 40px;
    }
  }

  &.custom-btn-s {
    height: 32px;
    padding: 4px 16px;

    svg {
      font-size: @button-s-desktop-font-size;
      max-width: @button-s-desktop-font-size;
      max-height: @button-s-desktop-font-size;
    }

    &.custom-btn-circle {
      padding: 0;
      width: 32px;
    }

    &.custom-btn-icon-position-r {
      .custom-btn__typography {
        margin: 0 6px 0 0;
      }
    }

    &.custom-btn-icon-position-l {
      .custom-btn__typography {
        margin: 0 0 0 6px;
      }
    }
  }

  &.custom-btn-text-link {
    padding: 0 !important;
    height: auto !important;
  }

  &.custom-btn-grow {
    .custom-btn__typography {
      display: flex;
      width: 100%;
    }
  }

  &.custom-btn-shrink {
    padding-left: unset !important;
    padding-right: unset !important;
  }

  &.custom-btn-fit-content {
    min-width: fit-content !important;
  }

  &.custom-btn-icon-position-r {
    flex-direction: row-reverse;
    .custom-btn__typography {
      margin: 0 @space-8 0 0;
    }
  }

  &.custom-btn-icon-position-l {
    .custom-btn__typography {
      margin: 0 0 0 @space-8;
    }
  }

  &.custom-btn-icon {
    .btnFill(transparent, transparent, transparent, transparent, transparent);
    .removeShadow();
  }
}

.button-disabled-wrapper {
  cursor: not-allowed;
}

&:focus {
  .ant-wave {
    display: none;
  }
}

.btnFill(@base, @hover, @active, @focus, @disabled) {
  background-color: @base;
  &:hover {
    background-color: @hover;
  }
  &:focus:not(a) {
    background-color: @focus;
  }
  &:active:not(a),
  &:focus:not(:focus-visible):not(a) {
    background-color: @active;
  }
  &:disabled {
    background-color: @disabled;
  }
}

.btnBorder(@base, @hover, @active, @focus, @disabled, @focusInner: transparent) {
  border: 1px solid @base;
  &:hover {
    border-color: @hover;
  }
  &:focus {
    border-color: @focus;
    box-shadow: inset 0px 0px 0px 4px @focusInner;
  }
  &:active,
  &:focus:not(:focus-visible) {
    border-color: @active;
    box-shadow: none;
    outline: 1px solid @active;
  }
  &:disabled {
    border-color: @disabled;
  }
}

.removeShadow() {
  box-shadow: none;
}

.btnColor(@base, @hover, @active, @focus, @disabled) {
  * {
    color: @base;
  }
  &:hover {
    * {
      color: @hover;
    }
  }
  &:active,
  &:focus:not(:focus-visible) {
    * {
      color: @active;
      text-decoration: none;
    }
  }
  &:focus {
    * {
      color: @focus;
      text-decoration: underline;
    }
  }
  &:disabled {
    * {
      color: @disabled;
    }
  }
}
