.ant-switch.custom-switch-m {
  background-color: transparent;
  border: 1px solid @neutral-6;
  height: 24px;
  width: 40px;

  .ant-switch-handle {
    height: 20px;
    left: 1px;
    top: 1px;
    width: 20px;
  }

  .ant-switch-handle::before {
    background-color: @neutral-6;
    border-radius: 10px;
    box-shadow: none;

    -webkit-box-shadow: none;
  }
}

.ant-switch.ant-switch-disabled {
  pointer-events: none;
}

.ant-switch.custom-switch-m.ant-switch-checked {
  background-color: @primary-5;
  border-color: @primary-5;

  .ant-switch-handle {
    left: calc(100% - 20px - 1px) !important;
    top: 1px;
  }

  .ant-switch-handle::before {
    background-color: @white;
  }
}

.ant-switch.custom-switch-s {
  background-color: transparent;
  border: 1px solid @neutral-6;
  height: 20px;
  width: 32px;

  .ant-switch-handle {
    height: 16px;
    left: 1px;
    top: 1px;
    width: 16px;
  }

  .ant-switch-handle::before {
    background-color: @neutral-6;
    border-radius: 8px;
    box-shadow: none;
  }
}

.ant-switch.custom-switch-s.ant-switch-checked {
  background-color: @primary-5;
  border-color: @primary-5;

  .ant-switch-handle {
    left: calc(100% - 16px - 1px) !important;
    top: 1px;
  }

  .ant-switch-handle::before {
    background-color: @white;
  }
}

.custom-switch-disabled {
  opacity: 0.4;
}
