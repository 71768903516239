.payment-preview-container-tip {
  display: flex;
  align-items: flex-start;

  .ant-col.tip-layout-tips {
    position: sticky;
    top: @space-24;
  }

  .payment-preview {
    div.subscription-terms-action {
      a.subscription-terms-url {
        font-weight: 400;
        font-size: @space-12;
      }
      button.sub-btn {
        min-width: 183px;

        @media screen and (max-width: @screen-md-max) {
          margin-top: @space-16;
          width: 100%;
        }
      }
    }
  }

  .payment-preview-desktop {
    .payment-preview-footer {
      div.ant-space-item:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .payment-preview.payment-preview-mobile {
    background: white;
    padding: @space-24;
    padding-bottom: 0;
    border: 1px solid @secondary-2;
    border-radius: @space-4;

    .payment-preview-footer {
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 101;
      position: fixed;
      background: white;
      padding: @space-24 @space-24 @space-32;
      text-align: center;
    }
  }
}

.subscription-voucher-input--error {
  outline: 1px solid @danger-color;
}

.payment-preview .ant-space-item:has(.ant-form-item-hidden) {
  display: none !important;
}
