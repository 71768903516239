.track-form-actions {
  margin-top: 32px;
}

.audio-preview,
.wavalyzer-feedback {
  width: 100%;
  display: flex;
  padding: @space-24;
  flex-direction: row;
  background: @white;
  align-items: center;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.wavalyzer-feedback {
  border-top: 1px solid @secondary-2;
}
