.tip-info-box,
.tip-articles-box {
  padding: @space-24;
  border-radius: @border-radius-base;
}

.tip-info-box .icon,
.tip-articles-box .icon {
  font-size: @space-20;
}

.tip-info-box-primary .icon,
.tip-info-box-primary .tip-articles-box .icon {
  color: @primary-5;
}

.tip-info-box.tip-info-box-secondary,
.tip-articles-box.tip-articles-box-secondary {
  background: @secondary-2;
}

.tip-info-box.tip-info-box-primary {
  background: @neutral-1;
  border: 1px solid @secondary-2;
}

.tip-additional-links {
  padding-top: @space-24;
}

.ant-btn.custom-btn {
  .ant-typography {
    vertical-align: middle;
    line-height: 1.3;
    white-space: normal;
    text-align: left;
  }
}

.tooltip-image-link {
  width: 100%;
  img {
    margin-top: @space-4;
    width: 100%;
  }
}

.ant-col.tip-layout-children {
  display: inline-flex;
  flex-direction: column;
}

.tip-layout-stretch {
  flex-flow: row nowrap;
  .tip-layout-tips {
    z-index: 1;
  }
}
