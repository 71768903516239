.track-released-in-section-header.section-header,
.section-header-title {
  .released-in-title {
    margin: 0;
  }
}

.track-released-in-section-header {
  margin-top: @space-24;

  @media (max-width: @screen-md-min) {
    margin-top: @space-12;

    .section-header-title {
      h1 {
        margin-bottom: 0px;
      }
    }
  }
}

.track-released-in-section-child {
  .track-released-in-section-title {
    margin-bottom: 0;
  }
}
