.release-confirmation-modal {
  .nps-score-group {
    flex-wrap: wrap;
    justify-content: center;

    .ant-space-item {
      margin-bottom: 10px;
    }
  }

  .release-confirmation-modal-nps {
    .nps-score-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      .ant-radio-group {
        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
          color: @primary-5 !important;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          color: @primary-5 !important;
          border-color: @primary-5 !important;
        }
      }
    }

    .nps-group-labels {
      width: 60%;
      margin: 0 auto;
      justify-content: space-between;
    }

    .nps-very-unlikely-text-mobile,
    .nps-very-unlikely-text {
      text-align: left;
      align-self: flex-start;
    }

    .nps-very-unlikely-text {
      display: none;
    }

    .nps-very-likely-text {
      text-align: right;
    }

    @media (min-width: @screen-sm-max) {
      .nps-very-unlikely-text {
        display: flex;
      }

      .nps-very-unlikely-text-mobile {
        display: none;
      }
    }
  }

  .release-confirmation-modal-reason-question {
    margin-top: @space-20;
    margin-bottom: @space-10;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .release-confirmation-modal-reasons {
    display: flex;
    justify-content: space-between;
    text-align: left;

    .ant-checkbox-group {
      .ant-row {
        display: flex;
        width: 70%;
        margin: 0 auto;

        @media screen and (max-width: @screen-sm-min) {
          width: 90%;
        }

        @media screen and (min-width: @screen-md-min) {
          .ant-col:nth-child(2n) {
            display: flex;
            .ant-checkbox-wrapper {
              padding-left: 6rem;
            }
          }
        }
      }
    }

    .ant-checkbox-wrapper {
      margin-bottom: 10px;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
    }
  }

  .partition {
    height: 1px;
    background-color: @secondary-2;
    border: none;
    width: @large-modal-max-width;
  }

  .release-confirmation-modal-container {
    > .ant-space-item:last-child {
      width: 100%;
    }
  }

  .release-confirmation-modal-footer {
    justify-content: center;
  }
}
