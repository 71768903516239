.rewind {
  pointer-events: auto;
  user-select: none;

  .screen-wrapper {
    position: relative;

    .button-wrapper {
      display: flex;
      justify-content: center;
      height: 40px;
      z-index: 102;

      .download-button {
        align-self: center;
      }
    }

    .image-preview {
      height: 640px;
      object-fit: contain;
      background-color: transparent;
      border: none;
      z-index: 101;
    }

    .image-preview-fallback {
      height: 640px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      span > i {
        background-color: white;
      }
    }

    .rewind-backward {
      position: absolute;
      top: calc(50% - 44px);
      left: 0px;
    }

    .rewind-forward {
      position: absolute;
      top: calc(50% - 44px);
      right: 0px;
    }
  }
}

.rewind-progress {
  top: 0;
  left: 100px;
  z-index: 99;
  width: 360px;
  position: absolute;
  display: flex;
  flex-direction: column;

  .progress {
    gap: 10px;
    display: flex;
    padding: 0 @space-16 0 @space-16;

    .ant-progress-bg {
      width: 0%;
    }

    .progress-bar-in-progress {
      .ant-progress-bg {
        width: 100% !important;
        -webkit-transition: width 10s linear;
        -moz-transition: width 10s linear;
        -o-transition: width 10s linear;
        transition: width 10s linear;
      }
    }

    .progress-bar-ready,
    .progress-bar-unfilled {
      .ant-progress-bg {
        width: 0% !important;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none;
      }
    }

    .progress-bar-filled {
      .ant-progress-bg {
        width: 100% !important;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none;
      }
    }
  }
  .rewind-close-icon {
    color: white;
    font-size: @space-16;
    align-self: flex-end;
    padding: @space-16;
  }
}

.mobile-rewind {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999999; // above intercom
  background-color: black;

  .rewind-progress {
    left: 0;
    width: 100%;
  }

  .screen-wrapper {
    position: relative;

    .download-button {
      align-self: center;
    }
  }

  .slick-slide {
    height: 100vh;
  }

  .button-wrapper {
    position: fixed;
    top: calc(100vw * 1.78 - 100px);
    padding: @space-16;
    display: flex;
    gap: @space-8;
    width: 100vw;
    align-items: flex-end;
    max-height: 72px;
    z-index: 99999999999;

    .download-button,
    .share-button {
      display: flex;
      width: 50%;
      align-self: unset;

      &.full-width {
        width: 100%;
      }
    }
  }

  .image-preview {
    height: calc(100vw * 1.78);
  }

  .image-preview-fallback {
    height: calc(100vw * 1.78);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    span > i {
      background-color: white;
    }
  }
}

.rewind-image-bg-blur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  height: 100vh;
  filter: blur(100px);
  z-index: -1;
  @media screen and (min-width: @screen-sm-max) {
    display: none;
  }
}

.rewind-image-wrapper {
  width: 1080px;
  height: 1920px;
  position: absolute;
  bottom: 99999px;

  .rewind-footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 10;
    position: relative;

    > span {
      font-size: 52px !important;
      line-height: 72px !important;
    }
  }

  .rewind-intro {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 140px;
    background: radial-gradient(@white, #f9efff, @accent-3, #b666ff, #5616af);

    > h1 {
      font-size: 84px !important;
      line-height: 98px !important;
    }

    > span {
      font-size: 62px !important;
      line-height: 80px !important;
    }
  }

  .rewind-info {
    height: 100%;
    width: 100%;
    position: relative;
    background: @accent-6;

    .rewind-info-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      // background: radial-gradient(ellipse at top, #e66465, transparent),
      //   radial-gradient(ellipse at bottom, #4d9f0c, transparent);
      background: radial-gradient(
        circle 400px at 50% 45%,
        @accent-5,
        @accent-6
      );

      .rewind-info-background-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 400px;
        z-index: 2;
        background-image: radial-gradient(
          circle 400px at top right,
          @accent-5 0%,
          @accent-6 70%
        );
      }
    }

    .rewind-info-content {
      height: 100%;
      width: 100%;
      padding: 80px 80px 80px 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      z-index: 3;
      position: relative;

      .profile-image {
        width: 500px;
        height: 500px;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 80px;
        border: 5px solid white;
      }

      > h1 {
        font-size: 100px;
        line-height: 100px;
        margin-bottom: 80px;
      }

      > h2 {
        font-size: 90px;
        line-height: 120px;
        margin-bottom: 0px;
      }

      > span {
        font-size: 76px;
        line-height: 98px;
      }
    }
  }

  .rewind-streams {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 80px;
    background: #9a52f1;
    position: relative;

    .rewind-streams-wrapper {
      padding: 60px;

      .rewind-streams-title {
        font-size: 76px;
        line-height: 98px;
        margin: 0px;
      }
      .rewind-streams-title-number {
        font-size: 90px;
        line-height: 120px;
        margin: 0px;
      }

      .rewind-streams-title:first-child {
        margin-top: 240px;
      }

      .rewind-streams-bg {
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 2;
      }

      > span {
        font-size: 62px;
        line-height: 80px;
        margin-top: 100px;
        display: block;
      }
    }
  }

  .rewind-top-track {
    padding: 80px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-image: radial-gradient(
      circle 500px at 50% 45%,
      @accent-5 0%,
      @accent-5 20%,
      @secondary-7 100%
    );

    .rewind-top-track-content {
      display: flex;
      flex-direction: column;
      padding: 100px 30px 0px 30px;

      .rewind-top-track-headline {
        font-size: 100px;
        line-height: 120px;
        margin-bottom: 90px;
      }

      .rewind-top-track-player {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .rewind-top-track-cover-art {
          height: 640px;
          width: 640px;
          border-radius: 20px;
          margin-bottom: 60px;
        }

        .rewind-top-track-title {
          font-size: 76px;
          line-height: 80px;
          margin-bottom: 12px;
        }

        .rewind-top-track-artist {
          font-size: 54px;
          line-height: 80px;
          margin-bottom: 60px;
        }

        .rewind-top-track-player-controls {
          color: white;
          font-size: 45px;
        }
        .rewind-top-track-player-controls-center {
          color: white;
          font-size: 100px;
        }
      }
    }
  }

  .rewind-top-dsps {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: radial-gradient(
      circle 400px at top right,
      @accent-5 0%,
      @accent-6 70%
    );
    padding: 80px;

    .rewind-top-dsps-content {
      padding: 100px 30px 0px 30px;

      .rewind-top-dsps-title {
        font-size: 100px;
        line-height: 120px;
      }

      .rewind-top-dsps-list {
        margin-top: 110px;
        display: flex;
        flex-direction: column;
        gap: 40px;
      }

      .rewind-top-dsp {
        display: flex;
        align-items: center;

        .rewind-top-dsp-index {
          font-size: 90px;
          margin-right: 40px;
          margin-bottom: 0px;
          line-height: 120px;
          text-align: center;
          width: 68px;
          display: inline-block;
        }
        > svg {
          height: 110px;
          width: 110px;
          margin-right: 40px;
        }
        .rewind-top-dsp-name {
          font-size: 76px;
          line-height: 80px;
        }
      }

      .rewind-top-dsp.apple,
      .rewind-top-dsp.napster {
        > svg > path {
          fill: white;
        }
      }

      .rewind-top-dsp.amazon {
        > svg > path:nth-child(2) {
          fill: white;
        }
      }
    }
  }

  .rewind-top-country {
    background: #9a52f1;
    width: 100%;
    height: 100%;
    padding: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    .rewind-top-country-content {
      padding: 100px 30px 0px 30px;

      .rewind-top-country-title {
        font-size: 100px;
        line-height: 120px;
      }
      .rewind-top-country-subtitle {
        font-size: 76px;
        line-height: 98px;
      }
      .rewind-top-country-container {
        display: flex;
        align-items: center;
        gap: 40px;
        margin-top: 40px;

        .rewind-top-country-name {
          font-size: 76px;
          line-height: 80px;
        }
      }
      .rewind-top-country-bg {
        position: absolute;
        bottom: 0px;
        left: 0px;
      }
    }
  }

  .rewind-overview {
    width: 100%;
    height: 100%;
    padding: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .rewind-overview-content {
      padding: 100px 30px 0px 30px;
      z-index: 3;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      h1 {
        margin-bottom: 0px;
      }

      .profile-image {
        position: relative;
        width: 500px;
        height: 500px;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 80px;
        z-index: 3;
        align-self: center;
        border: 5px solid white;
      }

      .rewind-overview-display-name {
        font-size: 100px;
        line-height: 100px;
        z-index: 4;
        position: relative;
      }

      .rewind-overview-total-streams-title {
        font-size: 84px;
        line-height: 98px;
      }

      .rewind-overview-top-track-label {
        font-size: 48px;
        line-height: 72px;
        margin-bottom: @space-4;
      }
      .rewind-overview-top-track {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
      }
      .rewind-overview-top-track-cover-art {
        height: 60px;
        width: 60px;
        border-radius: 50%;
      }
      .rewind-overview-top-track-title {
        font-size: 54px;
        line-height: 64px;
      }

      .rewind-overview-title {
        font-size: 48px;
        line-height: 72px;
      }

      .rewind-overview-text {
        font-size: 54px;
        line-height: 64px;
      }

      .rewind-overview-row {
        display: flex;
        margin-top: 80px;

        .rewind-overview-container {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;

          .rewind-overview-container-line {
            display: flex;
            align-items: center;
            gap: 12px;

            > svg {
              width: 60px;
              height: 60px;
            }

            &.apple,
            &.napster {
              > svg > path {
                fill: white;
              }
            }
            &.amazon {
              > svg > path:nth-child(2) {
                fill: white;
              }
            }
          }
        }
      }
    }
  }

  .rewind-overview-gradient1 {
    background-image: linear-gradient(
      222.91deg,
      #a547fb 0%,
      #9747ff 49.88%,
      #6affbf 99.76%
    );
  }

  .rewind-overview-gradient2 {
    background-image: linear-gradient(
      180deg,
      #7cd9b3 0%,
      #7cd9b3 31.88%,
      #7f31d4 90.76%
    );
  }

  .rewind-overview-dark {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #161229;
    position: relative;

    .rewind-overview-dark-radial-1 {
      position: absolute;
      top: 0px;
      left: 182px;
      z-index: 3;
    }

    .rewind-overview-dark-radial-2 {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 3;
    }

    .rewind-overview-dark-radial-3 {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 3;
    }
  }
}

.rewind-overview-selector {
  gap: 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 228px;
  right: 0px;

  .rewind-overview-selector-option {
    width: 56px;
    height: 56px;
    border-radius: 100%;
    cursor: pointer;
  }

  .rewind-overview-selector-dark {
    background: radial-gradient(
      circle 20px at center,
      @primary-4 0%,
      #7900ff 60%,
      @secondary-7 100%
    );
  }

  .rewind-overview-selector-gradient1 {
    background: linear-gradient(
      222.91deg,
      #a547fb 0%,
      #9747ff 49.88%,
      #6affbf 99.76%
    );
  }

  .rewind-overview-selector-gradient2 {
    background: linear-gradient(196.91deg, #9ef7d1 22.46%, #7900ff 166.85%);
  }

  .selected {
    border: 3px solid white;
  }

  .disabled {
    cursor: not-allowed;
  }

  &.rewind-overview-selector-mobile {
    position: unset;
    top: unset;
    bottom: unset;
    flex-direction: column;

    &:hover {
      .rewind-overview-selector-option {
        display: block !important;
      }
    }

    .rewind-overview-selector-option {
      width: 40px;
      height: 40px;

      &:not(.selected) {
        display: none;
      }
    }
  }
}
