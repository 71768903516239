.release-layout {
  .custom-layout-content {
    display: flex;
    flex-direction: column;
  }

  .release-container {
    flex-grow: 1;

    .release-list-container {
      display: flex;
      flex-direction: column;

      ul.ant-pagination {
        display: flex;
        justify-content: center;
        margin-top: 4rem;
      }
    }
  }
}

div.release-search-filter-dropdown.ant-select-dropdown {
  min-height: 258px;

  .rc-virtual-list-holder {
    min-height: 258px;
  }
}
