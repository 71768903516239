.tracks-tab-search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-container {
    width: 275px;
  }
}

.section-child:has(.track-view-layout) {
  width: 100%;
}

.ant-layout.track-view-layout.ant-content-layout {
  height: 100%;

  @media (max-width: @screen-md-max) {
    .ant-layout-content.custom-layout-content {
      padding-top: 0px;
    }
  }
}

.track-layout-container {
  margin-bottom: @space-32;

  div.ant-col {
    position: initial;
  }
}
