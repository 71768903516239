.custom-select.ant-select,
.ant-select:not(.ant-select-customize-input) {
  width: 100%;

  * {
    line-height: 1.2;
  }

  .ant-select-selection-search-input {
    height: 100% !important;
  }

  div.ant-select-selector {
    transition: none !important;
    display: flex;
    align-items: center;
    border: 1px solid @neutral-5;

    span.ant-select-selection-placeholder,
    &::placeholder {
      color: @neutral-7;
    }
  }

  .ant-select-arrow:has(.anticon.anticon-down.ant-select-suffix) {
    transform: rotate(0);
    transition: transform 0.2s ease-in;
  }

  &.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
    .ant-select-clear {
      margin-right: @space-20;
    }
  }

  &.ant-select-multiple {
    .custom-select-item-inner .custom-select-image {
      width: @space-16;
      height: @space-16;
    }
  }

  .custom-select-item-cta {
    display: none;
  }

  .custom-select-item-counter {
    display: flex;
    justify-content: flex-end;
  }

  &.custom-select-selected {
    .ant-space-item:has(.custom-select-checkbox) {
      display: none;
    }
  }

  &.ant-select-lg.custom-select-selected .ant-select-selector {
    height: auto !important;
  }

  &.ant-select-lg.custom-select-selected.custom-select-auto-input-height
    .ant-select-selector {
    padding: @space-12;
  }

  &.ant-select-focused.ant-select-open {
    .ant-select-selector {
      border: 1px solid @neutral-5 !important;
      box-shadow: none !important;
    }

    .ant-select-arrow:has(.anticon.anticon-down.ant-select-suffix) {
      transform: rotate(180deg);
      transition: transform 0.2s ease-in;
    }
  }

  &.ant-select-multiple .ant-select-selection-item,
  &.ant-select-multiple .ant-select-selection-item-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-select-selection-item {
    display: flex;
  }

  &.ant-select-lg {
    .ant-select-arrow {
      top: 46%;
      right: 10px;
      width: 16px;
      height: 16px;
      font-size: 16px;
    }
  }

  &.ant-select-borderless,
  &.ant-select-borderless .ant-select-selector {
    border: 0 !important;
    outline: 0 !important;
  }
}

.custom-select-dropdown {
  &.ant-select-dropdown {
    padding: @space-4 0 0 0;
    border: 1px solid @primary-4;
    box-shadow: 0px 0px 4px 0px rgba(93, 179, 147, 0.5);

    z-index: 999;
  }

  .ant-select-item-group {
    color: @neutral-8;
    border-top: 1px solid @neutral-4;
    font-weight: bold;
  }

  .custom-select-cta {
    width: 100%;
    outline: 0;
    background-color: @neutral-2;
    border: 0;
    padding: @space-8 @space-16 @space-8 @space-8;
    margin: 2px;
    border-top: 1px solid @neutral-4;
    cursor: pointer;

    .custom-select-item-inner {
      width: 100%;

      > .ant-space-item:last-child {
        // Ensures the content width of the text does not affect layout of icon
        max-width: calc(100% - 40px - @space-8);
      }
    }
  }

  .ant-select-item-option-state {
    display: flex;
    align-items: center;
  }

  &.bio-page-release-selector-dropdown {
    .ant-select-item-group:first-child {
      border-top: none;
    }

    .ant-select-item-group {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
}

.custom-select-item {
  &.ant-select-item {
    padding: @space-8;
    border-radius: @space-4;
    border: @space-4 solid white;
    border-top: 0;
    align-items: center;
  }

  &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: @secondary-1;

    &:hover {
      background-color: @secondary-2;
    }

    &.custom-select-item-checkbox {
      background-color: transparent;
      &:hover {
        background-color: @neutral-3;
      }
    }
  }

  &.ant-select-item-option-active {
    background-color: @neutral-3;
  }

  &.custom-select-item-size-small {
    height: 24px;
    min-height: 24px !important;
  }

  &.custom-select-item-size-middle {
    height: 32px;
  }

  &.custom-select-item-size-large {
    height: 56px;
  }
}

.custom-select-item-inner {
  width: 100%;
  flex-direction: row !important;

  &.custom-select-item-inner-disabled {
    opacity: 0.4;
  }

  .ant-space-item:first-child {
    max-width: 100%;
    overflow: hidden;
  }

  .ant-space-item:last-of-type {
    flex-grow: 1;
    text-align: right;
  }

  .custom-select-label {
    display: block;
    padding-right: @space-4;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.2;
  }

  .ant-typography {
    text-align: left;
  }

  .custom-select-image {
    overflow: hidden;
    width: @space-24;
    height: @space-24;
    border-radius: @space-4;

    img,
    svg,
    span[role='img'] {
      width: 100%;
      height: 100%;
    }

    span.anticon-user {
      svg {
        fill: @neutral-7;
        width: 20px;
      }
    }

    &.custom-select-image-size-middle,
    &.custom-select-image-size-large {
      width: @space-24;
      height: @space-24;
    }

    &.custom-select-image-size-small {
      width: 16px;
      height: 16px;
    }

    &.custom-select-image-round {
      border-radius: 50%;
    }
  }

  .ant-space-item:nth-child(3) {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  .custom-select-item-extra {
    display: block;
  }

  .custom-select-item-cta {
    height: auto;
    padding-right: @space-8!important;
  }

  .custom-select-item-counter {
    display: flex;
    justify-content: flex-end;
  }
}

.custom-select-item-inner:has(.custom-select-image, .custom-checkbox) {
  .ant-space-item:nth-child(2) {
    // Ensures the content width of the text does not affect layout of image / checkbox and shows ellipsis
    width: calc(100% - 40px - @space-8);
  }
}
