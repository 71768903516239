div.coming-soon-widget-row {
  div.coming-soon-card {
    border-radius: @space-8;
    box-shadow: 0px -1px 4px 0px rgba(168, 162, 193, 0.2);
    overflow: hidden;

    @media only screen and (max-width: @screen-sm-min) {
      overflow: visible;
    }

    div.ant-card-body {
      padding: @space-24;

      @media only screen and (max-width: @screen-sm-min) {
        padding: @space-16;
      }

      div.artwork-container {
        width: 128px;
        max-width: 128px;

        @media only screen and (max-width: @screen-sm-min) {
          width: 100px;
          max-width: 100px;
        }

        img {
          border-radius: @space-8;
          width: 100%;
        }
      }

      div.meta-container {
        .ant-space-vertical .ant-space-item {
          width: 100%;
        }

        .release-meta-release-date {
          svg {
            margin-left: @space-8;
          }
        }

        div.meta-top {
          max-width: 100%;

          .ant-space-item {
            width: 100%;
          }

          span.pending-date-tag {
            border-radius: @space-8;
            height: @space-24;
            border: none;
            background: @neutral-3;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            span.anticon-clock-circle {
              color: @neutral-6;
            }
          }
        }

        div.meta-bottom {
          .ant-space-item {
            width: 100%;
          }

          div.smart-link-container {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            .coming-soon-smart-link-url {
              text-decoration: underline;
              max-width: 70%;
              text-decoration-color: @secondary-5;
            }

            button.smart-link-copy-btn {
              height: 100%;
              padding: 0 @space-8;

              svg {
                font-size: @space-16;
                max-width: @space-16;
                max-height: @space-16;
              }
            }
          }
        }
      }
    }
  }
}

div.empty-releases-widget__container {
  padding: @space-24;
  border: 1px solid @secondary-3;
  border-radius: @space-8;
  min-height: 176px;

  div.peep-col {
    margin-bottom: -@space-26;
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.unsubscribed-recent-features-block,
.academy-block {
  margin-top: @space-36;
  @media only screen and (min-width: @screen-md-max) {
    margin-top: @space-unit * 9;
  }
}

.unsubscribed-column-flip {
  @media only screen and (max-width: @screen-sm-max) {
    flex-direction: column-reverse;
  }
}

.recently-released-links {
  padding-top: @space-16;
  border-top: 1px solid @neutral-4;
  margin-top: @space-24;

  @media only screen and (min-width: @screen-md-max) {
    border-top: unset;
    padding-top: unset;
    margin-top: unset;
    border-left: 1px solid @neutral-4;
    height: 100%;
    padding-left: @space-24;
  }

  .recently-released-links-row {
    flex-direction: column;
    display: flex;

    a {
      margin-top: @space-4;
    }
  }
}

.smart-landing-loader {
  height: 176px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: @space-8;
  box-shadow: 0px -1px 4px 0px rgba(168, 162, 193, 0.2);
  margin-top: @space-32;
  background-color: white;
}

div.custom-card.content-block-card {
  box-shadow: 0px -1px 4px 0px #a8a2c133;
  min-height: 328px;
  display: inline-flex;
  align-items: center;

  @media only screen and (max-width: @screen-sm-max) {
    min-height: auto;
  }

  div.custom-card__content {
    width: 100%;
  }

  div.content-block-meta-space {
    align-items: center;

    > .ant-space-item {
      @media only screen and (max-width: @screen-sm-max) {
        width: 100%;
      }
    }

    .ant-space-item {
      max-width: 100%;

      &:last-of-type {
        display: flex;
        overflow: hidden;
      }
    }
  }

  div.content-block-img-preview__container,
  div.content-block-img-preview__container-mob {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    border-radius: @space-8;

    img {
      display: inline-flex;
      max-width: 190px;
      width: 100%;
      border-radius: @space-8;

      @media only screen and (max-width: @screen-sm-max) {
        max-width: 380px;
      }
    }

    &.academy-video-preview {
      a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 4px;

        svg {
          transform: scale3d(1, 1, 1);
          transform-style: preserve-3d;
          transition: transform 300ms;
        }

        &:hover {
          svg {
            transform: scale3d(1.3, 1.3, 1);
          }
        }
      }
    }
  }

  div.content-block-img-preview__container {
    width: 190px;
    min-height: 110px;
  }

  div.content-block-img-preview__container-mob {
    width: 100%;
    min-height: 166px;
  }
}

.typeform-embed {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px -1px 4px 0px #a8a2c133;
}

.landing-page-smart-layout {
  .ant-card.homepage-widget-card {
    box-shadow: 0px -1px 4px 0px rgba(168, 162, 193, 0.2);
  }

  .ant-card.homepage-streams-widget .ant-card-body .streams-widget-container {
    margin-bottom: -2.8rem;
  }
}

.landing-page-container {
  margin-top: @space-24;

  @media screen and (max-width: @screen-md-max) {
    margin-top: @space-8;
  }

  .no-data-container {
    padding-bottom: @space-16;
    width: 100%;
    height: 262px;
    svg {
      width: 100%;
      height: auto;
    }
  }
}

.landing-page-row {
  margin-bottom: @space-32;

  @media screen and (min-width: @screen-md-max) {
    margin-bottom: 40px;
  }

  .landing-page-row-header {
    margin-bottom: @space-16;
    @media screen and (min-width: @screen-md-max) {
      margin-bottom: @space-32;
    }
  }

  &.landing-page-row--points {
    margin-top: @space-60;
    @media screen and (max-width: @screen-md-max) {
      margin-top: @space-32;
    }
  }
}

.artist-story {
  position: relative;
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;

  svg {
    transform: scale3d(1, 1, 1);
    transform-style: preserve-3d;
    transition: transform 300ms;
    width: 80px;
    height: 80px;
  }

  &:hover {
    svg {
      transform: scale3d(1.3, 1.3, 1);
    }
  }

  .artist-name {
    position: absolute;
    left: 32px;
    bottom: 32px;
    max-width: 80%;
  }

  @media screen and (max-width: @screen-md-max) {
    margin-bottom: @space-16;
  }
}

.ant-card.homepage-widget-card {
  border-radius: @space-8;

  .ant-card-body {
    width: 100%;
    border-radius: @space-8;
    padding: @space-24;
    display: inline-flex;
    flex-direction: column;

    .academy-video-preview {
      height: 191px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 4px;

      @media only screen and (max-width: @screen-md-min) {
        height: 291px;
      }

      svg {
        transform: scale3d(1, 1, 1);
        transform-style: preserve-3d;
        transition: transform 300ms;
      }

      &:hover {
        svg {
          transform: scale3d(1.3, 1.3, 1);
        }
      }
    }

    button {
      z-index: 1;
    }
  }
}

.ant-card.homepage-streams-widget {
  border-radius: @space-8;

  .ant-card-body {
    .streams-widget-container {
      margin-bottom: -2.4rem;

      .stream-chart-widget {
        height: 262px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &:has(canvas) {
          height: 252px;
        }

        .empty-stats-widget {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 88%;

          .peep-container {
            display: inline-flex;
            margin-top: -30px;
            margin-right: -40px;
            width: 100%;
            justify-content: flex-end;
          }

          .ant-typography {
            white-space: pre-wrap;
          }
        }

        canvas {
          height: 184px;
        }
      }
    }
  }
}

.ant-card.homepage-balance-widget {
  div.ant-spin {
    padding: @size-14 0;
  }

  .balance-widget-container {
    display: inline-flex;
    flex-direction: column;
    width: 100%;

    div.ant-statistic {
      line-height: @space-24;
      span.ant-statistic-content-value {
        .ant-statistic-content-value-int,
        .ant-statistic-content-value-decimal {
          font-weight: 500;
          color: @secondary-7;
          font-size: @size-28;
        }
      }
      .ant-statistic-content-suffix {
        font-size: @size-28;
        margin-left: @space-12;
      }
    }
  }
}

.promo-partner-section {
  margin-top: @space-40;

  .promo-partner {
    display: flex;
    margin-top: @space-16;

    .promo-partner-img {
      align-items: center;
      background-color: @neutral-1;
      border-radius: 8px;
      display: flex;
      flex: 1;
      height: 120px;
      justify-content: center;
      margin-right: @space-24;
    }

    .promo-partner-info {
      flex: 4;
    }
  }

  .promo-partner-no-section-title {
    margin-top: 0px;
  }

  @media screen and (max-width: @screen-md) {
    margin-top: @space-24;
    text-align: center;

    .promo-partner {
      margin-top: @space-12;
      flex-direction: column;
      align-items: center;

      .promo-partner-img {
        min-height: 70px;
        width: 145px;
        margin-right: 0px;

        svg {
          width: 120px;
        }
      }

      .promo-partner-info {
        margin-top: @space-12;
      }
    }
  }
}

.beatport-banner-release-highlight {
  color: @primary-3;
  font-weight: bold;
}
