.field-label {
  margin-bottom: 0;

  @media screen and (min-width: @screen-md-min) {
    margin-bottom: @space-16;
  }
}

.field-value {
  margin-bottom: @space-16;
}
