.ant-tag {
  &.statement-credit-tag,
  &.statement-debit-tag,
  &.statement-correction-tag {
    font-size: @size-16;
    font-weight: @font-weight-regular;
    text-align: right;
  }

  &.statement-credit-tag,
  &.statement-correction-tag {
    margin-right: @space-48;
  }

  &.statement-credit-tag {
    color: @success-6;
    margin-right: 0;
  }

  &.statement-correction-tag,
  &.statement-debit-tag {
    color: @neutral-8;
  }
}

.vat-amount {
  display: block;
  font-size: @size-12;
  color: @neutral-7;
  margin-top: 2px;
}
