div.audience-chart-container {
  background-color: @white;
  border-radius: @space-8;
  padding: @space-20 @space-16;

  @media screen and (min-width: @screen-sm-min) {
    padding: @space-32;
  }

  .no-data-container {
    #empty-audience-chart {
      max-width: 1022px;
    }
  }

  .audience-bar-chart {
    display: flex;
    align-items: flex-end;
  }
}
