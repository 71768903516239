.error-message-code {
  font-weight: 500;
}

.ant-row {
  &.release-error {
    margin-bottom: @space-80;

    > .ant-col {
      flex: 1 0 100%;

      @media (min-width: @screen-md-min) {
        flex: 1 0 50%;
      }
    }
  }
}

.ant-card {
  &.release-error-card {
    max-height: @space-80 * 4;
    overflow-y: scroll;

    .release-error-card-title {
      display: flex;

      > svg {
        margin-left: @space-16;
      }
    }
  }
}

.ant-list-split {
  .ant-list-item {
    &.release-error-dsp-item {
      display: flex;
      align-items: flex-start;
      border-bottom: 0;

      > span {
        width: 75%;
      }
    }
  }
}
