.release-preview-content {
  background-color: white;
  padding: 32px;
  width: 100%;
}

.release-preview-artwork {
  height: 164px;
  width: 164px;
}

.release-preview-duration-col {
  text-align: end;
}

.release-preview-confirmation {
  padding-left: 30px;
}

.release-preview-terms-card h4 {
  text-align: left;
}

.release-preview-terms-card {
  .notify-card-content {
    margin-top: @space-16;
  }
  .ant-card-body {
    padding: @space-24;
  }

  .release-confirmation {
    padding-left: 28px;
  }
}

.release-confirmation {
  width: 100%;
}

.release-confirmation,
.release-confirmation .ant-checkbox-wrapper,
.release-confirmation a {
  text-align: left;
  font-size: @size-12;
}
