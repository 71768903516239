.top-resource-card-desktop,
.top-resource-card-mob {
  .ant-card-head {
    border-bottom: none;

    .ant-card-head-title {
      padding: 0;
    }
  }

  .ant-card-body {
    padding: 0 @space-32 @space-10;

    .ant-table-empty {
      .ant-table-tbody {
        .ant-table-placeholder {
          tr.ant-table-placeholder,
          td {
            border: none;
          }
        }
      }
    }

    .top-tracks-title {
      display: inline-flex;

      .ant-typography {
        margin-bottom: 0;
      }
    }

    .release-title-version {
      display: inline-flex;
      flex-direction: column;
    }
  }
}

.top-resource-card-desktop {
  overflow-y: hidden;
  overflow-x: auto;

  .ant-card-head {
    padding: @space-32 @space-32 @space-18;
  }

  .ant-card-body {
    padding: 0 @space-24 @space-12;
  }
}

.top-resource-card-mob {
  .ant-card-head {
    padding: @space-24 @space-16;

    .ant-card-head-title {
      display: flex;
      width: 100%;

      .ant-typography.text-headline-s {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .ant-card-body {
    padding: 0 @space-16 @space-24;

    .ant-list-item {
      .title-container {
        display: flex;
        align-items: center;
        max-width: 66%;
      }
    }
  }
}

.top-resources-table {
  .ant-table-thead {
    @media (max-width: @screen-md-min) {
      display: none;
    }

    .ant-table-cell {
      background: none;
      border-bottom: 1px solid @neutral-4;
      padding: 0;
      padding-bottom: 0.8rem;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;

      @media screen and (min-width: @screen-sm-min) {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
      }
    }

    .top-resources-row:last-of-type {
      .ant-table-cell {
        border-bottom: none;
      }
    }
  }

  .top-resource-placeholder {
    width: 42px;

    @media screen and (min-width: @screen-sm-min) {
      width: @space-48;
    }
  }

  .ant-statistic.percentage-change-stats {
    font-size: 12px;
  }
}

.top-devices-title .ant-typography,
.top-sources-title .ant-typography {
  margin-bottom: 0;
}

.top-resource-card-desktop {
  height: 100%;
}
