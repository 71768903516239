.percentage-change-container {
  display: inline-flex;
  align-items: center;

  .ant-statistic-content-suffix {
    margin-left: 0;
  }

  .ant-statistic.percentage-change-stats {
    .ant-statistic-content {
      font-weight: 500;
      border-radius: 6px;
      padding: 2px 4px;
    }
  }

  .ant-statistic,
  .ant-statistic-content,
  span.ant-statistic-content-value,
  span.ant-statistic-content-value-decimal,
  span.ant-statistic-content-suffix {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
}
