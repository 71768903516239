.ellipsis-dropdown-menu {
  border-radius: 8px;
  min-width: 250px;

  &.ellipsis-dropdown-menu--has-shadow {
    border: 1px solid @primary-4;
    box-shadow: 0px 0px 4px rgba(93, 179, 147, 0.5);
  }

  .ellipsis-dropdown-menu-delete-option {
    color: @danger-4;
  }
}
