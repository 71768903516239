.bio-page-landing__container,
.bio-page-layout__container {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: @screen-sm-min) {
    flex-direction: column;
  }
}

.bio-page-landing__card,
.bio-page-layout__card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  margin-right: @space-40;
  height: fit-content;

  @media screen and (max-width: @screen-md-max) {
    margin-right: 0;
  }
}

.bio-page-list__container {
  display: flex;
  gap: @space-24;
  flex-direction: column;
}

.bio-page-list__card {
  display: flex;
  border-radius: 8px;
  overflow: hidden;

  .ant-btn .ant-typography {
    white-space: nowrap;
  }

  &.bio-page-list__card--placeholder {
    .ant-card-body {
      background-color: @neutral-3;
    }
    border: 1px solid @neutral-5;
  }

  .ant-card-body {
    padding: 24px;
    border: 0;
  }

  .bio-dropdown-ellipsis {
    @media screen and (max-width: @screen-md-max) {
      position: absolute;
      top: @space-12;
      right: @space-12;
    }
  }

  .bio-page-list__card-row {
    width: 100%;
    display: flex;
    gap: @space-8;
    align-items: center;
  }

  .bio-page-list__card-image {
    @media screen and (max-width: @screen-sm-min) {
      display: none;
    }
  }

  .bio-page-list__card-artist {
    max-width: 95%;
    .ant-typography.text-block {
      line-height: 1.2;
    }
  }

  .bio-page-list__card-meta {
    display: flex;
    flex-direction: column;
    gap: @space-8;
    height: 80px;
    flex-grow: 1;

    @media screen and (max-width: @screen-md-max) {
      gap: @space-12;
      align-items: flex-start;
      height: auto;
    }
  }

  .bio-page-list__card-info {
    display: flex;
    flex-direction: row;
    gap: @space-24;
    flex-grow: 1;

    @media screen and (max-width: @screen-md-max) {
      flex-wrap: unset;
      gap: @space-12;
      flex-direction: column;
    }
  }

  .bio-page-list__card-bio {
    display: flex;
    align-items: center;
    padding-right: @space-16;
    max-width: 40%;

    @media screen and (max-width: @screen-md-max) {
      max-width: none;
    }
  }

  .bio-page-list__card-actions {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    gap: @space-16;
    justify-content: flex-end;

    @media screen and (max-width: @screen-md-max) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .bio-page-list__card-url {
    max-width: 280px;
    line-height: 1.2;

    @media screen and (max-width: @screen-md-max) {
      max-width: none;
    }
  }

  .bio-page-list__card-tag {
    padding: @space-8;
  }

  .bio-page-list__card-buttons {
    display: flex;
    flex-direction: row;
    gap: 0;
  }
}

.bio-page-remove-release-link,
.bio-page-remove-link {
  width: 100%;
  justify-content: flex-end !important;
  text-align: right;
}

.bio-dropdown.ellipsis-dropdown-menu {
  min-width: 168px;
}

.bio-page-form__preview,
.bio-page-landing__preview,
.bio-page-layout__preview {
  min-width: 200px;
  max-width: 200px;
  position: sticky;
  top: @space-20;

  @media screen and (min-width: @screen-lg-min) {
    min-width: 300px;
    max-width: 300px;
  }

  @media screen and (max-width: @screen-sm-min) {
    min-width: 100%;
    max-width: 100%;
    margin-top: @space-20;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.bio-page-landing--view__link-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex-direction: flex-end;
}

.bio-page-create-modal {
  padding-bottom: @space-48;

  .bio-page-create-form {
    .custom-modal__content:has(.ant-form-item-has-error) {
      padding-bottom: @space-12;
    }

    .bio-page-url-item {
      align-items: flex-start;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      margin-top: @space-16;

      .bio-page-url-label {
        margin-top: @space-8;
      }

      .bio-page-url-input-wrapper {
        flex: 1;

        .bio-page-url-input {
          flex: 1;
        }

        .ant-row.ant-form-item {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.bio-page-form__preview {
  border-radius: @space-32;
  border: 8px solid @secondary-3;
  background: @secondary-5;
  overflow: hidden;
  height: 612px;

  iframe {
    outline: 0;
    height: 100%;
    width: 100%;
    border: 0;
    border-radius: @space-16;
  }
}

.bio-page-cover-art-preview {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: @neutral-5;
  margin-right: @space-16;
  color: @white;

  &.size-m {
    width: 80px;
    height: 80px;
    .bio-page-cover-art-preview__image {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
    }
  }
}

.bio-page-cover-art-preview__image {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.bio-page-share-url-preview {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ded9ef, #ded9ef);
  border: 1px solid #ded9ef;
  padding: 12px 32px 12px 32px;
  border-radius: 8px;
}

.bio-page-share-copy-button {
  svg,
  path {
    fill: @neutral-9;
  }
}

.bio-page-home-copy-button {
  padding: 0px !important;
  height: auto !important;
  width: auto !important;
  svg,
  path {
    fill: @secondary-5;
    height: 20px;
    width: 20px;
    max-width: 20px !important;
    max-height: 20px !important;
  }
}

.bio-page-mobile-preview-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background-color: @secondary-1;
  padding: @space-16;
}

.bio-page-mobile-modal {
  .custom-modal__wrapper {
    padding: @space-36;
    @media screen and (max-width: @screen-sm-max) {
      padding: @space-16;
    }
  }
  .custom-modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bio-page-form__preview {
    min-width: 400px;
    max-width: 400px;

    @media screen and (max-width: @screen-sm-min) {
      min-width: 300px;
      max-width: 300px;
    }
    margin-bottom: 100px;
  }
}

.bio-analytics-no-data-card {
  position: relative;
  overflow: hidden;
  padding: @space-24 @space-24 @space-64 @space-24;
  background-color: transparent;
  border: 1px solid @secondary-3;
  border-radius: @space-8;
}

.bio-analytics-no-data-card__peep {
  position: absolute;
  bottom: -72px;
}

.bio-analytics-donut-legend {
  .ant-space-item:last-child {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
}

.bio-analytics-donut-legend-bar {
  display: block;
  width: @space-8;
  height: @space-32;
  border-radius: @space-4;
}

.bio-analytics-donut-legend-platform {
  min-width: 60%;
}

.bio-analytics-table {
  .ant-table-thead > tr > th {
    padding-top: 0;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    background-color: unset;
    border-bottom-color: @neutral-4;
    padding-left: @space-8;
    padding-right: @space-8;
  }
}

.bio-analytics-card-disable {
  .bio-analytics-card-inner,
  .custom-card__header {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.bio-analytics-card-disable-top {
  .custom-card__content,
  .custom-card__header {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.bio-analytics-no-data-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 320px;
  max-height: max-content;
  background-color: #ffffff;
  box-shadow: 0px 4px 13px 0px #00000014;
  z-index: 99;
}

.bio-page-common-link-dropdown {
  border-radius: 8px !important;
  padding: 0 !important;
  width: 168px;

  &.bio-page-common-link-dropdown--has-shadow {
    border: 1px solid @primary-4;
    box-shadow: 0px 0px 4px rgba(93, 179, 147, 0.5);
  }

  .bio-page-common-link-dropdown-delete-option {
    color: @danger-4;
  }

  .bio-page-common-link-option-release,
  .bio-page-common-link-option-link {
    height: @space-40;
    display: flex;
    align-items: center;
    min-height: fit-content;

    svg {
      margin-right: @space-8;
    }

    .bio-page-common-link-option-container {
      display: flex;
      align-items: center;

      .ant-typography {
        text-wrap: wrap;
      }

      svg {
        height: @space-16;
        width: @space-16;
      }
    }
  }

  .bio-page-common-link-option-release {
    border-bottom: 1px solid @neutral-4;
  }
}

.links__draggable {
  position: relative;
  width: 100%;

  .resource-form-item-row .ant-row.ant-form-item {
    padding-left: @space-12;
    margin-bottom: 0;
  }
}

.links__draggable:not(:first-child) {
  .resource-form-item-row {
    margin-top: 20px;
  }
}

.links__drag-icon {
  position: absolute;
  left: -26px;
}

.links__drag-icon.hidden {
  display: none;
}

.links__dragging {
  .bio-page-add-link-button {
    margin-top: 102px;
  }
}

.bio-page-link-row {
  display: flex;
  @media screen and (max-width: @screen-sm-max) {
    flex-direction: column;
  }
}

.bio-page-link-col {
  flex: 1;

  .bio-page-link-label-item {
    margin-right: @space-24;
  }

  @media screen and (max-width: @screen-sm-max) {
    .bio-page-link-label-item {
      margin-right: 0px;
    }
  }
}

.bio-page-bg-type-container {
  .bio-page-bg-type-selector {
    height: 45px;
  }
  .ant-radio-group.ant-radio-group-outline {
    flex-wrap: wrap;
    flex-direction: column;

    .bio-page-bg-option-radio {
      flex: 1;

      .radio-label-container {
        text-wrap: nowrap;
        margin-bottom: @space-12;
      }
    }
  }

  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }

  .ant-row.resource-form-item-row {
    height: 100%;
  }
}

.multi-bio-page-offer-card {
  padding: 4px;
  border-radius: @space-8;
  z-index: 1;
  background: linear-gradient(
    222.91deg,
    #a547fb 0%,
    #9747ff 49.88%,
    #6affbf 99.76%
  );
  width: 100%;

  .multi-bio-page-offer-content {
    background-color: white;
    border-radius: 6px;
    padding: @space-20;

    .multi-bio-page-items {
      display: block;
      column-count: 2;
    }
  }
}
