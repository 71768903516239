.peep {
  display: flex;
  justify-content: flex-end;

  .line {
    width: @space-36;
    height: 0;
    border-bottom: 1px solid @secondary-4;
  }

  p,
  span.ant-typography {
    margin-top: -@space-24;
    color: @secondary-5;
  }

  &.left-alignment {
    .line {
      transform: matrix(-1, -0.5, 0, 1, 0, 0);
      margin-right: -@space-24;
    }

    p {
      margin-right: @space-16;
      width: 40%;
      text-align: right;
    }
  }

  &.right-alignment {
    .line {
      transform: matrix(1, -0.5, 0, 1, 0, 0);
      margin-left: -@space-48;
    }

    p {
      margin-left: @space-16;
    }
  }
}
