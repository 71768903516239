@primary5: @primary-5;

div.ant-picker {
  border: 1px solid @neutral-5;

  &.ant-picker-focused {
    border: 1px solid @primary-4;
    box-shadow: 0 0 4px 0 ~'@{primary5}80';

    &:hover {
      border: 1px solid @primary-4;
      box-shadow: 0 0 4px 0 ~'@{primary5}80';
    }
  }

  &:hover {
    border: 1px solid @neutral-7;
  }

  &.ant-picker-disabled {
    background-color: @neutral-3;
    border: 1px solid @neutral-5;

    &:hover {
      border: 1px solid @neutral-5;
    }
  }

  div.ant-picker-input {
    input::placeholder {
      color: @neutral-7;
    }
  }
}

div.ant-picker-range {
  div.ant-picker-active-bar {
    background: @primary-4;
    width: 46%;
    margin-left: 0px;
  }
}

div.ant-picker-dropdown {
  div.ant-picker-panel-container {
    border-radius: 8px;
    border: 1px solid @primary-4;
    box-shadow: 0px 0px 4px 0px ~'@{primary5}80';

    div.ant-picker-header {
      div.ant-picker-header-view {
        font-size: @size-16;
        color: @neutral-9;
      }
    }

    div.ant-picker-body {
      table.ant-picker-content {
        thead {
          tr {
            th {
              font-size: @size-14;
              color: @neutral-7;
              font-weight: 500;
            }
          }
        }

        td.ant-picker-cell.ant-picker-cell-in-view {
          padding: 0;

          .ant-picker-cell-inner {
            color: @neutral-9;
            min-width: @space-32;
            height: @space-32;
            line-height: @space-32;
          }
        }

        td.ant-picker-cell.ant-picker-cell-in-view
          :not(.ant-picker-cell-in-range)
          :not(.ant-picker-cell-range-start)
          :not(.ant-picker-cell-range-end)
          :not(.ant-picker-cell-ranger-hover)
          :not(.ant-picker-cell-ranger-hover-start)
          :not(.ant-picker-cell-ranger-hover-end) {
          &:hover {
            .ant-picker-cell-inner {
              background: @neutral-3;
              border-radius: 100px;
            }
          }
        }

        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
          .ant-picker-cell-inner {
            font-weight: 700;
            color: @neutral-9;

            &:before {
              border: none;
            }
          }
        }

        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover {
          background: @primary-1;
          height: @space-32;
        }

        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range,
        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-start,
        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
          &:before {
            height: @space-32;
            background: @primary-1;
          }
        }

        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-start,
        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover,
        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
          &:after {
            border-top: none;
            border-bottom: none;
          }

          .ant-picker-cell-inner {
            &:after {
              background: @primary-1;
            }
          }
        }

        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected,
        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start,
        td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end {
          &:before {
            height: @space-32;
          }

          .ant-picker-cell-inner {
            color: @white;
            background: @primary-5;
            border-radius: 100px;

            &hover {
              background: @primary-5;
            }
          }
        }

        td.ant-picker-cell.ant-picker-cell-disabled,
        td.ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-selected,
        td.ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-today {
          cursor: not-allowed;
          pointer-events: inherit;

          &:before {
            background: none;
          }

          &:hover {
            div.ant-picker-cell-inner {
              background: none;
            }
          }

          div.ant-picker-cell-inner {
            color: @neutral-5;
          }
        }
      }
    }
  }
}
