.overview-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > svg {
    fill: @neutral-5;
    cursor: pointer;
  }

  .anticon {
    > svg {
      color: @neutral-5;
      width: @space-24;
      height: @space-24;
    }
  }
}

.overview-card-body {
  display: flex;
  flex-direction: column;
  height: calc(100% - @space-24);
  justify-content: flex-end;
}
