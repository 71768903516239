.lyrics-modal {
  top: @space-32;
}

.lyrics-is-cover-field .resource-form-item-row {
  margin-top: @space-16;
  .ant-col:not(.lyrics-is-cover-field__label) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 2px;

    .ant-radio-wrapper:last-child {
      margin-right: 0;
    }
  }
}
