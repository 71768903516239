.error-layout {
  .ant-layout-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.error-container {
  margin: @space-64 0;

  .error-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: @space-40 0 @space-80 0;
  }

  .error-img {
    padding-bottom: @space-80;
  }
}

@media (min-width: @screen-lg-min) {
  .error-container {
    .error-content {
      padding: 0 @space-64 0 0;
    }

    .error-img {
      padding-bottom: 0;
    }
  }
}
