.breakdown-by-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: @space-16;

  .breakdown-by-type {
    min-width: 500px;
    display: flex;
    gap: @space-16;
    align-items: center;
  }

  .breakdown-by-date {
    min-width: 290px;
  }
}

@media (max-width: @screen-sm-max) {
  .breakdown-by-options {
    .breakdown-by-type {
      flex-direction: column;
      align-items: flex-start;
      min-width: unset;
    }

    .breakdown-by-date {
      min-width: unset;
    }
  }
}
