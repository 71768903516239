.beatport-modal-distribution.custom-modal {
  .custom-modal__footer-buttons {
    justify-content: center;
  }
}

.beatport-modal-description {
  background-color: white;
  padding: @space-24;

  .beatport-modal-alert-columns {
    column-count: 2;
    column-gap: 150px;
  }
}

.beatport-cancel-points-card {
  border: 1px solid @primary5;
}
