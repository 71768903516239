.empty-section {
  display: flex;
  flex-direction: column;
  margin: 0 @space-8 20rem;

  @media screen and (min-width: @screen-sm-min) {
    margin: 0 @space-16 20rem;
  }

  @media screen and (min-width: @screen-md-min) {
    margin: 0 @space-24 20rem;
  }

  @media screen and (min-width: @screen-lg-min) {
    margin: 0 12rem 20rem;
  }

  div.ant-row.meta-row {
    width: 80%;
    margin: 3.2rem auto 8rem;
    text-align: center;
    justify-content: center;

    @media screen and (min-width: @screen-md-min) {
      width: 48%;
      min-width: 529px;
    }

    div.ant-col:first-of-type * {
      margin-bottom: @space-16;
    }

    div.ant-col:last-of-type {
      margin-top: @space-16;
      margin-bottom: 0rem;
    }
  }
}

div.ant-row.peep-row {
  justify-content: flex-end;
  position: absolute;
  bottom: 0%;
  right: 10%;
}
