.gema-works-search-modal {
  .ant-modal-content {
    .ant-modal-body {
      .gema-search-card {
        .ant-card-body {
          padding: @space-24;
        }
        .works-search-author-input {
          width: 264px;
        }
      }

      .works-searching {
        align-items: center;
        background-color: @neutral-1;
        display: flex;
        flex-direction: column;
        height: 216px;
        justify-content: center;

        .works-searching-icon {
          color: @secondary-4;
          font-size: @size-54;
        }
      }

      .search-result-title {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: @space-8;

        .ant-typography {
          margin: 0;
        }
      }

      .works-result-table-container {
        border-radius: 4px;
        display: flex;

        div.ant-col {
          display: inherit;
        }

        .ant-table {
          border: 1px solid @neutral-4;
          border-bottom: 0;
        }

        .ant-table-thead {
          th.ant-table-cell {
            background: @neutral-3;
            padding: @space-12 @space-32;

            &.work-number-cell {
              padding-right: @space-24;
              text-align: right;
            }

            &.work-title-cell {
              padding-left: @space-24;
              text-align: left;
            }
          }
        }

        tbody.ant-table-tbody {
          td.ant-table-cell {
            &.work-number-cell {
              padding-right: @space-24;
              text-align: right;
            }

            &.work-title-cell {
              padding-left: @space-24;
              text-align: left;
            }

            &.work-authors-cell {
              white-space: pre-line;
            }
          }
        }
      }

      .no-work-results-card {
        .ant-card-body {
          padding-bottom: 0;

          h1.ant-typography {
            margin-bottom: 0;
          }

          .ant-space-item:last-of-type {
            display: inline-flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
