.release-section-artwork {
  .ant-space {
    .ant-space-item:last-of-type {
      width: 100%;
    }
  }

  @media screen and (max-width: @screen-xs-min) {
    flex-direction: column;
    align-items: center;
  }

  .release-section-artwork-img {
    margin: 0;

    @media screen and (max-width: @screen-xs-min) {
      margin-bottom: @space-20;
    }

    > svg {
      width: 100px;
      height: 100px;
    }

    > img {
      width: 100px;
      height: 100px;
      border-radius: @border-radius-base;
    }
  }

  .release-section-artwork-name {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: @space-32;

    @media screen and (max-width: @screen-xs-min) {
      margin: 0;
      flex-direction: row;

      .ant-row {
        .ant-col {
          width: max-content;
        }
      }
    }
  }
}

.dsps-list-wrapper {
  .ant-space-item {
    display: flex;
  }
}

.release-section-tip-layout {
  .tip-layout-children {
    display: flex;
    align-items: flex-start;
  }
}

.dsps-released-in {
  .ant-typography {
    margin: 0;
  }

  .ant-col:last-of-type {
    display: flex;
    margin-top: @space-16;
  }
}

.release-tracklist-section-header {
  margin-top: @space-80;
}

.ant-typography a.dsp-typeform-link {
  text-decoration: underline;
  font-weight: inherit;
}

.dsp-no-extra {
  position: relative;
  .peep {
    position: absolute;
    bottom: 0;
    right: @space-32;
    bottom: -@space-48;

    @media screen and (max-width: @screen-sm-min) {
      display: none;
    }
  }
}

.smart-link-url-label {
  display: inline-flex;

  .ant-space-item:last-of-type {
    display: inline-flex;
  }
}

.ant-typography {
  a.smart-link-url {
    color: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }
}

button.ant-btn.custom-btn.copy-link {
  display: none;
}
button.ant-btn.custom-btn.copy-link-hover {
  display: inline-flex;
  padding: 0;
  height: auto;
  margin-left: @space-16;
}

.release-recording-right-name-preview-label,
.release-copyright-name-preview-label {
  display: flex;

  svg {
    align-self: center;
    margin-left: @space-8;
    width: @size-14;
    height: @size-14;
  }
}
