.ant-row {
  &[data-theme='dark'] {
    background-color: @secondary-6;
    color: @white;
  }

  &.container {
    padding-left: @space-16;
    padding-right: @space-16;

    @media (min-width: @screen-md-min) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.withPadding {
    padding-bottom: @space-40;

    @media (min-width: @screen-md-min) {
      padding-bottom: @space-80;
    }
  }
}
