header.ant-layout-header {
  padding: 0;
  margin: @space-24 0;
  background-color: unset;

  div.ant-col {
    display: inline-flex;
    justify-content: space-between;

    .mobile-nav-menu-layout {
      cursor: pointer;

      svg.menu-closed__mob {
        color: @secondary-5;
      }
    }
  }
}

.logo {
  width: @space-80;
}

div.ant-menu-submenu {
  min-width: 244px;
  border: 1px solid @primary-4;
  border-radius: @space-8;

  .anticon.external-icon {
    margin-right: 6px;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }

  div.menu-dropdown-divider {
    margin: @space-4;
    min-width: 0;
    width: auto;
    border-top: 1px solid @neutral-4;
  }
}

li.ant-menu-submenu {
  .sub-menu-custom-icon {
    margin-right: 10px;
  }
}

.account-sub-menu .ant-menu-title-content {
  min-width: 38px !important;
}

.nav-menu-item-musicHubAcademyLink,
.nav-menu-item-blogLink,
.nav-menu-item-discordCommunityLink {
  .ant-menu-title-content {
    display: flex;
  }
}

.new-feature-icon {
  background: @accent-4;
  border-radius: @space-24;
  padding: 0 @space-8;
  color: @neutral-1;
  font-size: 10px;
  height: 16px;
  display: flex;
  align-items: center;
}
