.ant-table-title {
  background-color: @secondary-4;
  color: @white;

  .table-title-container {
    display: flex;
    align-items: center;

    > div:first-child {
      .typography.h4();
      margin-right: @space-24;
      padding: @space-4 0;
    }

    > div:last-child {
      text-transform: uppercase;
      color: @secondary-2;
    }
  }
}

.ant-table-thead {
  th.ant-table-cell {
    .typography.label();
  }
}
