.upload-certificate-example {
  width: 100%;

  @media only screen and (max-width: @screen-md) {
    margin-top: @space-12;
  }
}

.upload-certificate-footer {
  align-items: flex-start;
  display: flex;

  svg {
    min-width: 41px;
    margin-right: @space-8;
  }
}

.upload-certificate-tooltip {
  display: flex;
  align-items: center;

  svg {
    margin-left: @space-4;
  }
}

.upload-certificate-tab-footer {
  display: flex;
  align-items: center;

  @media screen and (max-width: @screen-md-min) {
    flex-direction: column;
    .upload-certificate-download-button {
      width: 100%;
      margin-top: 16px;
    }
  }
}

.upload-certificate-card {
  .upload-certificate-example {
    border: 1px solid @neutral-4;
  }
}

.upload-certificate-article {
  display: 'flex';
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin-top: 24px;
  border-radius: 4px;
  background-color: @secondary-2;

  .upload-certificate-article-link {
    height: 18px !important;
    margin-top: 10px;
  }

  @media screen and (max-width: @screen-md-min) {
    padding: 16px;
    margin-top: 12px;
  }
}
