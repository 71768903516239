::-moz-selection {
  background: @primary-color !important;
  color: inherit !important;
}

::selection {
  color: inherit !important;
  background: @primary-color !important;
}

.ant-layout-content {
  padding-top: @space-40;
  position: relative;

  @media screen and (max-width: @screen-md) {
    padding-top: 0px;
  }
}

a {
  .typography.link();

  &.light {
    font-weight: @font-weight-regular;
  }
}

i.ant-spin-dot-item {
  background-color: @secondary-5;
}

li.ant-pagination-prev .ant-pagination-item-link,
li.ant-pagination-next .ant-pagination-item-link {
  border-color: transparent;
  background-color: unset;
  &:hover {
    border-color: @secondary-4;
    color: inherit;
  }
}

.ant-pagination-options-size-changer {
  .ant-select-selector {
    border-color: @secondary-4!important;
  }
  .ant-select-arrow {
    color: @secondary-7 !important;
  }
}

li.ant-pagination-item {
  border-color: transparent;
  background-color: unset;

  a {
    color: @secondary-7;
    font-weight: 100;
  }
  &:hover {
    border-color: @secondary-4;
    a {
      color: inherit;
    }
  }
}

li.ant-pagination-item-active {
  border-color: @secondary-4;
  background-color: @white;

  a {
    color: @secondary-6;
  }
}

.ant-statistic {
  .ant-statistic-content-value {
    font-weight: 600;
    color: @secondary-10;
  }

  .ant-statistic-content-suffix {
    color: @secondary-11;
  }
}

.ant-form {
  .ant-card {
    .ant-card-body {
      .ant-form-item {
        margin-bottom: @size-32;
      }
    }
  }

  .ant-card.ant-card-bordered {
    border: none;
    border-bottom: 1px solid @secondary-2;
  }
}

.ant-form-item-label:first-child label.ant-form-item-no-colon {
  color: @neutral-9;
  font-size: 1.2rem;
  font-weight: 500;
}

.ant-form-item {
  .ant-col.ant-form-item-label {
    padding-bottom: 2px;
    line-height: @space-20;

    label {
      display: flex;
      color: @neutral-8;
      font-size: @size-12;
      font-style: normal;
      line-height: @space-20;
      font-weight: 500;
      height: fit-content;

      // Adds padding to the * to place info icon correctly
      &::before {
        padding-right: 12px;
      }

      img {
        margin-left: @space-8;
      }

      .ant-form-item-tooltip {
        position: absolute;
        right: -18px;
        top: 2px;
      }
    }
  }
}

.ant-form-item-label .ant-form-item-required::before {
  display: none !important;
}

.ant-form-item-extra,
.ant-form-item-has-warning,
.ant-form-item-has-error {
  span.ant-input-affix-wrapper.custom-input,
  input.ant-input.custom-input {
    border: 1px solid @warning-4;

    &:hover {
      border: 1px solid @warning-4;
    }

    &:disabled {
      &:hover {
        background-color: @neutral-3;
      }
    }

    &:focus,
    &.ant-input-affix-wrapper-focused {
      border: 1px solid @warning-4;
      box-shadow: 0 0 4px 0 rgba(250, 140, 22, 0.5);
    }
  }

  div.ant-select:not(.ant-select-focused) {
    span.ant-select-arrow {
      color: @warning-4;
    }
  }

  .ant-form-item-explain {
    font-size: @size-12;
    line-height: @space-18;
    display: inline-flex;
    flex-direction: column;

    div[role='alert'] {
      display: inline-flex;
      align-items: flex-start;
      padding-top: 4px;
    }
  }

  .ant-form-item-explain-warning {
    flex-direction: column;

    .form-error-alert,
    .form-error-warning {
      display: inline-flex;

      span[role='img'] {
        padding-top: 2px;
      }
    }

    .form-error-alert:not(:first-of-type),
    .form-error-warning:not(:first-of-type) {
      padding-top: 4px;
    }
  }

  .ant-form-item-info,
  .ant-form-item-info-warning {
    color: @info-color;
  }

  .ant-form-item-info-warning {
    div[role='alert']:first-of-type {
      padding-top: 0;
    }
  }

  .ant-checkbox-inner {
    border-color: @warning-color;
  }

  div.ant-form-item-control-input-content {
    .ant-picker {
      &.ant-picker-focused {
        border: 1px solid @warning-4;
        box-shadow: 0 0 4px 0 rgba(250, 140, 22, 0.5);

        &:hover {
          border: 1px solid @warning-4;
          box-shadow: 0 0 4px 0 rgba(250, 140, 22, 0.5);
        }
      }
    }
  }
}

div.ant-form-item-control-input-content {
  .ant-picker {
    span.ant-picker-suffix {
      color: @neutral-7;
      font-size: @size-16;
    }
  }
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper.ant-checkbox-wrapper {
  margin-left: unset;
  margin-right: @space-2;
}

.ant-card {
  .ant-card-head {
    border-bottom: 1px solid @secondary-2;
    color: @secondary-7;
    padding: 0 @space-32;

    @media screen and (max-width: @screen-xs-min) {
      padding: 0 @space-22;
    }

    .ant-card-head-wrapper {
      .ant-card-head-title {
        flex: initial;
        font-weight: 500;
        color: @secondary-5;
      }
    }
  }

  .ant-card-body {
    padding: @space-32;

    @media screen and (max-width: @screen-xs-min) {
      padding: @space-22;
    }
  }
}

.ant-popover {
  max-width: 370px;

  .ant-popover-inner-content {
    color: @neutral-7;
  }

  .ant-popover-title {
    color: @neutral-8;
  }

  &.arranging-tracks-tooltip {
    .ant-popover-inner {
      margin-left: -@space-16;
      border-radius: 0;
      padding: @space-16;
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-title {
      padding: 0;
      border-bottom: 0;
    }
  }

  &.available-balance-popover {
    .ant-popover-inner-content {
      width: @space-80 * 3;
      font-size: @size-12;
    }
  }
}

.ant-tabs.ant-tabs-top {
  > .ant-tabs-nav::before {
    border-bottom: 0px;
  }

  .ant-tabs-nav {
    @media screen and (min-width: @screen-sm-min) {
      margin-bottom: @space-40;
    }
  }

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      padding: 0 0 @space-16 0;

      .ant-tabs-tab-btn {
        font-size: @size-16;
        color: @secondary-5;
      }

      .ant-tabs-tab-btn:hover {
        color: @secondary-5;
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        font-weight: 500;
        color: @secondary-6;
      }
    }
    .ant-tabs-ink-bar {
      background: @secondary-6;
    }
  }
}

.ant-progress-bg.ant-progress-bg::before {
  transition: none;
  animation: none !important;
}

.intercom-namespace iframe[name='intercom-banner-frame'] {
  z-index: 99 !important;
}

.ant-dropdown,
.ant-menu-submenu-popup,
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1060 !important;
}

.dsp-search-dropdown,
.user-segment-select-dropdown,
.user-role-select-dropdown {
  // ABOVE THE MODAL
  z-index: 1070 !important;
}

.ant-modal-header.ant-modal-header {
  padding: 4rem 6.4rem 4rem 6.4rem;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: none;
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    .ant-picker-date-panel {
      .ant-picker-header {
        button.ant-picker-header-super-prev-btn,
        button.ant-picker-header-super-next-btn {
          display: none;
        }
      }
    }

    .ant-picker-cell:not(.ant-picker-cell-in-view) {
      pointer-events: none;
      .ant-picker-cell-inner {
        display: none;
      }
    }
  }
}

.ant-form-item.form-item-error-only {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  .ant-form-item-control-input {
    display: none;
  }
}

.ant-form-item-control-input-content {
  display: inline-flex;

  div.ant-select-selector {
    span.ant-select-selection-placeholder,
    &::placeholder {
      color: @neutral-7;
    }
  }
}

.ant-alert.ant-alert-success {
  background-color: @primary-1;
  border: 1px solid @primary-5;
}
.ant-alert.ant-alert-success,
.ant-alert.ant-alert-info {
  .ant-alert-icon {
    margin-right: @space-8;

    svg {
      width: 21px;
      height: 21px;
    }
  }
}

.ant-select.ant-select-focused.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-open,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border: 1px solid @primary-4 !important;
    box-shadow: 0 0 4px 0 rgba(93, 179, 147, 0.5) !important;
  }

  span.ant-select-arrow {
    color: @primary-4;
  }
}

.ant-select:not(.ant-select-disabled):hover {
  .ant-select-selector {
    border: 1px solid @neutral-7 !important;
    box-shadow: none !important;
  }

  span.ant-select-arrow {
    color: @neutral-7;
  }
}

.ant-message {
  z-index: 1062 !important;
}

span.anticon.anticon-question-circle {
  color: @neutral-6;
}

.intercom-lightweight-app-launcher.intercom-release-edit-flow {
  bottom: @space-80;
}

.link-in-text {
  color: @secondary-5 !important;
  text-decoration: underline !important;
  font-size: inherit !important;
  font-weight: 500;
  &:hover {
    text-decoration: none !important;
  }
}

.custom-icon {
  svg {
    width: 100%;
    height: 100%;
  }
}

.ant-collapse:not(.custom-accordion):hover {
  .ant-collapse-header.ant-collapse-header-collapsible-only {
    box-shadow: none;
  }
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

ul.bullet-list {
  li {
    display: flex;
    margin: 0px !important;

    &::before {
      content: '\2022';
      margin-right: @space-12;
    }
  }
}

.ant-space.ant-space-align-center {
  vertical-align: middle;
}

.ant-card-extra {
  padding: @space-16 0;
}

.soundfile-terms-checkbox {
  span.ant-checkbox {
    align-self: flex-start !important;
    margin-top: @space-8;
  }
}

.standard-size-svg {
  font-size: 21px;
}
