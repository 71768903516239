div.ant-upload.ant-upload-drag {
  max-height: 220px;
  background-color: rgba(158, 247, 209, 0.16);
  border: 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%235db393' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
  text-align: left;

  &.ant-upload-disabled {
    background-image: unset;
    background-color: @neutral-2;
    border: 1px dashed @neutral-4;

    .custom-typography {
      color: @neutral-6 !important;
    }
    .ant-upload-drag-icon path {
      fill: @neutral-6 !important;
    }
  }

  .file-upload-dropzone {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (max-width: @screen-md-min) {
      &.file-upload-dropzone,
      .ant-upload-drag-icon {
        padding: 0;
      }
    }

    &.file-upload-dropzone--horizontal {
      flex-direction: row;
      text-align: center;
      padding: @space-12 @space-12 @space-12 0;
    }

    &.file-upload-dropzone--vertical {
      flex-direction: row;

      @media (max-width: @screen-sm-max) {
        flex-direction: column;
      }
    }

    .ant-upload-drag-icon {
      padding: @space-16;

      svg {
        height: @space-32;
        width: @space-32;
      }
    }

    .ant-upload-drag-text {
      text-align: start;
    }

    p.ant-upload-hint {
      color: @neutral-7;
    }

    @media screen and (max-width: @screen-sm) {
      padding: 0 @space-40 @space-16 @space-40;
      text-align: center;
    }
  }
}

.button-file-upload {
  display: inline-block;

  span.ant-upload.ant-upload-btn {
    padding: 0px !important;
  }
}

.button-file-upload .ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: inline-block;
  vertical-align: baseline;
}

.ant-upload-drag-container {
  width: 100%;
}

.hide-drop-zone {
  display: none;
}

.hide-dropzone {
  .ant-upload.ant-upload-drag {
    display: none;
  }
}

.button-file-upload .ant-upload.ant-upload-drag {
  max-height: 200px;
  width: -moz-fit-content;
  width: fit-content;
  border: 0;
  display: inline-block;
  background: none;
}

.button-file-upload .ant-upload.ant-upload-drag .ant-upload-btn {
  display: inline-block;
  padding: 0;
}

.file-upload-progress {
  position: fixed;
  z-index: 1052; // above all but errors
  right: 100px;
  bottom: @space-88;
  width: 400px;
  background: white;
  box-shadow: 0px 2px 2px @secondary-2;

  @media screen and (max-width: @screen-sm-max) {
    right: 24px;
    width: 300px;
    bottom: 76px;
  }

  .ant-collapse-header {
    background-color: @secondary-6;
    border-radius: 0.4rem 0.4rem 0 0;
    color: white !important;
  }

  .ant-collapse-header,
  .ant-collapse-content,
  .ant-collapse-item {
    border-radius: 0 !important;
  }
  .ant-collapse-item {
    border: 0;
    box-shadow: 0px 1px 0px #ded9ef;
  }

  .file-upload-progress-row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    padding: @space-16;
    border-bottom: 1px solid @neutral-5;
  }

  .file-upload-progress-toggle {
    right: 40px !important;
    top: 15px;
  }

  &.ant-collapse-item-active {
    .file-upload-progress-toggle {
      transform: rotate(180deg);
    }
  }

  .file-upload-progress-close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .ant-collapse-content.ant-collapse-content-active,
  .ant-collapse-content-inactive {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .file-upload-progress-title {
    justify-content: flex-start;
    max-width: 50%;

    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .file-upload-progress-indicator {
    flex-grow: 1;
    text-align: right;
  }
}
