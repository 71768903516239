@drag-icon-size: @space-24;
@index-size: @space-16;

.ant-collapse:not(.custom-accordion),
.ant-collapse-borderless:not(.custom-accordion) {
  &.custom-collapse {
    background-color: transparent;

    .ant-collapse-header-text {
      flex: 1;
    }

    .ant-collapse-item {
      border-bottom: 0;
    }

    .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      top: 0px;
      bottom: 0;
      margin: auto;
      height: 18px;
    }

    .ant-collapse-item {
      .custom-collapse-btn-icon {
        transform: rotate(0deg);
        transition: transform 150ms linear;
      }
      .custom-collapse-btn-see-less {
        display: none;
      }
      .custom-collapse-btn-see-more {
        display: initial;
      }

      &.ant-collapse-item-active {
        .custom-collapse-btn-icon {
          transform: rotate(-180deg);
          transition: transform 150ms linear;
        }
        .custom-collapse-btn-see-less {
          display: initial;
        }
        .custom-collapse-btn-see-more {
          display: none;
        }
      }
    }
  }

  &.with-padding {
    margin-bottom: @space-24;
  }

  &.is-dragging,
  &:hover {
    .ant-collapse-header {
      background: @secondary-4;
      box-shadow: 0px 2px 8px rgba(23, 18, 43, 0.5);

      svg > circle {
        fill: @secondary-1;
      }
    }
  }

  .ant-collapse-item.custom-panel {
    background-color: transparent;

    &:last-child {
      .ant-collapse-header {
        background-color: @secondary-6;
        color: @white;

        border-radius: 8px;
        padding: @space-18 @space-16;

        @media only screen and (max-width: 375px) {
          padding: @space-12;
        }
      }
    }

    &.ant-collapse-item-active {
      border: 0;

      .ant-collapse-header {
        border-radius: 8px 8px 0 0;
      }
    }

    .tracklist-track-panel-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: @font-family;

      .custom-collapse-section-drag-handle {
        @media only screen and (max-width: @screen-sm) {
          svg.tracklist-card-drag {
            height: 20px;
            width: 20px;
          }
        }

        svg.tracklist-card-drag {
          color: @secondary-2;
        }
      }

      .title {
        display: inline-flex;
        align-items: center;
        z-index: 9;
        flex-grow: 1;

        .ant-row.tracklist-card-title {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: nowrap;
          flex-grow: 1;

          .custom-collapse-track-card-btn {
            .ant-typography {
              white-space: nowrap;
            }
          }

          .form-item-title {
            margin: 0;
            max-width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: @screen-xs-max) {
              max-width: 70%;
            }

            @media screen and (max-width: @screen-md) {
              padding-left: @space-4;
              padding-right: @space-4;
            }
          }
        }
      }

      .title-index {
        padding-right: @space-16;

        @media only screen and (max-width: @screen-md) {
          padding-right: @space-4;
        }
      }

      .subtitle {
        color: @secondary-2;
      }

      &.draggable {
        .title {
          padding-left: @space-12;

          @media only screen and (max-width: @screen-md) {
            padding-left: @space-4;
          }
        }

        svg {
          vertical-align: bottom;
        }
      }
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;

        > div {
          margin-bottom: @space-20;
        }
      }
    }

    .ant-card:first-child {
      border-radius: 0 0 @border-radius-base @border-radius-base;
    }
  }
}
