.add-performer-btn {
  margin-bottom: @space-8;
}

.remove-performer-btn {
  margin-left: -@space-16;
  margin-top: -@space-10;
}

.ant-form .performer-role.ant-row .ant-form-item {
  margin-top: 0;
}

.add-artist-modal {
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: 1px solid @neutral-5;
    }

    .ant-modal-header,
    .ant-modal-footer {
      padding: @space-24 @space-32;
    }
    .ant-modal-body {
      padding: @space-24 @space-32;

      .artist-profile-action-container {
        margin-top: @space-8;

        .ant-space-edit {
          .ant-space-item:first-of-type {
            margin-right: auto !important;
          }
        }
      }
    }
  }
}

.text-highlight {
  background-color: @primary-2;
}

.new-artist-tootlip {
  margin-bottom: @space-64;
}

.ant-form-item.apple-profile-radio,
.ant-form-item.spotify-profile-radio {
  margin-bottom: 0px;
  margin-top: 24px;
}

.missing-profile-warning svg {
  fill: @warning-color!important;
}

.ant-typography a.ipi-name-number-link {
  color: inherit;
  font-size: inherit;
  text-decoration: underline;
  color: @neutral-8;
  font-weight: unset;
}

.ant-modal-confirm-body-wrapper {
  .ant-modal-confirm-btns {
    .delete-prompt-button.ant-btn.custom-btn.custom-btn-primary.custom-btn-primary-filled {
      background-color: @danger-color;
      span {
        color: @neutral-1;
      }
    }
  }
}

.disabled-btn-tooltip-fix {
  //wrap this button inside a span
  pointer-events: none;
}
