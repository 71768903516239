// TODO: Work out why we have no ant prefix on this title class name, there is nothing seemingly overriding it??
.news-menu-item .-title-content,
.news-menu-item .HW_widget_component_headway-widget-badge {
  width: 100%;
}

.HW_frame_cont {
  min-width: 480px;
}

.news-menu-item .HW_badge_cont {
  opacity: 0;
  visibility: visible !important;
  pointer-events: auto !important;
}

.news-menu-item .HW_badge_cont.HW_visible {
  opacity: 1;
} 

.news-menu-item .HW_badge {
  background: none;
  opacity: 1 !important;
  transform: scale(1.0) !important;
  transition: unset;

  &.HW_visible {
    background-color: @accent-color;
  }

  .ant-layout-sider-collapsed & {
    left: 12px;
    top: 0;
  }
}

.news-menu-item .HW_badge[data-count-unseen=""],
.news-menu-item .HW_badge[data-count-unseen="0"] {
  background: none;
}


.HW_frame_cont {
  border: 2px solid #9a52f1 !important;
}