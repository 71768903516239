.ant-row.resource-form-item-row {
  margin-bottom: @space-16;
  .ant-form-item {
    margin-bottom: 0 !important;
  }

  @media screen and (min-width: @screen-md-min) {
    margin-bottom: @space-32;
  }
}

.ant-row.resource-form-item-row:last-child {
  margin-bottom: 0;
}

.ant-form-item-control-input-content {
  div.ant-radio-group {
    min-height: @space-40;
  }

  div.resource-form-item-radio-children {
    flex-direction: row;

    @media only screen and (max-width: @screen-xs-max) {
      flex-direction: column;
    }
  }
}

.ant-form {
  .ant-card {
    .ant-card-body {
      .resource-form-item-row.ant-row {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
}
