.or-separator {
  display: flex;
  align-content: center;

  h3 {
    font-weight: @font-weight-medium;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: @neutral-5;
    margin: 0 @space-8;
  }

  hr {
    border: 1px solid @neutral-5;
    width: 100%;
  }
}
