.ant-alert.section-alert-success,
.ant-alert.section-alert-info,
.ant-alert.section-alert-warning,
.ant-alert.section-alert-error,
.ant-alert.section-alert-tip,
.ant-alert.section-alert-accent {
  align-items: center;
  border-radius: 4px;

  &.section-alert-with-button {
    .ant-alert-action {
      align-self: center;
    }
  }

  &.section-alert-icon-align-flex-start .section-alert-icon,
  &.section-alert-button-align-flex-start .ant-alert-action {
    align-self: flex-start;
  }

  &.section-alert-icon-align-center .section-alert-icon,
  &.section-alert-button-align-center .ant-alert-action {
    align-self: center;
  }

  @media screen and (max-width: @screen-md-min) {
    @icon-width: @space-24;

    flex-wrap: wrap;
    flex-direction: row;

    .ant-alert-icon {
      flex: 0;
      min-width: @icon-width;
    }

    .ant-alert-content {
      flex-basis: calc(100% - (@icon-width + @space-8));
    }

    .ant-alert-action {
      display: flex;
      align-items: flex-end;
      flex-direction: row-reverse;
      flex-basis: 100%;
      margin-top: @space-8;
    }
  }

  span.anticon.ant-alert-icon {
    margin-right: @space-12;

    svg {
      width: @heading-xs-mobile-line-height;
      height: @heading-xs-mobile-line-height;
    }
  }

  .ant-alert-message {
    margin: 0px;

    .ant-typography.text-headline-xs.text-responsive.bold-500 {
      margin-bottom: 0px;
    }
  }

  .alert-description-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    button.ant-btn.custom-btn.custom-btn-m.custom-btn-secondary-link {
      padding: 0;
      height: auto;
    }
  }
}

.ant-alert.section-alert-success {
  background-color: @success-1;
}

.ant-alert.section-alert-regular {
  padding: @space-16;
}

.ant-alert.section-alert-small {
  padding: @space-12;

  span.anticon.ant-alert-icon {
    margin-top: @space-2;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .ant-alert-message {
    margin: 0px;
    line-height: 16px;
  }

  a {
    font-size: inherit;
  }

  button.custom-btn-secondary-link {
    padding: 0px !important;
  }
}

.ant-alert.section-alert-tip {
  background-color: rgba(@primary-3, 0.16);
  border-color: @primary-4;
}

.ant-alert.section-alert-accent {
  background: linear-gradient(
    90deg,
    @accent-6 -7.54%,
    @accent-color 38.47%,
    #9ef7d1 100%
  );

  button.ant-btn-gradient {
    background-color: @accent-color;
  }
}

.section-alert:has(.section-alert-close-msg) {
  display: grid;
  grid-template-columns: auto auto;
  > button:last-child {
    margin-top: @space-4;
    grid-column: 2;
    width: fit-content;
    margin-left: 0;
  }
}
